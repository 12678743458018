import SecurityService from "modules/security/securityService";
import paginationAction from "modules/shared/pagination/paginationAction";
import selectors from "modules/security/list/securityListSelectors";
import { i18n } from "i18n";
import exporterFields from "modules/security/list/securityListExporterFields";

const prefix = "Security_LIST";

export default paginationAction(
  prefix,
  SecurityService.list,
  selectors,
  i18n("entities.Security.exporterFileName"),
  exporterFields
);
