import NeighborhoodService from 'modules/neighborhood/neighborhoodService';
import paginationAction from 'modules/shared/pagination/paginationAction';
import selectors from 'modules/neighborhood/list/neighborhoodListSelectors';
import { i18n } from 'i18n';
import exporterFields from 'modules/neighborhood/list/neighborhoodListExporterFields';

const prefix = 'Neighborhood_LIST';

export default paginationAction(
  prefix,
  NeighborhoodService.list,
  selectors,
  i18n('entities.Neighborhood.exporterFileName'),
  exporterFields,
);
