import viewActions from "modules/shared/view/viewActions";
import SecurityService from "modules/security/securityService";

const prefix = "Security_VIEW";

export default viewActions({
  prefix,
  findFn: SecurityService.find,
  redirectToOnError: "/security",
});
