import list from 'modules/neighborhood/list/neighborhoodListReducers';
import form from 'modules/neighborhood/form/neighborhoodFormReducers';
import view from 'modules/neighborhood/view/neighborhoodViewReducers';
import destroy from 'modules/neighborhood/destroy/neighborhoodDestroyReducers';
import importerReducer from 'modules/neighborhood/importer/neighborhoodImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
    list,
    form,
    view,
    destroy,
    importer: importerReducer,
});