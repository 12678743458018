import model from 'modules/students/studentsModel';

const { fields } = model;

export default [
  fields.id,
  fields.first_name,
  fields.last_name,
  fields.code_massar,
  fields.matr,
];
