import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';
import { getLanguageCode } from 'i18n';
import Permissions from 'security/permissions';
import PermissionChecker from 'modules/auth/permissionChecker';
import { getStore } from 'modules/store';
import StudentServices from 'modules/students/studentsService';
export default class CourseStudentsService {
  static async update(id, data) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        update_courseStudents(where: {id: {_eq: ${id}}}, _set: {name: "${data.name}"}) {
          affected_rows
        }
      }
      
      `,
    });

    return response.data.update_courseStudents;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        delete_courseStudents(where: {id: {_eq: ${ids}}}) {
          affected_rows
        }
      }
      
      `,
    });

    return response.data.delete_courseStudents;
  }

  static async create(data) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        insert_courseStudents(objects: {name: "${data.name.toLowerCase()}"}) {
          affected_rows
        }
     }    
      `,
    });

    return response.data.insert_courseStudents;
  }

  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        courseStudents(where: {id: {_eq: ${id}}}) {
          id
          name
          createdAt
          updatedAt
        }
      }
      
      `,
    });

    return response.data.courseStudents[0];
  }

  static async list(filter, orderBy, limit, offset) {
    const CurrentUser = await getStore().getState().auth
      .currentUser;
    const permissions = Permissions.values;
    const permissionChecker = new PermissionChecker(
      CurrentUser,
    );
    var standar = false;
    if (
      permissionChecker.match(permissions.HomeFramer) ||
      permissionChecker.match(permissions.HomeParent) ||
      permissionChecker.match(permissions.Managing_users) ||
      permissionChecker.match(permissions.HomeProf)
    ) {
      standar = true;
    }

    if (standar && !filter) {
      return [];
    }

    if (
      standar &&
      filter &&
      Object.keys(filter).length == 0
    ) {
      return [];
    }
    var response;
    if (!standar) {
      response = await graphqlClientConfig.config().query({
        query: gql`
        query MyQuery {
          matter {
            name
            id
            element_matters {
              id
              name
              level_sector_elements{
                assignments(order_by: {created_at: desc}) {
                  coursHTML
                  created_at
                  id
                  id_classroom_teacher_matter
                  id_element
                  id_type
                  name
                  payload
                  type_course
                  youtubeLink
                }
              }
            }
          }
        }
        `,
      });

      return response.data.matter;
    } else {
      if (permissionChecker.match(permissions.HomeParent)) {
        response = await graphqlClientConfig
          .config()
          .query({
            query: gql`
              query MyQuery($student: Int) {
                matter(
                  where: {
                    classroom_teacher_matters: {
                      classroom: {
                        registers: {
                          id_student: { _eq: $student }
                        }
                      }
                    }
                  }
                ) {
                  name
                  element_matters(
                    where: {
                      level_sector_elements: {
                        level_sector: {
                          classrooms: {
                            registers: {
                              id_student: { _eq: $student }
                            }
                          }
                        }
                      }
                    }
                  ) {
                    id
                    name
                    level_sector_elements {
                      assignments(order_by: {created_at: desc}) {
                        coursHTML
                        created_at
                        id
                        id_classroom_teacher_matter
                        id_element
                        id_type
                        name
                        payload
                        type_course
                        youtubeLink
                      }
                    }
                  }
                }
              }
            `,
            variables: {
              student:
                filter && filter.student
                  ? filter.student
                  : null,
            },
          });
        return response.data.matter;
      } else {
        if (
          permissionChecker.match(permissions.HomeProf)
        ) {
  
          response = await graphqlClientConfig
            .config()
            .query({
              query: gql`
              query MyQuery($matter: Int, $cycle: Int, $classroom: Int, $teacher: Int) {
                matter(where: {framer_matters: {id_matter: {_eq: $matter}, id_cycle: {_eq: $cycle}}, classroom_teacher_matters: {classroom: {id: {_eq: $classroom}}}}) {
                  name
                  id
                  element_matters(where: {id_matter: {_eq: $matter}, level_sector_element: {level_sector: {classrooms: {id: {_eq: $classroom}}}}}) {
                    name
                    level_sector_elements {
                      assignments(order_by: {created_at: desc}, where: {classroom_teacher_matter: {id_teacher: {_eq: $teacher}, id_classroom: {_eq: $classroom}, id_matter: {_eq: $matter}}}) {
                        coursHTML
                        created_at
                        id
                        id_classroom_teacher_matter
                        id_element
                        id_type
                        name
                        payload
                        type_course
                        youtubeLink
                        classroom_teacher_matter {
                          id
                          classroom {
                            id
                          }
                          teacher {
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
              `,
              variables: {
                cycle:
                  filter && filter.cycle
                    ? filter.cycle
                    : null,
                matter:
                  filter && filter.matter
                    ? filter.matter
                    : null,
  
                    classroom:
                      filter && filter.classroom
                        ? filter.classroom
                        : null,
  
                        teacher:
                          filter && filter.teacher
                            ? filter.teacher
                            : null,
              },
            });
  
            return response.data.matter;
          } else {
            if (
              permissionChecker.match(permissions.HomeFramer)
              ||
              permissionChecker.match(permissions.Managing_users)
            ) {
      
              response = await graphqlClientConfig
                .config()
                .query({
                  query: gql`
                  query MyQuery($matter: Int, $cycle: Int, $classroom: Int, $teacher: Int) {
                    matter(where: {classroom_teacher_matters: {matter: {id: {_eq: $matter}}, id_teacher: {_eq: $teacher}, classroom: {level_sector: {classrooms: {id: {_eq: $classroom}}}}}}) {
                      name
                      id
                      element_matters(where: {level_sector_elements: {level_sector: {classrooms: {classroom_teacher_matters: {id_matter: {_eq: $matter}, id_teacher: {_eq: $teacher}, id_classroom: {_eq: $classroom}}}}}}) {
                        name
                        level_sector_elements {
                          assignments(order_by: {created_at: desc}) {
                            coursHTML
                            created_at
                            id
                            id_classroom_teacher_matter
                            id_element
                            id_type
                            name
                            payload
                            type_course
                            youtubeLink
                            classroom_teacher_matter {
                              id
                              classroom {
                                id
                              }
                              teacher {
                                id
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  `,
                  variables: {
                    cycle:
                      filter && filter.cycle
                        ? filter.cycle
                        : null,
                    matter:
                      filter && filter.matter
                        ? filter.matter
                        : null,
      
                        classroom:
                          filter && filter.classroom
                            ? filter.classroom
                            : null,
      
                            teacher:
                              filter && filter.teacher
                                ? filter.teacher
                                : null,
                  },
                });
      
                return response.data.matter;
              }
              return [];
            }
          }
      }
  }

  static async listSelect(limit) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        courseStudents(where: {}) {
          id
          name
        }
      }
      `,
    });
    return response.data.courseStudents;
  }
}
