const fr = {
  common: {
    or: 'ou',
    cancel: 'Annuler',
    reset: 'Réinitialiser',
    save: 'Sauvegarder',
    search: 'Recherche',
    edit: 'Modifier',
    exportdata: 'Exporter',
    editing: 'M',
    publish: 'Publier',
    closing: 'Clôturer',
    duplicate: 'Dupliquer',
    archive: 'Archiver',
    cancelPub: 'Annuler la publication',
    remove: 'Supprimer',
    new: 'Nouveau',
    export: 'Exporter en Excel',
    noDataToExport: 'Pas de données à exporter',
    import: 'Importer',
    discard: 'Jetez',
    yes: 'Oui',
    no: 'Non',
    pause: 'Pause',
    areYouSure: 'Êtes-vous sûr ?',
    view: 'Consulter',
    viewing: 'C',
    destroy: 'Supprimer',
    destroying: 'S',
    mustSelectARow: 'Vous devez sélectionner une ligne',
    manager: 'Géstionnaire',
  },
  dictionary: {
    day: [
      'Dimanche',
      'Lundi',
      'Mardi',
      'Mercredi',
      'Jeudi',
      'Vendredi',
      'Samedi',
    ],
  },
  //App_Title
  app: {
    // title: 'Fadaa Al Ouloume',
    title: 'Ecole Taiba',
  },

  auth: {
    accountSeting: {
      title: 'Langue & Fuseau horaire',
    },
    menu: 'Paramétrage',
    profile: {
      title: 'Modifier le profil',
      success: 'Profil mis à jour avec succès',
    },
    editpassword: {
      title: 'Modifier le mot de passe',
      success: 'Mot de passe mis à jour avec succès',
      error: 'Mot de passe incorrect',
      errornewpassword:
        'Les mots de passes ne sont pas identiques',
    },
    createAnAccount: 'Créer un compte',
    rememberMe: 'Souvenez-vous de moi.',
    forgotPassword: 'Mot de passe oublié',
    signin: "S'identifier",
    signup: "S'inscrire",
    signout: 'Se déconnecter',
    alreadyUsed: 'Cette adresse email est déjà utilisée',

    alreadyHaveAnAccount:
      'Vous avez déjà un compte ? Connectez-vous.',
    signinWithAnotherAccount:
      'Vous avez déjà un compte ? Connectez-vous.',
    emailUnverified: {
      message: `Veuillez confirmer votre courriel à <strong>{0}</strong> pour continuer.`,
      submit: `Renvoyer la vérification de l'email`,
    },
    emptyPermissions: {
      message: `Vous n'avez pas encore d'autorisations. Attendez que l'administrateur vous accorde des privilèges`,
    },
    passwordReset: {
      message:
        'Envoyer un courriel de réinitialisation du mot de passe',
      message1: 'Renvoyer le courriel',
      error: `Courriel non reconnu`,
    },
    emailAddressVerificationEmail: {
      error: `Courriel non reconnu`,
    },
    verificationEmailSuccess: `Courriel de vérification envoyé avec succès`,
    alreadyActive: 'Le compte est deja activé',
    passwordResetSuccess: `Courriel de réinitialisation du mot de passe envoyé avec succès`,
  },

  liveButtons: {
    chat: {
      title: 'Chat de la Classe',
    },
  },

  Home: {
    Classe: 'Classe',
    Classes: 'Liste des Classes',
    menu: {
      Cours: 'Cours',
      Classes: 'Classes',
      records: 'Enregistrements',
      Access: 'Accéder',
      Meetings: 'Réunions',
      Statistics: 'Statistiques',
    },
  },

  fields: {
    StudentClassroom: 'Classe',
    studentName: 'Nom de l\'enfant',
    transport: 'Transport ?',
    online: 'En ligne ?',
    canteen: 'Cantine ?',
  },

  entities: {
    EventModalFormView: {
      Title: 'Titre',
      Teacher: 'Enseignant',
      Matter: 'Matière',
      Type: 'Type',
      StartDate: 'Date debut',
      EndDate: 'Date fin',
      classroomName: 'Classe',
      elementName: 'Elément',
    },

    StudentStatus: {
      list: {
        title: 'Status de mes étudiants',
      },
      menu: 'Status Etudiants',
    },
    CourseCreation: {
      menu: 'Création des cours',
    },
    CourseStudents: {
      menu: 'Cours',
      fields: {
        id: 'id',
        created_at: 'Created At',
        updated_at: 'Updated At',
        createdAtRange: 'Created At Range',
        student: "nom de l\'étudiant",
        cycle: 'Cycle',
        matter: 'Matière',
        classroom: 'Nom de la classe',
        teacher: 'Enseignant',
      },
    },
    LevelSector: {
      list: {
        title: 'Affectation niveaux filière',
      },
      menu: 'Affectation niveaux filière',
      edit: {
        title: 'Modifier affectation',
      },
      new: {
        title: 'Ajout affectation',
      },
      view: {
        title: 'Niveau Filière',
      },
      save: {
        success: 'Affec NF éffectué avec succès',
      },
      destroy: {
        success: 'Affec NF supprimé avec succès',
      },
      create: {
        success: 'Affec NF créé avec succès',
      },
      update: {
        success: 'Affec NF modifiè avec succès',
      },
      exporter: 'Affectation Niveau Filière',
      fields: {
        id: 'id',
        sector: 'Filière',
        level: 'Niveau',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        cycle: 'Cycle',
        codeMassar: 'Code Massar',
      },
    },
    LevelSectorElement: {
      list: {
        title: 'Affectation niveau - filière - éléments',
      },
      menu: 'Niveau - filière - éléments',
      edit: {
        title: 'Modifier affectation',
      },
      new: {
        title: 'Ajout affectation',
      },
      view: {
        title: 'Niveau Filière Elément',
      },
      save: {
        success: 'Affec NFE éffectué avec succès',
      },
      destroy: {
        success: 'Affec NFE supprimé avec succès',
      },
      create: {
        success: 'Affec NFE créé avec succès',
      },
      update: {
        success: 'Affec NFE modifiè avec succès',
      },
      exporter: 'Affectation Niveau Filière Eléments',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        sector: 'Filière',
        cycle: 'Cycle',
        level: 'Niveau',
        element: 'Elément',
        matter: 'Matière',
        levelSector: 'Niveau Filière',
      },
    },
    LevelSectorExam: {
      list: {
        title: 'Affectation niveau - filière - exam',
      },
      menu: 'Niveau - filière - exam',
      edit: {
        title: 'Modifier affectation',
      },
      new: {
        title: 'Ajout affectation',
      },
      view: {
        title: 'Niveau Filière Exam',
      },
      save: {
        success: 'Affec NFExam éffectué avec succès',
      },
      destroy: {
        success: 'Affec NFExam supprimé avec succès',
      },
      create: {
        success: 'Affec NFExam créé avec succès',
      },
      update: {
        success: 'Affec NFExam modifiè avec succès',
      },
      exporter: 'Affectation Niveau Filière Exam',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        sector: 'Filière',
        cycle: 'Cycle',
        level: 'Niveau',
        exam: 'Contrôle',
        matter: 'Matière',
        semester: 'Semetre',
        purcent: 'Poucentage',
        levelSector: 'Niveau Filière',
      },
    },
    LevelSectorMatterExam: {
      list: {
        title: 'Niveau - filière - Matière - exam',
      },
      menu: 'Niveau - filière - Matière - exam',
      edit: {
        title: 'Modifier affectation',
      },
      new: {
        title: 'Ajout affectation',
      },
      view: {
        title: 'Niveau Filière Exam',
      },
      save: {
        success: 'Affec NFMExam éffectué avec succès',
      },
      destroy: {
        success: 'Affec NFMExam supprimé avec succès',
      },
      create: {
        success: 'Affec NFMExam créé avec succès',
      },
      update: {
        success: 'Affec NFMExam modifiè avec succès',
      },
      exporter: 'Affectation Niveau Filière Matière Exam',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        sector: 'Filière',
        cycle: 'Cycle',
        level: 'Niveau',
        exam: 'Contrôle',
        matter: 'Matière',
        semester: 'Semetre',
        grade_plafond: 'Plafond',
        coefficient: 'Coefficient',
        element: 'Elémemt',
        levelSectorExam: 'N-F-C',
      },
    },
    GradesManager: {
      list: {
        title: 'Gestionnaire des Notes',
      },
      menu: 'Gestionnaire des Notes',
      edit: {
        title: 'Gestionnaire des Notes',
      },
      new: {
        title: 'Gestionnaire des Notes',
      },
      view: {
        title: 'Note',
      },
      save: {
        success: 'Note éffectué avec succès',
      },
      destroy: {
        success: 'Note supprimé avec succès',
      },
      create: {
        success: 'Note créé avec succès',
      },
      update: {
        success: 'Note modifiè avec succès',
      },
      exporter: 'Notes',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        sector: 'Filière',
        cycle: 'Cycle',
        level: 'Niveau',
        exam: 'Contrôle',
        matter: 'Matière',
        semester: 'Semestre',
        grade_plafond: 'Plafond',
        coefficient: 'Coefficient',
        element: 'Elémemt',
        levelSectorExam: 'N-F-C',
        grade: 'Note',
        classroom: 'Classe',
        schoolYear: 'Année Scolaire',
        firstname: 'Prénom',
        lastname: 'Nom',
        Value: 'Note',
      },
    },
    RoomController: {
      list: {
        title: 'Accès à la classe',
      },
      menu: 'Classe',
      edit: {
        title: 'Modifier le lien de la classe',
      },
      new: {
        title: 'Ajouter un lien de la classe',
      },
      view: {
        title: 'Lien de la classe',
      },
      save: {
        success: 'lien du classe éffectué avec succès',
      },
      destroy: {
        success: 'lien du classe supprimé avec succès',
      },
      create: {
        success: 'lien du classe créé avec succès',
      },
      update: {
        success: 'lien du classe modifiè avec succès',
      },
      exporter: 'lien du classe',
      studentURL: {
        NoURL: 'Pas de Lien il faut contacter votre enseignant(e) !!',
        success: 'Rdirection avec succès !!',
      },
      No: {
        Roomsession: 'Pas de classe en ce moment !!',
      },
      fields: {
        id: 'id',
        sector: 'Filière',
        level: 'Niveau',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        cycle: 'Cycle',
      },
    },
    Assignments: {
      menu: 'Espace des cours',
      list: {
        title: 'Espace des Cours',
      },
      view: {
        title: 'Cours',
      },
      edit: {
        title: 'Modification Cours',
      },
      new: {
        title: 'Ajouter Cours',
      },
      save: {
        success: 'Assignments éffectué avec succès',
      },
      destroy: {
        success: 'Assignments supprimé avec succès',
      },
      create: {
        success: 'Assignments créé avec succès',
      },
      update: {
        success: 'Assignments modifiè avec succès',
      },
      exporter: 'Assignments',
      enumerators: {
        type_course: {
          Standard: 'Fichier',
          Editable: 'éditeur de contenu',
          External_Link: 'Lien de video éxterne',
        },
        hidden: {
          hidden: 'Cacher',
          shown: 'afficher',
        },
      },
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        assignmentsName: "Nom d'assignement",
        name: 'Nom du cours',
        classroom: 'Classe',
        matter: 'Matière',
        level: 'Niveau',
        cycle: 'Cycle',
        sector: 'Filière',
        element: 'Elément',
        types_assignment: 'Type de Cours',
        type_course: 'Le mode',
        file: 'Fichier',
        teacher: 'Enseignant',
        student: 'Etudiant',
        start_date: 'Date debut',
        end_date: 'Date fin',
        hidden: 'Status',
        listClassrooms: 'Choix Des Classes',
        youtubeLink: 'Lien video',
      },
    },
    RoomURL: {
      menu: 'Lien de la classe',
      list: {
        title: 'Lien de la classe',
      },
      view: {
        title: 'Lien de la classe',
      },
      edit: {
        title: 'Modification Du Lien de la classe',
      },
      new: {
        title: 'Ajouter Du Lien de la classe',
      },
      save: {
        success: 'URL éffectué avec succès',
      },
      destroy: {
        success: 'URL supprimé avec succès',
      },
      create: {
        success: 'URL créé avec succès',
      },
      update: {
        success: 'URL modifiè avec succès',
      },
      exporter: 'teacher_room_url',
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        url: 'lien de la classe',
      },
    },
    Absence: {
      menu: 'Absence',
      errorTeacherAbsence: 'Pas de cours',
      list: {
        title: 'Absence',
      },
      view: {
        title: 'Absence',
      },
      edit: {
        title: 'Modification de l\'absence',
      },
      new: {
        title: 'Saisie d\'absence',
      },
      save: {
        success: 'Absence éffectué avec succès',
      },
      destroy: {
        success: 'Absence supprimé avec succès',
      },
      create: {
        success: 'Absence créé avec succès',
      },
      update: {
        success: 'Absence modifiè avec succès',
      },
      exporter: 'Absence',
      enumerators: {
        day: {
          Sunday: 'Dimanche',
          Monday: 'Lundi',
          Tuesday: 'Mardi',
          Wednesday: 'Mercredi',
          Thursday: 'Jeudi',
          Friday: 'Vendredi',
          Saturday: 'Samedi',
        },
      },
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        firstname: 'Prénom',
        lastname: 'Nom',
        first_name: 'Prénom',
        last_name: 'Nom',
        classroom: 'Classe',
        absence: 'Absence',
        sector: 'Filière',
        level: 'Niveau',
        day: 'Jours',
        cycle: 'Cyle',
        week: 'Semaine',
        start_time: 'Heure début',
        end_time: 'Heure Fin',
      },
    },
    Matter: {
      menu: 'matières',
      list: {
        title: 'matière',
      },
      view: {
        title: 'Matière',
      },
      edit: {
        title: 'Modifier Matière',
      },
      new: {
        title: 'Ajout Matière',
      },
      save: {
        success: 'matière éffectué avec succès',
      },
      destroy: {
        success: 'matière supprimé avec succès',
      },
      create: {
        success: 'matière créé avec succès',
      },
      update: {
        success: 'matière modifiè avec succès',
      },
      exporter: 'Assignments',
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        name: 'Nom de matière',
        subname: 'Matière',
        createdAtRange: 'Période',
      },
    },
    Semester: {
      menu: 'Semestre',
      list: {
        title: 'Semestre',
      },
      view: {
        title: 'Semestre',
      },
      edit: {
        title: 'Modifier Semestre',
      },
      new: {
        title: 'Ajout Semestre',
      },
      save: {
        success: 'Semestre éffectué avec succès',
      },
      destroy: {
        success: 'Semestre supprimé avec succès',
      },
      create: {
        success: 'Semestre créé avec succès',
      },
      update: {
        success: 'Semestre modifiè avec succès',
      },
      exporter: 'Semestre',
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        name: 'Nom de Semestre',
        subname: 'Semestre',
        createdAtRange: 'Période',
      },
    },
    Exam: {
      menu: 'Contrôles',
      list: {
        title: 'Contrôles',
      },
      view: {
        title: 'Contrôle',
      },
      edit: {
        title: 'Modifier Contrôle',
      },
      new: {
        title: 'Ajout Contrôle',
      },
      save: {
        success: 'Contrôle éffectué avec succès',
      },
      destroy: {
        success: 'Contrôle supprimé avec succès',
      },
      create: {
        success: 'Contrôle créé avec succès',
      },
      update: {
        success: 'Contrôle modifiè avec succès',
      },
      exporter: 'Contrôle',
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        name: 'Nom du contrôle',
        subname: 'Contrôle',
        createdAtRange: 'Période',
        exams: 'Contrôles',
      },
    },
    Teachers: {
      menu: 'enseignants',
      list: {
        title: 'Enseignants',
      },
      edit: {
        title: 'Modifier enseignant',
      },
      new: {
        title: 'Ajout enseignant',
      },
      view: {
        title: 'Enseignant',
      },
      save: {
        success: 'enseignant éffectué avec succès',
      },
      destroy: {
        success: 'enseignant supprimé avec succès',
      },
      create: {
        success: 'enseignant créé avec succès',
      },
      update: {
        success: 'enseignant modifiè avec succès',
      },
      exporter: 'enseignant',
      enumerators: {
        permanent: {
          Permanent: 'Permanent',
          Temporary: 'Vacatère',
        },
      },
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        name: "Nom d'utilisateur",
        first_name: 'Prénom',
        last_name: 'Nom',
        email: 'E-mail',
        phone: 'Téléphone',
        permanent: 'Permanent',
      },
    },
    QrCodeGenerator: {
      menu: 'Générateur du QR Code',
      list: {
        title: 'Générateur du QR Code',
      },
      new: {
        title: 'Générateur du QR Code',
      },
      exporter: 'enseignant',
      enumerators: {
        role: {
          responsible: 'Responsable',
          educDirector: 'Directeur Pédag',
          teacher: 'Enseignant',
          student: 'Etudiant',
          parent: 'Parent',
          framer: 'Encadrant',
        },
        isAll: {
          SelectOne: 'Sélectionné Un Membre',
          All: 'Tous Les Membres',
        },
      },
      fields: {
        id: 'id',
        educDirector: 'Direc Pédag',
        responsible: 'Responsable',
        teacher: 'Enseignant',
        student: "Etudiant",
        classroom: 'Classe',
        schoolYear: 'Années Scolaire',
        cycle: 'Cycle',
        level: 'Niveau',
        sector: 'Filière',
        framer: 'Encadrant',
        parent: 'Parent',
        role: 'Rôle',
        isAll: 'Sélection',
      },
    },
    Level: {
      menu: 'Niveaux',
      list: {
        title: "Niveaux de l'école",
      },
      edit: {
        title: 'Modifier niveau',
      },
      new: {
        title: 'Ajout niveau',
      },
      view: {
        title: 'Niveau',
      },
      save: {
        success: 'niveau éffectué avec succès',
      },
      destroy: {
        success: 'niveau supprimé avec succès',
      },
      create: {
        success: 'niveau créé avec succès',
      },
      update: {
        success: 'niveau modifiè avec succès',
      },
      exporter: 'niveau',
      fields: {
        id: 'id',
        name: 'Niveaux',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        cycle: 'Cycle',
      },
    },
    Students: {
      menu: 'Etudiants',
      list: {
        title: "Etudiants de l'école",
      },
      edit: {
        title: 'Modifier étudiant',
      },
      new: {
        title: 'Ajout étudiant',
      },
      view: {
        title: 'étudiant',
      },
      save: {
        success: 'étudiant éffectué avec succès',
      },
      destroy: {
        success: 'étudiant supprimé avec succès',
      },
      create: {
        success: 'étudiant créé avec succès',
      },
      update: {
        success: 'étudiant modifiè avec succès',
      },
      exporter: 'étudiant',
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        name: "Nom d'utilisateur",
        first_name: 'Prénom',
        last_name: 'Nom',
        email: 'E-mail',
        phone: 'Téléphone',
        code_massar: 'Code Massar',
        matr: 'Matricule',
        father: 'Père',
        mother: 'Mère',
        tutor1: 'Tuteur 1',
        tutor2: 'Tuteur 2',
        quality_tutor1: 'Qualité Tuteur 1',
        quality_tutor2: 'Qualité Tuteur 2',
        neighborhood: 'Quartier',
      },
    },
    Responsibles: {
      menu: 'Responsables',
      list: {
        title: "Responsables de l'école",
      },
      edit: {
        title: 'Modifier responsable',
      },
      new: {
        title: 'Ajout responsable',
      },
      view: {
        title: 'Responsable',
      },
      save: {
        success: 'responsable éffectué avec succès',
      },
      destroy: {
        success: 'responsable supprimé avec succès',
      },
      create: {
        success: 'responsable créé avec succès',
      },
      update: {
        success: 'responsable modifiè avec succès',
      },
      exporter: 'responsable',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        Username: "Nom d'utilisateur",
        first_name: 'Prénom',
        last_name: 'Nom',
        email: 'E-mail',
        phone: 'Téléphone',
      },
    },
    Classroom: {
      menu: 'Classes de l\'école',
      list: {
        title: 'Classes de l\'école',
      },
      edit: {
        title: 'Modifier classe',
      },
      new: {
        title: 'Ajout classe',
      },
      view: {
        title: 'Classe',
      },
      save: {
        success: 'classe éffectué avec succès',
      },
      destroy: {
        success: 'classe supprimé avec succès',
      },
      create: {
        success: 'classe créé avec succès',
      },
      update: {
        success: 'classe modifiè avec succès',
      },
      exporter: 'classe',
      enumerators: {
        room_status: {
          ByURL: 'Avec Un Lien Externe (URL)',
          ToServer: 'Classe Interne (LIVE)',
        },
      },
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        name: 'Nom de la classe',
        subname: 'Classe',
        level: 'Niveau',
        matter: 'Matière',
        cycle: 'Cycle',
        sector: 'Filière',
        codeMassar: 'Code Massar',
        room_status: 'Status de la classe',
      },
    },
    FramerMatterCycle: {
      menu: 'Encadrant - Cycle - Matière',
      list: {
        title: 'Affectation Encadrant - Cycle - Matières',
      },
      edit: {
        title: 'Modifier Affectation ECM',
      },
      new: {
        title: 'Ajout Affectation ECM',
      },
      view: {
        title: 'Encadrant - Cycle - Matière',
      },
      save: {
        success: 'Affec ECM éffectué avec succès',
      },
      destroy: {
        success: 'Affec ECM supprimé avec succès',
      },
      create: {
        success: 'Affec ECM créé avec succès',
      },
      update: {
        success: 'Affec ECM modifiè avec succès',
      },
      exporter: 'Affec ECM',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        cycle: 'Cycle',
        level: 'Niveau',
        matter: 'Matière',
        framer: 'Encadrant',
        schoolYear: 'Année scolaire',
      },
    },
    ClassroomTeacherMatter: {
      menu: 'Classe - Enseignant - Matière',
      list: {
        title:
          'Affectation classes - enseignants - matières',
      },
      edit: {
        title: 'Modifier Affectation CEM',
      },
      new: {
        title: 'Ajout Affectation CEM',
      },
      view: {
        title: 'Classe - Enseignant - Matière',
      },
      save: {
        success: 'Affec CEM éffectué avec succès',
      },
      destroy: {
        success: 'Affec CEM supprimé avec succès',
      },
      create: {
        success: 'Affec CEM créé avec succès',
      },
      update: {
        success: 'Affec CEM modifiè avec succès',
      },
      exporter: 'Affec CEM',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        class: 'Classe',
        level: 'Niveau',
        matter: 'Matière',
        teachers: 'Enseignants',
        schoolYear: 'Année scolaire',
        cycle: 'Cycle',
        sector: 'Filière',
        teacher: 'Enseignant',
        classroom: 'Classe',
      },
    },
    Roomsession: {
      menu: 'Emploi Du Temps',
      live: {
        openRoom: {
          success: 'Joindre le cours',
          error: "L'ensegnant n'a pas encore rejoint",
          errorTeacher:
            "vous n'avez aucune seance a ce moment",
        },
      },
      list: {
        title: 'Emploi Du Temps',
      },
      edit: {
        title: 'Modifier Emploi Du Temps',
      },
      new: {
        title: 'Ajout Emploi Du Temps',
      },
      view: {
        title: 'Emploi Du Temps',
      },
      save: {
        success: 'Emploi Du Temps éffectué avec succès',
      },
      destroy: {
        success: 'Emploi Du Temps supprimé avec succès',
      },
      create: {
        success: 'Emploi Du Temps créé avec succès',
      },
      update: {
        success: 'Emploi Du Temps modifiè avec succès',
      },
      exporter: 'Emploi Du Temps',
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        name: 'Nom de la session',
        classroomStudent: 'Classe - Etudiant',
        day: 'Jour',
        start_time: 'Heure de début',
        end_time: 'Heure de fin',
        classroom: 'Classe',
        level: 'Niveau',
        matter: 'Matière',
        teacher: 'Enseignants',
        cycle: 'Cycle',
        sector: 'Filière',
        element: 'Element de matière',
        schoolYear: 'Année scolaire',
        week: 'Semaine',
        isAll: 'Type d\'action',
      },
      enumerators: {
        day: {
          Sunday: 'Dimanche',
          Monday: 'Lundi',
          Tuesday: 'Mardi',
          Wednesday: 'Mercredi',
          Thursday: 'Jeudi',
          Friday: 'Vendredi',
          Saturday: 'Samedi',
        },
        isAll: {
          isAll: 'Pour toutes les semaines',
          OnlyCurrent: 'Juste la semaine actuel',
        },
        quality_tutor: {
          friend: 'Ami',
          brother: 'Frère',
          grand_father: 'Grand Père',
          uncle: 'Oncle',
          sister: 'Soeur',
          aunt: 'Tante',
        },
        room_status: {
          ByURL: 'Par Lien (URL)',
          ToServer: 'Classroom',
        },
      },
    },

    Framer: {
      menu: 'Encadrant',
      list: {
        title: "Encadrants de l'école",
      },
      edit: {
        title: 'Modifier Encadrant',
      },
      new: {
        title: 'Ajout Encadrant',
      },
      view: {
        title: 'Encadrant',
      },
      save: {
        success: 'Encadrant éffectué avec succès',
      },
      destroy: {
        success: 'Encadrant supprimé avec succès',
      },
      create: {
        success: 'Encadrant créé avec succès',
      },
      update: {
        success: 'Encadrant modifiè avec succès',
      },
      exporter: 'Encadrant',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        Username: "Nom d'utilisateur",
        first_name: 'Prénom',
        last_name: 'Nom',
        email: 'E-mail',
        phone: 'Téléphone',
      },
    },
    Cycle: {
      menu: 'Cycles',
      list: {
        title: "Cycles de l'école",
      },
      edit: {
        title: 'Modifier Cycle',
      },
      new: {
        title: 'Ajout Cycle',
      },
      view: {
        title: 'Cycle',
      },
      save: {
        success: 'Cycle éffectué avec succès',
      },
      destroy: {
        success: 'Cycle supprimé avec succès',
      },
      create: {
        success: 'Cycle créé avec succès',
      },
      update: {
        success: 'Cycle modifiè avec succès',
      },
      exporter: 'Cycle',
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        name: 'Nom du cycle',
      },
    },
    Sector: {
      menu: 'Filières',
      list: {
        title: "Filières de l'école",
      },
      edit: {
        title: 'Modifier Filière',
      },
      new: {
        title: 'Ajout Filière',
      },
      view: {
        title: 'Filière',
      },
      save: {
        success: 'Filière éffectué avec succès',
      },
      destroy: {
        success: 'Filière supprimé avec succès',
      },
      create: {
        success: 'Filière créé avec succès',
      },
      update: {
        success: 'Filière modifiè avec succès',
      },
      exporter: 'Filière',
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        name: 'Nom de la filière',
      },
    },
    Week: {
      menu: 'Semaines',
      list: {
        title: "Semaines de l'année",
      },
      edit: {
        title: 'Modifier Semaine',
      },
      new: {
        title: 'Ajout Semaine',
      },
      view: {
        title: 'Semaines',
      },
      save: {
        success: 'Semaine éffectué avec succès',
      },
      destroy: {
        success: 'Semaine supprimé avec succès',
      },
      create: {
        success: 'Semaine créé avec succès',
      },
      update: {
        success: 'Semaine modifiè avec succès',
      },
      exporter: 'Semaine',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        name: 'Nom de la Semaine',
        schoolYear: 'Année scolaire',
        end_date: 'Date fin',
        start_date: 'Date debut',
      },
    },
    timeTable: {
      menu: 'Emploi du temps',
      list: {
        title: 'Emploi du temps',
      },
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        classroom: 'Classe',
        matter: 'Matière',
        element: 'Element',
        week: 'week',
        cycle: 'cycle',
        level: 'level',
        sector: 'sector',
        student: 'Etudiant',
        school_year: "l'année scolaire",
        day: ' jour',
        teacher_name: 'Enseignant',
        teacher: 'Enseignant',
      },
    },
    Element: {
      menu: 'Eléments de matières',
      list: {
        title: 'Eléments de matières',
      },
      edit: {
        title: 'Modifier Element',
      },
      new: {
        title: 'Ajout Element',
      },
      view: {
        title: 'Element de Matière',
      },
      save: {
        success: 'Element éffectué avec succès',
      },
      destroy: {
        success: 'Element supprimé avec succès',
      },
      create: {
        success: 'Element créé avec succès',
      },
      update: {
        success: 'Element modifiè avec succès',
      },
      exporter: 'Element',
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        name: "Nom de l'element",
        matter: 'Matière',
        sector: 'Filière',
        cycle: 'Cycle',
        level: 'Niveau',
        elements: 'Eléments', 
      },
    },
    SchoolYear: {
      menu: 'Années Scolaires',
      list: {
        title: 'Années Scolaires',
      },
      edit: {
        title: 'Modifier Année Scolaire',
      },
      new: {
        title: 'Ajout Année Scolaire',
      },
      view: {
        title: 'Année Scolaire',
      },
      save: {
        success: 'Année Scolaire éffectué avec succès',
      },
      destroy: {
        success: 'Année Scolaire supprimé avec succès',
      },
      create: {
        success: 'Année Scolaire créé avec succès',
      },
      update: {
        success: 'Année Scolaire modifiè avec succès',
      },
      exporter: 'Année Scolaire',
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        name: "Nom de l'année scolaire",
      },
    },
    Register: {
      menu: 'Inscription des éléves',
      list: {
        title: 'Inscription des éléves',
      },
      edit: {
        title: 'Modifier Inscription',
      },
      new: {
        title: 'Ajout Nouveau Inscrit',
      },
      view: {
        title: 'Inscrit',
      },
      save: {
        success: 'Inscription éffectué avec succès',
      },
      destroy: {
        success: 'Inscription supprimé avec succès',
      },
      create: {
        success: 'Inscription créé avec succès',
      },
      update: {
        success: 'Inscription modifiè avec succès',
      },
      exporter: 'Inscription',
      enumerators: {
        transport: {
          WithTransport: 'Oui',
          WithoutTransport: 'Non',
        },
        online: {
          Presently: 'Oui',
          Online: 'Non',
        },
        canteen: {
          WithCanteen: 'Oui',
          WithoutCanteen: 'Non',
        },
      },
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        schoolYear: "Année scolaire",
        cycle: 'Cycle',
        level: 'Niveau',
        sector: 'Filière',
        classroom: 'Classe',
        student: 'Etudiant',
        transport: 'Transport',
        online: 'Online',
        canteen: 'Cantine',
      },
    },
    Chat: {
      menu: 'Chat',
      list: {
        title: 'Chat',
      },
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        name: 'Chat',
      },
    },
    EducDirector: {
      menu: 'Directeurs Pédagogiques',
      list: {
        title: "Directeurs Pédagogiques de l'école",
      },
      edit: {
        title: 'Modifier Directeur Pédagogique',
      },
      new: {
        title: 'Ajout Directeur Pédagogique',
      },
      view: {
        title: 'Directeur Pédagogique',
      },
      save: {
        success: 'Direc Pédago éffectué avec succès',
      },
      destroy: {
        success: 'Direc Pédago supprimé avec succès',
      },
      create: {
        success: 'Direc Pédago créé avec succès',
      },
      update: {
        success: 'Direc Pédago modifiè avec succès',
      },
      exporter: 'Direc Pédago',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        Username: "Nom d'utilisateur",
        first_name: 'Prénom',
        last_name: 'Nom',
        email: 'E-mail',
        phone: 'Téléphone',
      },
    },
    Parent: {
      menu: 'Parents',
      list: {
        title: 'Parents',
      },
      edit: {
        title: 'Modifier Parent',
      },
      new: {
        title: 'Ajout Parent',
      },
      view: {
        title: 'Parent',
      },
      save: {
        success: 'Parent éffectué avec succès',
      },
      destroy: {
        success: 'Parent supprimé avec succès',
      },
      create: {
        success: 'Parent créé avec succès',
      },
      update: {
        success: 'Parent modifiè avec succès',
      },
      exporter: 'Parent',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        Username: "Nom d'utilisateur",
        first_name: 'Prénom',
        last_name: 'Nom',
        email: 'E-mail',
        phone: 'Téléphone',
      },
    },
    Security: {
      menu: 'Sécurité',
      list: {
        title: 'Sécurité',
      },
      edit: {
        title: 'Modifier Sécurité',
      },
      new: {
        title: 'Ajout Sécurité',
      },
      view: {
        title: 'Sécurité',
      },
      save: {
        success: 'Sécurité éffectué avec succès',
      },
      destroy: {
        success: 'Sécurité supprimé avec succès',
      },
      create: {
        success: 'Sécurité créé avec succès',
      },
      update: {
        success: 'Sécurité modifiè avec succès',
      },
      exporter: 'Sécurité',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        Username: "Nom d'utilisateur",
        first_name: 'Prénom',
        last_name: 'Nom',
        email: 'E-mail',
        phone: 'Téléphone',
      },
    },
    Assistant: {
      menu: 'Assistants',
      list: {
        title: 'Assistants',
      },
      edit: {
        title: 'Modifier Assistant',
      },
      new: {
        title: 'Ajout Assistant',
      },
      view: {
        title: 'Assistant',
      },
      save: {
        success: 'Assistant éffectué avec succès',
      },
      destroy: {
        success: 'Assistant supprimé avec succès',
      },
      create: {
        success: 'Assistant créé avec succès',
      },
      update: {
        success: 'Assistant modifiè avec succès',
      },
      exporter: 'Assistant',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        Username: "Nom d'utilisateur",
        first_name: 'Prénom',
        last_name: 'Nom',
        email: 'E-mail',
        phone: 'Téléphone',
      },
    },
    Transport: {
      menu: 'Transports',
      list: {
        title: 'Transports',
      },
      edit: {
        title: 'Modifier Transport',
      },
      new: {
        title: 'Ajout Transport',
      },
      view: {
        title: 'Transport',
      },
      save: {
        success: 'Transport éffectué avec succès',
      },
      destroy: {
        success: 'Transport supprimé avec succès',
      },
      create: {
        success: 'Transport créé avec succès',
      },
      update: {
        success: 'Transport modifiè avec succès',
      },
      exporter: 'Transport',
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        number: 'Numéro',
        brand: 'Marque',
        model: 'Modele',
        transport: 'Transport',
      },
    },
    Circuit: {
      menu: 'Circuits',
      list: {
        title: 'Circuits',
      },
      edit: {
        title: 'Modifier Circuit',
      },
      new: {
        title: 'Ajout Circuit',
      },
      view: {
        title: 'Circuit',
      },
      save: {
        success: 'Circuit éffectué avec succès',
      },
      destroy: {
        success: 'Circuit supprimé avec succès',
      },
      create: {
        success: 'Circuit créé avec succès',
      },
      update: {
        success: 'Circuit modifiè avec succès',
      },
      exporter: 'Circuit',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        name: 'Nom du Circuit',
        assistant: 'Assitant(e)',
        transport: 'Transport',
      },
    },
    Neighborhood: {
      menu: 'Quartiers',
      list: {
        title: 'Quartiers',
      },
      edit: {
        title: 'Modifier Quartier',
      },
      new: {
        title: 'Ajout Quartier',
      },
      view: {
        title: 'Quartier',
      },
      save: {
        success: 'Quartier éffectué avec succès',
      },
      destroy: {
        success: 'Quartier supprimé avec succès',
      },
      create: {
        success: 'Quartier créé avec succès',
      },
      update: {
        success: 'Quartier modifiè avec succès',
      },
      exporter: 'Quartier',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        name: 'Nom du Quartier',
        circuit: 'Circuit',
      },
    },
    ResponsibleCycle: {
      menu: 'Responsables - Cycles',
      list: {
        title: 'Affectation Responsables - Cycles',
      },
      edit: {
        title: 'Modifier RC',
      },
      new: {
        title: 'Ajout RC',
      },
      view: {
        title: 'Affectation Responsable - Cycle',
      },
      save: {
        success: 'affec RC éffectué avec succès',
      },
      destroy: {
        success: 'affec RC supprimé avec succès',
      },
      create: {
        success: 'affec RC créé avec succès',
      },
      update: {
        success: 'affec RC modifiè avec succès',
      },
      exporter: 'affectation Responsables Cycles',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        responsible: 'Responsable',
        cycle: 'Cycle',
        schoolYear: 'Année scolaire',
      },
    },
    EducDirectorCycle: {
      menu: 'Direct. Pédag. - Cycles',
      list: {
        title:
          'Affectation Directeurs Pédagogiques - Cycles',
      },
      edit: {
        title: 'Modifier DC',
      },
      new: {
        title: 'Ajout DC',
      },
      view: {
        title: 'Affectation Directeur Pédagogique - Cycle',
      },
      save: {
        success: 'affec DC éffectué avec succès',
      },
      destroy: {
        success: 'affec DC supprimé avec succès',
      },
      create: {
        success: 'affec DC créé avec succès',
      },
      update: {
        success: 'affec DC modifiè avec succès',
      },
      exporter: 'affectation Directeur pedagogique Cycles',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        educDirector: 'Dir Pédagogique',
        cycle: 'Cycle',
        schoolYear: 'Année scolaire',
      },
    },
  },

  roles: {
    user: {
      label: 'utilisateur',
      description: 'Accès complet à toutes les ressources',
    },
    editor: {
      label: 'Rédacteur en chef',
      description:
        "Modifier l'accès à toutes les ressources",
    },
    viewer: {
      label: 'Viewer',
      description: 'View access to all resources',
    },
  },

  user: {
    fields: {
      id: 'Id',
      authenticationUid: 'Authentication Uid',
      avatars_url: 'Avatar',
      email: 'Courriel',
      emails: 'Courriel(s)',
      fullName: 'Nom',
      name: "Nom d'utilisateur",

      first_name: 'Prénom',
      last_name: 'Nom',
      status: 'Statut',
      disabled: 'Desactivé ?',
      phoneNumber: 'Téléphone',
      role: 'Rôle',
      created_at: 'Crée le',
      updatedAt: 'Mise à jour le',
      roleUser: 'Rôle utilisateur',
      roles: 'Rôles',
      createdAtRange: 'Crée le',
      password: 'Mot de passe',
      newpassword: 'Nouveau Mot de passe',
      confirmPassword: 'Confirmation de mot de passe',
      confirm_password: 'Confirmation de mot de passe',

      rememberMe: 'Se souvenir de moi',
      language: 'Langue',
      timezone: 'Fuseau horaire',
      placeholder: {
        timezone: 'Sélectionnez un fuseau horaire',
      },
    },
    enabled: 'Activé ?',
    disabled: 'Désactivé ?',
    validations: {
      // eslint-disable-next-line
      email: 'Adresse mail ${value} invalide',
    },
  },

  auditLog: {
    menu: 'Audit Logs',
    title: 'Audit Logs',
    exporterFileName: 'audit_log_export',
    entityNamesHint:
      'Separate multiple entities using the comma character.',
    fields: {
      id: 'Id',
      timestampRange: 'Period',
      entityName: 'Entity',
      entityNames: 'Entities',
      entityId: 'Entity ID',
      action: 'Action',
      values: 'Values',
      timestamp: 'Date',
      createdByEmail: 'User Email',
    },
  },

  settings: {
    title: 'Réglages',
    menu: 'Réglages',
    save: {
      success:
        'Réglages enregistrés avec succès. La page se rechargera dans {0} secondes pour que les changements prennent effet.',
    },
    fields: {
      theme: 'Theme',
    },
    colors: {
      default: 'Default',
      cyan: 'Cyan',
      'geek-blue': 'Geek Blue',
      gold: 'Gold',
      lime: 'Lime',
      magenta: 'Magenta',
      orange: 'Orange',
      'polar-green': 'Polar Green',
      purple: 'Purple',
      red: 'Red',
      volcano: 'Volcano',
      yellow: 'Yellow',
    },
  },

  home: {
    menu: 'Accueil',
    message: `Cette page utilise de fausses données à des fins de démonstration uniquement. Vous pouvez la modifier à l'adresse frontend/view/home/HomePage.js.`,
    charts: {
      day: 'Journée',
      red: 'Rouge',
      green: 'Vert',
      yellow: 'Jaune',
      grey: 'Gris',
      blue: 'Bleu',
      sales: 'Ventes',
      visitor: 'Visiteurs',
      months: {
        1: 'Janvier',
        2: 'Février',
        3: 'Mars',
        4: 'Avril',
        5: 'Mai',
        6: 'Juin',
        7: 'Juillet',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
    },
  },

  live: {
    menu: 'Live',
  },

  errors: {
    backToHome: 'Retour à l Accueil',
    403: `Désolé, vous n'avez pas accès à cette page`,
    404: "Désolé, la page que vous avez visitée n'existe pas",
    500: 'Désolé, le serveur signale une erreur',
    forbidden: {
      message: 'Interdit',
    },
    validation: {
      message: 'Une erreur est survenue',
    },
    defaultErrorMessage: 'Ops, Une erreur est survenue',
  },

  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} est invalide',
      required: '${path} est exigé',
      oneOf:
        "${path} doit être l'une des valeurs suivantes :  ",
      notOneOf:
        "${path} ne doit pas être l'une des valeurs suivantes : ${values}",
      notType: ({ path, type, value, originalValue }) => {
        return `${path} doit être un ${type}`;
      },
    },
    string: {
      length:
        '${path} doivent être exactement des caractères ',
      min: '${path} doit être au moins ${min} characters',
      max: '${path} doit être au maximum ${max} characters',
      matches:
        '${path} doit correspondre à ce qui suit : "${regex}"',
      email: '${path} doit être un email valide',
      url: '${path} doit être une URL valide',
      trim: '${path} doit être une corde coupée',
      lowercase:
        '${path} doit être une chaîne en minuscules',
      uppercase:
        '${path} doit être une chaîne en majuscules',
      selected: '${path} doit être sélectionné',
    },
    number: {
      min: '${path} doit être supérieure ou égale à ',
      max: '${path} doit être inférieure ou égale à ${max}',
      lessThan: '${path} doit être inférieur à ${less}',
      moreThan: '${path} doit être supérieure à ${more}',
      notEqual:
        '${path} ne doit pas être égal à ${notEqual}',
      positive: '${path} doit être un nombre positif',
      negative: '${path} doit être un nombre négatif',
      integer: '${path} doit être un nombre entier',
    },
    date: {
      min: '${path} Le champ doit être postérieur à ${min}',
      max:
        '${path}  le champ doit être à une date antérieure à ${max}',
    },
    boolean: {},
    object: {
      noUnknown:
        "${path} Le champ ne peut pas avoir de clés non spécifiées dans la forme de l'objet",
    },
    array: {
      min:
        '${path} Le champ doit avoir au moins ${min} items',
      max:
        "${path} Le champ doit avoir un nombre d'éléments inférieur ou égal à ${max}.",
    },
  },

  /* eslint-disable */
  fileUploader: {
    upload: 'Télécharger',
    image: 'Vous devez télécharger une image',
    size:
      'Le dossier est trop gros. La taille maximale autorisée est {0}',
    formats: `Format non valide. Doit être '{0}'.`,
  },

  importer: {
    line: 'Ligne',
    status: 'Statut',
    pending: 'En attente',
    imported: 'Imported',
    error: 'Erreur',
    total: `{0} impo removed successfully',
  },
  roles: {
    label: 'Roles',
    doRemoveAllSelectedSuccess:
      rted, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage:
      'Do not navigate away from this page or import will be stopped.',
    completed: {
      success:
        'Import completed. All rows were successfully imported.',
      someErrors:
        'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.',
    },
    form: {
      downloadTemplate: 'Download the template',
      hint:
        'Click or drag the file to this area to continue',
    },
    list: {
      discardConfirm:
        'Are you sure? Non-imported data will be lost.',
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel:
        'Only excel (.xlsx) files are allowed',
      invalidFileUpload:
        'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported',
    },
  },

  autocomplete: {
    loading: 'Loading...',
  },

  imagesViewer: {
    noImage: 'No image',
  },
};

export default fr;
