import destroyActions from "modules/shared/destroy/destroyActions";
import listActions from "modules/security/list/securityListActions";
import SecurityService from "modules/security/securityService";

const prefix = "Security_DESTROY";

export default destroyActions({
  prefix,
  destroyAllFn: SecurityService.destroyAll,
  destroySuccessMessageI18nKey: "entities.Security.destroy.success",
  destroyAllSuccessMessageI18nKey: "entities.Security.destroyAll.success",
  redirectTo: "/security",
  listActions,
});
