import model from 'modules/teachers/teachersModel';

const { fields } = model;

export default [
  fields.id,
  fields.cin,
  fields.matric,
  fields.first_name,
  fields.last_name,
  fields.email,
  fields.phone,
  fields.permanent,
];
