import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';
import moment from 'moment';
import service from 'modules/roomSession/roomSessionService';

export default class MatterService {
  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
          roomsession(where: { id: { _eq: ${id} } }) {
            name
            start_time
            end_time
            day
            createdAt
            updatedAt
            week {
              id
              start_date
              end_date
              school_year {
                id
                name
              }
            }
            element {
              id
              element_matter {
                id
                name
              }
            }
            classroom_teacher_matter {
              id
              classroom {
                id
                name
                level_sector {
                  id
                  level {
                    id
                    name
                    cycle {
                      id
                      name
                    }
                  }
                  sector {
                    id
                    name
                  }
                }
              }
              teacher {
                id
                first_name
                last_name
                user {
                  id
                }
              }
              matter {
                id
                name
              }
            }
          }
      }
    `,
    });

    return response.data.roomsession[0];
  }

  static async list(filter, orderBy, limit, offset) {
    if (
      filter &&
      filter.createdAt &&
      filter.createdAtRange
    ) {
      delete filter.createdAt;
    }
    if (filter == undefined) filter = {};

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
      query MyQuery {
        roomsession( ${
          limit != 0 ? `limit:${limit},` : ''
        } ${
          offset != 0 ? `offset:${offset} ,` : ''
        } order_by:{${
          orderBy ? orderBy : ''
        }}, where: {id:${
          filter.id ? ` {_eq: ${filter.id}}` : '{}'
        },
        classroom_teacher_matter: {
          classroom: {
            id:
        ${
          filter.classroom
            ? `{_eq: ${filter.classroom}}`
            : '{}'
        }
          }
          matter: {
            id:
        ${filter.matter ? `{_eq: ${filter.matter}}` : '{}'}
          }
        }
    
  }) {
      name
      start_time
      end_time
      day
      createdAt
      updatedAt
      week {
        id
        start_date
        end_date
        school_year {
          id
          name
        }
      }
      element {
        id
        element_matter {
          id
          name
        }
      }
      classroom_teacher_matter {
        id
        classroom {
          id
          name
          level_sector {
            id
            level {
              id
              name
              cycle {
                id
                name
              }
            }
            sector {
              id
              name
            }
          }
        }
        teacher {
          id
          first_name
          last_name
          user {
            id
          }
        }
        matter {
          id
          name
        }
      }
    }
      }
      `,
      });

    var responseJson = response.data.roomsession;

    responseJson.map((item, index) => {
      if (item.element == null) {
        item.element = {
          element_matter: {
            id : 0,
            name: 'None'
          }
        }
      }
    });

    var dataCalendar = {
      data: {
        type: 'teacher',
        roomsession: response.data.roomsession,
      },
    };

    return dataCalendar;
  }

  static async getTimeTable(data) {

    var current_date_time = await service.get_current_time();
    var currentDate = current_date_time;
    var currentTime = current_date_time.split("T")[1];
    currentTime = currentTime.slice(0, -4)+'1';

    console.log(currentTime);
    console.log(current_date_time)

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {
          roomsession_aggregate(where:{
            element: { element_matter: {id: {_eq: ${
              data.element || null
            }}} },
            classroom_teacher_matter: {id_classroom: {_eq: ${
              data.classroom || null
            }}, id_matter: {_eq: ${
              data.matter || null
            }}, id_teacher: {_eq: ${
              data.teacher ? data.teacher.id : null
            }}, classroom: {level_sector: {id_level: {_eq: ${
              data.level || null
            }}, id_sector: {_eq: ${
              data.sector || null
            }}, level: { ${
              data.cycle
                ? `cycle: {id: {_eq:${data.cycle}}}`
                : ''
            }}}, ${data.student ? `registers: {
              id_student: {_eq: ${
                data.student || null
                }
              }
            }` : ''}
              }}, week: {  ${
              data.week
                ? `id: {_eq: ${data.week}}`
                : `start_date: { _lte: "${currentDate}" }
            end_date: { _gte: "${currentDate}" }`
            } }})
          {
            aggregate {
              count
            }
          }
          roomsession(where:{
            element: { element_matter: {id: {_eq: ${
              data.element || null
            }}} },
            classroom_teacher_matter: {id_classroom: {_eq: ${
            data.classroom || null
          }}, id_matter: {_eq: ${
          data.matter || null
        }}, id_teacher: {_eq: ${
          data.teacher ? data.teacher.id : null
        }}, classroom: {level_sector: {id_level: {_eq: ${
          data.level || null
        }}, id_sector: {_eq: ${
          data.sector || null
        }}, level: { ${
          data.cycle
            ? `cycle: {id: {_eq:${data.cycle}}}`
            : ''
        }}},  ${data.student ? `registers: {
                id_student: {_eq: ${
                  data.student || null
                  }
                }
              }` : ''}
            }}, week: {  ${
          data.week
            ? `id: {_eq: ${data.week}}`
            : `start_date: { _lte: "${currentDate}" }
        end_date: { _gte: "${currentDate}" }`
        } }}) {
            name
            start_time
            end_time
            day
            createdAt
            updatedAt
            week {
              id
              school_year {
                id
                name
              }
            }
            element {
              id
              element_matter {
                id
                name
              }
            }
            classroom_teacher_matter {
              id
              classroom {
                id
                name
                level_sector {
                  id
                  level {
                    id
                    name
                    cycle {
                      id
                      name
                    }
                  }
                  sector {
                    id
                    name
                  }
                }
              }
              teacher {
                id
                first_name
                last_name
              }
              matter {
                id
                name
              }
            }
          }
        }
        
        `,
      });

    var responseJson = response.data.roomsession;

    responseJson.map((item, index) => {
      if (item.element == null) {
        item.element = {
          element_matter: {
            id : 0,
            name: 'None'
          }
        }
      }
    });

    responseJson.count = response.data.roomsession_aggregate.aggregate.count;

    return responseJson;
  }
}
