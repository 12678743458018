import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';
import { getLanguageCode } from 'i18n';

export default class ExamService {
  static async update(id, data) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        update_exam(where: {id: {_eq: ${id}}}, _set: {name: "${data.name}"}) {
          affected_rows
        }
      }
      
      `,
    });

    return response.data.update_exam;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        delete_exam(where: {id: {_eq: ${ids}}}) {
          affected_rows
        }
      }
      
      `,
    });

    return response.data.delete_exam;
  }

  static async create(data) {
    
    await data.exams.map( async (item, index) => {
      await graphqlClient.config().mutate({
        mutation: gql`
        mutation MyMutation {
          insert_exam(objects: {name: "${item.toLowerCase()}"}) {
            affected_rows
          }
       }    
        `,
      });
    })
    
    return null;
  }

  // static async create(data) {
  //   const response = await graphqlClient.config().mutate({
  //     mutation: gql`
  //     mutation MyMutation {
  //       insert_exam(objects: {name: "${data.name.toLowerCase()}"}) {
  //         affected_rows
  //       }
  //    }    
  //     `,
  //   });

  //   return response.data.insert_exam;
  // }

  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        exam(where: {id: {_eq: ${id}}}) {
          id
          name
          created_at
          updated_at
        }
      }
      
      `,
    });

    return response.data.exam[0];
  }

  static async list(filter = {}, orderBy, limit, offset) {
    if (
      filter &&
      filter.createdAt &&
      filter.createdAtRange
    ) {
      delete filter.createdAt;
    }
    if (filter == undefined) filter = {};

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {

          exam_aggregate(where: {id: {_eq: ${filter.id || null}},
            name:
            ${
              filter.name ? `{_like: "%${filter.name}%"}` : '{}'
            }
            
            ${
              filter.createdAt
                ? `,created_at:{_eq:"${filter.createdAt.format()}"}`
                : ''
            },
          
            updated_at:
          ${
            filter.updatedAt
              ? `{_eq:"${filter.updatedAt.format()}"}`
              : '{}'
          }
          
          ${
            filter.createdAtRange
              ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
              : ''
          }
            }) {
            aggregate {
              count
            }
          }
          
          exam( ${limit != 0 ? `limit:${limit},` : ''} ${
          offset != 0 ? `offset:${offset} ,` : ''
        } order_by:{${
          orderBy ? orderBy : ''
        }}, where: {id: {_eq: ${filter.id || null}},
        name:
        ${
          filter.name ? `{_like: "%${filter.name}%"}` : '{}'
        }
        
        ${
          filter.createdAt
            ? `,created_at:{_eq:"${filter.createdAt.format()}"}`
            : ''
        },
      
        updated_at:
      ${
        filter.updatedAt
          ? `{_eq:"${filter.updatedAt.format()}"}`
          : '{}'
      }
      
      ${
        filter.createdAtRange
          ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
          : ''
      }
        }) {
            id
            name
            created_at
            updated_at
          }
        }
        `,
      });

    var data = response.data.exam;

    var new_Data = data.map((e) => ({
      ...e,
    }));
    new_Data.count =
      response.data.exam_aggregate.aggregate.count;
    return new_Data;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        exam(where: {name: {_like: "%${query}%"}}) {
          id
          name
        }
      }
      `,
    });

    return response.data.exam;
  }

  static async listSelect() {
    const response = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          exam {
            id
            name
          }
        }
      `,
    });
    return response.data.exam;
  }
}
