import actions from 'modules/timeTable/standarUser/timeTableActions';

const initialData = {
  timeTable: null,
  loading: true,
  errorMessage: null,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.ERROR_MESSAGE_CLEARED) {
    return {
      ...state,
      errorMessage: null,
    };
  }

  if (type === actions.LIST_TIMETABLE_START) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.LIST_TIMETABLE_SUCCESS) {
    return {
      ...state,
      loading: false,
      timeTable: payload.timeTable,
    };
  }

  if (type === actions.LIST_TIMETABLE_ERROR) {
    return {
      ...state,
      loading: true,
    };
  }

  return state;
};
