import actions from 'modules/roomController/roomControllerActions';

const initialData = {
  rows: null,
  loading: true,
  errorMessage: null,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.ERROR_MESSAGE_CLEARED) {
    return {
      ...state,
      errorMessage: null,
    };
  }

  if (type === actions.RoomController_START) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.RoomController_SUCCESS) {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
    };
  }

  if (type === actions.RoomController_ERROR) {
    return {
      ...state,
      loading: true,
    };
  }

  return state;
};
