import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/roomURL/importer/roomURLImporterSelectors';
import RoomURLService from 'modules/roomURL/roomURLService';
import fields from 'modules/roomURL/importer/roomURLImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'roomURL',
  selectors,
  RoomURLService.import,
  fields,
  i18n('entities.RoomURL.importer.fileName'),
);
