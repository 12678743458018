import list from "modules/security/list/securityListReducers";
import form from "modules/security/form/securityFormReducers";
import view from "modules/security/view/securityViewReducers";
import destroy from "modules/security/destroy/securityDestroyReducers";
import importerReducer from "modules/security/importer/securityImporterReducers";
import { combineReducers } from "redux";

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
