import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';
import role from 'security/roles';
import authService from 'modules/auth/authService';

export default class LevelSectorExamService {
  static async update(id, data) {

    const response2 = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          level_sector(where: { id: { _eq: ${data.levelSector} }}) {
            id
            name
          }
          semester(where: { id: { _eq: ${data.semester} }}) {
            id
            name
          }
          exam(where: { id: { _eq: ${data.exam} }}) {
            id
            name
          }
        }
      `,
    });
    var nameLevelSector = response2.data.level_sector[0].name;
    var nameExam = response2.data.semester[0].name;
    var nameSemester = response2.data.exam[0].name;

    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        update_level_sector_exam(where: {id: {_eq: ${id}}}, _set: {id_exam: ${data.exam}, id_semester: ${data.semester}, id_level_sector: ${data.levelSector}, purcent: ${data.purcent}, name: "${nameLevelSector}-${nameExam}-${nameSemester}"}) {
          affected_rows
        }
      }      
      `,
    });

    return response.data.update_level_sector_exam;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        delete_level_sector_exam(where: {id: {_eq: ${ids}}}) {
          affected_rows
        }
      }
      `,
    });

    return response.data.delete_level_sector_exam;
  }

  static async create(data) {
    
    const response2 = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          level_sector(where: { id: { _eq: ${data.levelSector} }}) {
            id
            name
          }
          semester(where: { id: { _eq: ${data.semester} }}) {
            id
            name
          }
          exam(where: { id: { _eq: ${data.exam} }}) {
            id
            name
          }
        }
      `,
    });
    var nameLevelSector = response2.data.level_sector[0].name;
    var nameExam = response2.data.semester[0].name;
    var nameSemester = response2.data.exam[0].name;

    const response = await graphqlClient.config().mutate({
      mutation: gql`
        mutation MyMutation {
          insert_level_sector_exam(objects: {id_exam: ${data.exam}, id_semester: ${data.semester}, id_level_sector: ${data.levelSector}, purcent: ${data.purcent}, name: "${nameLevelSector}-${nameExam}-${nameSemester}"}) {
            affected_rows
          }
        }
      `,
    });

    return response.data.insert_level_sector_exam;
  }

  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        level_sector_exam(
          where: {id: {_eq: ${id}}}
        ) {
          semester {
            id
            name
          }
          purcent
          level_sector {
            id
            name
            sector {
              name
              id
            }
            level {
              name
              id
              cycle {
                id
                name
              }
            }
          }
          exam {
            id
            name
          }
          id
          updated_at
          created_at
        }
      }
      `,
    });

    return response.data.level_sector_exam;
  }

  static async list(filter, orderBy, limit, offset) {
    if (
      filter &&
      filter.created_at &&
      filter.createdAtRange
    ) {
      delete filter.created_at;
    }
    if (filter == undefined) filter = {};

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {

          level_sector_exam_aggregate(where: {id: ${
            filter.id ? ` {_eq: ${filter.id}}` : '{}'
          },                 
          level_sector: {
            id_sector:
                ${
                  filter.sector
                    ? `{_eq: ${filter.sector.id}}`
                    : '{}'
                },
              id_level:
              ${
                filter.level
                  ? `{_eq: ${filter.level.id}}`
                  : '{}'
              }
          },
          id_semester:
            ${
              filter.semester
                ? `{_eq: ${filter.semester.id}}`
                : '{}'
            }
          ,
          purcent:
            ${
              filter.purcent
                ? `{_eq: ${filter.purcent}}`
                : '{}'
            }
          ,
          exam: {
            id: ${
              filter.exam
                ? `{_eq: ${filter.exam.id}}`
                : '{}'
            }
          }
                
                ${
                  filter.created_at
                    ? `,created_at:{_eq:"${filter.created_at.format()}"}`
                    : ''
                },
              
                  updated_at:
              ${
                filter.updated_at
                  ? `{_eq:"${filter.updated_at.format()}"}`
                  : '{}'
              }
              
              ${
                filter.createdAtRange
                  ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
                  : ''
              }
                }) {
            aggregate {
              count
            }
          }

          level_sector_exam( ${
            limit != 0 ? `limit:${limit},` : ''
          } ${
          offset != 0 ? `offset:${offset} ,` : ''
        } order_by:{${
          orderBy ? orderBy : ''
        }}, where: {id: ${
          filter.id ? ` {_eq: ${filter.id}}` : '{}'
        },              
          level_sector: {
            id_sector:
                ${
                  filter.sector
                    ? `{_eq: ${filter.sector.id}}`
                    : '{}'
                },
                id_level:
                ${
                  filter.level
                    ? `{_eq: ${filter.level.id}}`
                    : '{}'
                }
            },
            id_semester:
            ${
              filter.semester
                ? `{_eq: ${filter.semester.id}}`
                : '{}'
            }
            ,
            purcent:
              ${
                filter.purcent
                  ? `{_eq: ${filter.purcent}}`
                  : '{}'
              }
            ,
            exam: {
              id: ${
                filter.exam
                  ? `{_eq: ${filter.exam.id}}`
                  : '{}'
              }
            }
              
              ${
                filter.created_at
                  ? `,created_at:{_eq:"${filter.created_at.format()}"}`
                  : ''
              },
            
                updated_at:
            ${
              filter.updated_at
                ? `{_eq:"${filter.updated_at.format()}"}`
                : '{}'
            }
            
            ${
              filter.createdAtRange
                ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
                : ''
            }
              }) {
                semester {
                  id
                  name
                }
                purcent
                level_sector {
                  id
                  name
                  sector {
                    name
                    id
                  }
                  level {
                    name
                    id
                    cycle {
                      id
                      name
                    }
                  }
                }
                exam {
                  id
                  name
                }
                id
                updated_at
                created_at
              }
            }
        `,
      });

    let data = response.data.level_sector_exam;
    let newData = data.map((item) => ({
      ...item,
      id: item.id,
      sector: item.level_sector.sector.name,
      level: item.level_sector.level.name,
      cycle: item.level_sector.level.cycle.name,
      exam: item.exam.name,
      semester: item.semester.name,
      purcent: item.purcent,
    }));

    newData.count = response.data.level_sector_exam_aggregate.aggregate.count;

    return newData;
  }

  static async listSelect() {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        level_sector_exam {
          name
          id
        }
      }
      `,
    });
    return response.data.level_sector_exam;
  }

}
