import SemesterService from 'modules/semester/semesterService';
import formActions from 'modules/shared/form/formActions';

const prefix = 'MATTER_FORM';

export default formActions({
  prefix,
  createFn: SemesterService.create,
  createSuccessMessageI18nKey:
    'entities.Semester.create.success',
  updateFn: SemesterService.update,
  updateSuccessMessageI18nKey:
    'entities.Semester.update.success',
  findFn: SemesterService.find,
  redirectTo: '/Semester',
});
