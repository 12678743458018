import model from 'modules/classroom/classroomModel';

const { fields } = model;

export default [
  fields.id,
  fields.name,
  fields.id_level_sector,
  fields.codeMassar,
];
