import { i18n } from 'i18n';
import IdField from 'modules/shared/fields/idField';
import DateTimeField from 'modules/shared/fields/dateTimeField';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField';
import StringField from 'modules/shared/fields/stringField';
import EnumeratorField from 'modules/shared/fields/enumeratorField';
import DecimalRangeField from 'modules/shared/fields/decimalRangeField';
import DecimalField from 'modules/shared/fields/decimalField';
import RelationToOneField from 'modules/shared/fields/relationToOneField';
import FilesField from 'modules/shared/fields/filesField';
import ImagesField from 'modules/shared/fields/imagesField';
import JsonField from 'modules/shared/fields/jsonField';

function label(name) {
  return i18n(`entities.LevelSectorMatterExam.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(
    `entities.LevelSectorMatterExam.enumerators.${name}.${value}`,
  );
}

const fields = {
  id: new IdField('id', label('id')),
  cycle: new JsonField('cycle', label('cycle'), {
    required: true,
  }),
  semester: new JsonField('semester', label('semester'), {
    required: true,
  }),
  grade_plafond: new StringField('grade_plafond', label('grade_plafond'), {
    required: true,
  }),
  coefficient: new StringField('coefficient', label('coefficient'), {
    required: true,
  }),
  level: new JsonField('level', label('level'), {
    required: true,
  }),
  element: new JsonField('element', label('element'), {
    required: true,
  }),
  sector: new JsonField('sector', label('sector'), {
    required: true,
  }),
  matter: new JsonField('matter', label('matter'), {
    required: true,
  }),
  exam: new JsonField('exam', label('exam'), {
    required: true,
  }),
  levelSectorExam: new JsonField('levelSectorExam', label('levelSectorExam'), {
    required: true,
  }),
  id_level: new JsonField('id_level', 'id_niveau', {}),
  id_sector: new JsonField(
    'id_sector',
    'id_filiere',
    {},
  ),
  created_at: new DateTimeField(
    'created_at',
    label('created_at'),
  ),
  updated_at: new DateTimeField(
    'updated_at',
    label('updated_at'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export default {
  fields,
};
