import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';
import moment from 'moment';
import momentTz from 'moment-timezone';

export default class RoomsessionService {
  static async get_current_time() {
    var response = await graphqlClient.config().query({
      query: gql`
        query test {
          get_current_time {
            now
          }
        }
      `,
    });
    return response.data.get_current_time[0].now;
  }

  static async update(id, data) {

    const response1 = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        classroom_teacher_matter(where: {id_classroom: {_eq: ${
          data.classroom
            ? data.classroom.id || data.classroom
            : null
        }}, id_teacher: {_eq: ${
        data.teacher
          ? data.teacher.id || data.teacher
          : null
      }}, id_matter: {_eq: ${
        data.matter ? data.matter.id || data.matter : null
      }}}) {
          id
        }
      }
      `,
    });

    var id_CTM = response1.data.classroom_teacher_matter[0].id;

    var WeekRow = {}, AllWeeks = [];

        
    const response10 = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        level_sector_element(where: {id_element: {_eq: ${data.element.id ? data.element.id : data.element || null}}, level_sector: {id_level: {_eq: ${data.level.id ? data.level.id : data.level || null}}, id_sector: {_eq: ${data.sector.id ? data.sector.id : data.sector || null}}}}) {
          id
        }
      }
      `,
    });

    var level_sector_element = response10.data.level_sector_element[0].id;

    if ( data.isAll ) {
      
      const response2 = await graphqlClient.config().query({
        query: gql`
        query MyQuery {
          week(order_by: {start_date: desc}, where: {id: {_eq: ${data.week}}}) {
            id
            start_date
            end_date
          }
        }
        `,
      });
  
      WeekRow = response2.data.week[0];

      const response3 = await graphqlClient.config().query({
        query: gql`
        query MyQuery {
          week(order_by: {start_date: asc}) {
            id
            start_date
            end_date
          }
        }
        `,
      });
  
      AllWeeks = response3.data.week;

      var flag = true;

      var AllWeeksResult = [];

      AllWeeks.map((item, index) => {
        if ( flag = true ) {
          if ( moment(item.start_date, 'YYYY-MM-DD').toDate() >= moment(WeekRow.start_date, 'YYYY-MM-DD').toDate() ) {
            AllWeeksResult.push(item.id);
          } else  {
            flag = false;
          }
        }
      });

      const response4 = await graphqlClient.config().query({
        query: gql`
        query MyQuery {
          roomsession(where: {id: {_eq: ${id}}}) {
            day
            start_time
            end_time
          }
        }
        `,
      });

      var roomsession = response4.data.roomsession[0];      
      
      AllWeeksResult.map( async (item, index) => {
        await graphqlClient.config().mutate({
          mutation: gql`
          mutation MyMutation {
            update_roomsession(where: {day: {_eq: ${parseInt(roomsession.day)}}, end_time: {_eq: "${roomsession.end_time}"}, start_time: {_eq: "${roomsession.start_time}"}, id_week: {_eq: ${
              item
            }}, classroom_teacher_matter: {classroom: {id: {_eq: ${data.classroom ? data.classroom.id || data.classroom: null}}}}}, _set: {id_classroom_teacher_matter: ${id_CTM}, id_week: ${
            item
          }, id_element: ${level_sector_element}, day: ${parseInt(data.day)}, end_time: "${moment
            .tz(data.end_time, 'Africa/Casablanca')
            .format('HH:mm:ssz')}", start_time: "${moment
            .tz(data.start_time, 'Africa/Casablanca')
            .format('HH:mm:ssz')}"}) {
              affected_rows
            }
          }
          `,
        });
      })

      return null;
    } else {
      const response = await graphqlClient.config().mutate({
        mutation: gql`
        mutation MyMutation {
          update_roomsession(where: {id: {_eq: ${id}}}, _set: {id_classroom_teacher_matter: ${id_CTM}, id_week: ${
          data.week.id ? data.week.id : data.week || null
        }, id_element: ${level_sector_element}, day: ${parseInt(data.day)}, end_time: "${moment
          .tz(data.end_time, 'Africa/Casablanca')
          .format('HH:mm:ssz')}", start_time: "${moment
          .tz(data.start_time, 'Africa/Casablanca')
          .format('HH:mm:ssz')}"}) {
            affected_rows
          }
        }
        `,
      });
  
      return response.data.update_roomsession;

    }
  }

  static async destroyAll(ids, data) {

    console.log(data)
    console.log(data.matter)
    console.log(data.teacher)
    // classroom schoolYear 
    var WeekRow = {}, AllWeeks = [];
    if ( data.isAll ) {
      
      const response2 = await graphqlClient.config().query({
        query: gql`
        query MyQuery {
          week(order_by: {start_date: desc}, where: {id: {_eq: ${data.week}}}) {
            id
            start_date
            end_date
          }
        }
        `,
      });
  
      WeekRow = response2.data.week[0];

      const response3 = await graphqlClient.config().query({
        query: gql`
        query MyQuery {
          week(order_by: {start_date: asc}) {
            id
            start_date
            end_date
          }
        }
        `,
      });
  
      AllWeeks = response3.data.week;

      var flag = true;

      var AllWeeksResult = [];

      AllWeeks.map((item, index) => {
        if ( flag = true ) {
          if ( moment(item.start_date, 'YYYY-MM-DD').toDate() >= moment(WeekRow.start_date, 'YYYY-MM-DD').toDate() ) {
            AllWeeksResult.push(item.id);
          } else  {
            flag = false;
          }
        }
      });

      const response4 = await graphqlClient.config().query({
        query: gql`
        query MyQuery {
          roomsession(where: {id: {_eq: ${ids}}}) {
            day
            start_time
            end_time
          }
        }
        `,
      });

      var roomsession = response4.data.roomsession[0];

      const response5 = await graphqlClient.config().query({
        query: gql`
          query MyQuery {
            classroom_teacher_matter(where: {id_classroom: {_eq: ${data.classroom}}, id_teacher: {_eq: ${data.teacher}}, id_matter: {_eq: ${data.matter}}}) {
              id
            }
          }
        `,
      });

      var classroom_teacher_matter = response5.data.classroom_teacher_matter[0].id;
      
      AllWeeksResult.map( async (item, index) => {
        await graphqlClient.config().mutate({
          mutation: gql`
          mutation MyMutation {
            delete_roomsession(where: {id_classroom_teacher_matter: {_eq: ${classroom_teacher_matter}}, day: {_eq: ${parseInt(roomsession.day)}}, end_time: {_eq: "${roomsession.end_time}"}, start_time: {_eq: "${roomsession.start_time}"}, id_week: {_eq: ${item}}}) {
              affected_rows
            }
          }
          `,
        });
      })

      return null;
    } else {
      const response = await graphqlClient.config().mutate({
        mutation: gql`
        mutation MyMutation {
          delete_roomsession(where: {id: {_eq: ${ids}}}) {
            affected_rows
          }
        }
        `,
      });
  
      return response.data.delete_roomsession;
    } 
    
  }

  static async create(data) {
    const response1 = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        classroom_teacher_matter(where: {id_classroom: {_eq: ${data.classroom}}, id_teacher: {_eq: ${data.teacher}}, id_matter: {_eq: ${data.matter}}}) {
          id
        }
      }
      `,
    });

    var id_CTM = response1.data.classroom_teacher_matter[0].id;

        
    const response10 = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        level_sector_element(where: {id_element: {_eq: ${data.element.id ? data.element.id : data.element || null}}, level_sector: {id_level: {_eq: ${data.level.id ? data.level.id : data.level || null}}, id_sector: {_eq: ${data.sector.id ? data.sector.id : data.sector || null}}}}) {
          id
        }
      }
      `,
    });

    var level_sector_element = response10.data.level_sector_element[0].id;

    var WeekRow = {}, AllWeeks = [];
    if ( data.isAll ) {
      const response2 = await graphqlClient.config().query({
        query: gql`
        query MyQuery {
          week(order_by: {start_date: desc}, where: {id: {_eq: ${data.week}}}) {
            id
            start_date
            end_date
          }
        }
        `,
      });
  
      WeekRow = response2.data.week[0];

      const response3 = await graphqlClient.config().query({
        query: gql`
        query MyQuery {
          week(order_by: {start_date: asc}) {
            id
            start_date
            end_date
          }
        }
        `,
      });
  
      AllWeeks = response3.data.week;

      var flag = true;

      var AllWeeksResult = [];

      AllWeeks.map((item, index) => {
        if ( flag = true ) {
          if ( moment(item.start_date, 'YYYY-MM-DD').toDate() >= moment(WeekRow.start_date, 'YYYY-MM-DD').toDate() ) {
            AllWeeksResult.push(item.id);
          } else  {
            flag = false;
          }
        }
      });

      AllWeeksResult.map( async (item, index) => {
        await graphqlClient.config().mutate({
          mutation: gql`
          mutation MyMutation {
            insert_roomsession(objects: {id_classroom_teacher_matter: ${id_CTM}, id_week:${
            item
          }, id_element: ${level_sector_element}, end_time: "${moment
            .tz(data.end_time, 'Africa/Casablanca')
            .format('HH:mm:ss z')}", start_time: "${moment
            .tz(data.start_time, 'Africa/Casablanca')
            .format('HH:mm:ss z')}", day: ${parseInt(
            data.day,
          )}}) {
              affected_rows
            }
          }
          `,
        });
      })

      return null;
    } else {
      const response = await graphqlClient.config().mutate({
        mutation: gql`
        mutation MyMutation {
          insert_roomsession(objects: {id_classroom_teacher_matter: ${id_CTM}, id_week:${
          data.week
        }, id_element: ${level_sector_element}, end_time: "${moment
          .tz(data.end_time, 'Africa/Casablanca')
          .format('HH:mm:ss z')}", start_time: "${moment
          .tz(data.start_time, 'Africa/Casablanca')
          .format('HH:mm:ss z')}", day: ${parseInt(
          data.day,
        )}}) {
            affected_rows
          }
        }
        `,
      });
  
      return response.data.insert_roomsession;
    }

    
  }

  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
          roomsession(where: {id:{_eq: ${id}}}) {
            id
            name
            start_time
            end_time
            day
            createdAt
            updatedAt
            week {
              id
              start_date
              end_date
              name
              school_year {
                id
                name
              }
            }
            element {
              id
              element_matter {
                id
                name
              }
            }
            classroom_teacher_matter {
              id
              classroom {
                id
                name
                level_sector {
                  id
                  level {
                    id
                    name
                    cycle {
                      id
                      name
                    }
                  }
                  sector {
                    id
                    name
                  }
                }
              }
              teacher {
                id
                first_name
                last_name
                user {
                  id
                }
              }
              matter {
                id
                name
              }
            }
          }
        }
      `,
    });

    return response.data.roomsession;
  }

  static async list(filter, orderBy, limit, offset) {
    if (
      filter &&
      filter.createdAt &&
      filter.createdAtRange
    ) {
      delete filter.createdAt;
    }
    if (filter == undefined) filter = {};

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {
          roomsession_aggregate(
            where: {
              
              id: {_eq: ${filter.id || null}},
              id_week: {_eq: ${
                filter.week || null
              }}, classroom_teacher_matter: {
              id_matter: {_eq: ${filter.matter || null}},
              id_classroom: {_eq: ${
                filter.classroom || null
              }},
        id_teacher: {_eq: ${
          filter.teacher ? filter.teacher.id : null
        }}
      
      }, 
        
        day: {_eq: ${filter.day || null}}
        start_time: {_gte: ${
          filter.start_time
            ? `"${moment
                .tz(filter.start_time, 'Africa/Casablanca')
                .format('HH:mm:ss z')}"`
            : null
        }}
        end_time: {_lte: ${
          filter.end_time
            ? `"${moment
                .tz(filter.end_time, 'Africa/Casablanca')
                .format('HH:mm:ss z')}"`
            : null
        }}
  }) {
            aggregate {
              count
            }
          }
          roomsession(limit: ${limit},offset:${offset} ,
            order_by: {${orderBy || ''}},
          
            where: {
              
              id: {_eq: ${filter.id || null}},
              id_week: {_eq: ${
                filter.week || null
              }}, classroom_teacher_matter: {
              id_matter: {_eq: ${filter.matter || null}},
              id_classroom: {_eq: ${
                filter.classroom || null
              }},
        id_teacher: {_eq: ${
          filter.teacher ? filter.teacher.id : null
        }}
      
      }, 
        
        day: {_eq: ${filter.day || null}}
        start_time: {_gte: ${
          filter.start_time
            ? `"${moment
                .tz(filter.start_time, 'Africa/Casablanca')
                .format('HH:mm:ss z')}"`
            : null
        }}
        end_time: {_lte: ${
          filter.end_time
            ? `"${moment
                .tz(filter.end_time, 'Africa/Casablanca')
                .format('HH:mm:ss z')}"`
            : null
        }}
  }) {
            id
            name
            start_time
            end_time
            day
            createdAt
            updatedAt
            week {
              id
              name
              start_date
              end_date
              school_year {
                id
                name
              }
            }
            element {
              id
              element_matter {
                id
                name
              }
            }
            classroom_teacher_matter {
              id
              classroom {
                id
                name
                level_sector {
                  id
                  level {
                    id
                    name
                    cycle {
                      id
                      name
                    }
                  }
                  sector {
                    id
                    name
                  }
                }
              }
              teacher {
                id
                first_name
                last_name
                user {
                  id
                }
              }
              matter {
                id
                name
              }
            }
          }
        }
      `,
      });

    var data = response.data.roomsession;
    
    data.map((item, index) => {
      if (item.element == null) {
        item.element = {
          element_matter: {
            id : 0,
            name: 'None'
          }
        }
      }
    });
    
    data.count =
      response.data.roomsession_aggregate.aggregate.count;
    return data;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        roomsession {
          id
          name
          start_time
          end_time
          day
          createdAt
          updatedAt
          week {
            id
            start_date
            end_date
            name
            school_year {
              id
              name
            }
          }
          element {
            id
            element_matter {
              id
              name
            }
          }
          classroom_teacher_matter {
            id
            classroom {
              id
              name
              level_sector {
                id
                level {
                  id
                  name
                  cycle {
                    id
                    name
                  }
                }
                sector {
                  id
                  name
                }
              }
            }
            teacher {
              id
              first_name
              last_name
              user {
                id
              }
            }
            matter {
              id
              name
            }
          }
        }
        `,
      });

    return response.data.roomsession;
  }

  static async CheckRoomStatus() {

    const date = new Date();
    const Day = date.getDay();

    var current_date_time = await this.get_current_time();
    var currentDate = moment(current_date_time).format();
    var currentTime = current_date_time.split("T")[1];

    const response = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          roomsession(
            where: {
              day: { _eq: ${Day} }
              start_time: { _lte: "${currentTime}" }
              end_time: { _gte: "${currentTime}" }
              week: {
                start_date: { _lte: "${currentDate}" }
                end_date: { _gte: "${currentDate}" }
              }
            }
          ) {
            id
            classroom_teacher_matter {
              classroom {
                room_status
              }
            }
          }
        }
        `,
      });

    return response.data.roomsession;
  }

  static async getRoom(flag) {
    const date = new Date();
    const Day = date.getDay();

    var current_date_time = await this.get_current_time();
    var currentDate = current_date_time;
    var currentTime = current_date_time.split("T")[1];
    currentTime = currentTime.slice(0, -4)+'1';

    console.log(currentTime);
    console.log(current_date_time)


    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
          query MyQuery {
            roomsession(
              where: {
                day: { _eq: ${Day} }
                start_time: { _lte: "${currentTime}" }
                end_time: { _gte: "${currentTime}" }
                week: {
                  start_date: { _lte: "${currentDate}" }
                  end_date: { _gte: "${currentDate}" }
                } 
                ${
                  !!!flag
                    ? `                status: { _eq: true }
                `
                    : ''
                }
              }
            ) {
              id
              name
              start_time
              end_time
              password
              classroom_teacher_matter {
                matter {
                  name
                }
                teacher {
                  first_name
                  last_name
                }
              }
            }
          }
        `,
      });
    let responsejson = response.data.roomsession;
    

    return responsejson;
  }

  static async setJoinDoneTeacher(roomId, id_participant) {
    const date = new Date();
    const Day = date.getDay();
    let response = await graphqlClientConfig
      .config()
      .mutate({
        mutation: gql`
        mutation MyMutation {
          update_roomsession(_set: {status: true, id_moderator: "${id_participant}"}, where: {id: {_eq: ${roomId}}}) {
            affected_rows
          }
        }
        
        `,
      });
  }

  static async SetRoomActif(name, password) {
    const date = new Date();
    const Day = date.getDay();

    var current_date_time = await this.get_current_time();
    var currentDate = moment(current_date_time).format();
    var currentTime = current_date_time.split("T")[1];

    const response = await graphqlClientConfig
      .config()
      .mutate({
        mutation: gql`
        mutation MyMutation {
          update_roomsession(where: {day: {_eq: ${Day}}, start_time: {_lte: "${currentTime}"}, end_time: {_gte: "${currentTime}"}, week: {start_date: {_lte: "${currentDate}"}, end_date: {_gte: "${currentDate}"}}}, _set: {name: "${name}" password: "${password}"}) {
            affected_rows
            returning {
              id
              start_time
              end_time
              name
              password
              classroom_teacher_matter {
                classroom {
                  name
                }
                teacher {
                  first_name
                  last_name
                }
              }
            }
          }
        }
        
        
        
        `,
      });
    let responsejson =
      response.data.update_roomsession.returning;
    return responsejson;
  }

  static async isTeacher(roomId, id_participant) {
    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {
          roomsession(where: {id: {_eq: ${roomId}}, id_moderator: {_eq: "${id_participant}"}}) {
            id
          }
        }
        
        
        `,
      });

    let responsejson = response.data.roomsession;

    return responsejson.length > 0;
  }
}