import actions from 'modules/studentStatus/list/studentStatusListActions';

const initialData = {
    rows: null,
  loading: true,
  errorMessage: null,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.ERROR_MESSAGE_CLEARED) {
    return {
      ...state,
      errorMessage: null,
    };
  }

  if (type === actions.LIST_StudentStatus_START) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.LIST_StudentStatus_SUCCESS) {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
    };
  }

  if (type === actions.LIST_StudentStatus_ERROR) {
    return {
      ...state,
      loading: true,
    };
  }

  return state;
};
