import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/exam/importer/examImporterSelectors';
import ExamService from 'modules/exam/examService';
import fields from 'modules/exam/importer/examImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'exam',
  selectors,
  ExamService.import,
  fields,
  i18n('entities.Exam.importer.fileName'),
);
