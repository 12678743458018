import SecurityService from "modules/security/securityService";
import formActions from "modules/shared/form/formActions";

const prefix = "Security_FORM";

export default formActions({
  prefix,
  createFn: SecurityService.create,
  createSuccessMessageI18nKey: "entities.Security.create.success",
  updateFn: SecurityService.update,
  updateSuccessMessageI18nKey: "entities.Security.update.success",
  findFn: SecurityService.find,
  redirectTo: "/security",
});
