import GradesManagerService from 'modules/gradesManager/gradesManagerService';
import paginationAction from 'modules/shared/pagination/paginationAction';
import selectors from 'modules/gradesManager/list/gradesManagerListSelectors';
import { i18n } from 'i18n';
import exporterFields from 'modules/gradesManager/list/gradesManagerListExporterFields';

const prefix = 'GradesManager_LIST';

export default paginationAction(
  prefix,
  GradesManagerService.list,
  selectors,
  i18n('entities.GradesManager.exporterFileName'),
  exporterFields,
);
