import CircuitService from 'modules/circuit/circuitService';
import formActions from 'modules/shared/form/formActions';

const prefix = 'Circuit_FORM';

export default formActions({
    prefix,
    createFn: CircuitService.create,
    createSuccessMessageI18nKey:
        'entities.Circuit.create.success',
    updateFn: CircuitService.update,
    updateSuccessMessageI18nKey:
        'entities.Circuit.update.success',
    findFn: CircuitService.find,
    redirectTo: '/circuit',
});