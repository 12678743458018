import actions from 'modules/absence/form/absenceFormActions';
import formReducers from 'modules/shared/form/formReducers';

const initialData = {
  rows: null,
  loading: false,
  saveLoading: false,
  errorMessage: null,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.ERROR_MESSAGE_CLEARED) {
    return {
      ...state,
      errorMessage: null,
    };
  }

  if (type === actions.LIST_ABSENCE_START) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.LIST_ABSENCE_SUCCESS) {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
    };
  }

  if (type === actions.LIST_ABSENCE_ERROR) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.UPDATE_ABSENCE_START) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.UPDATE_ABSENCE_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.UPDATE_ABSENCE_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }
  return state;
};
