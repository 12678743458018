import LevelSectorElementService from 'modules/levelSectorElement/levelSectorElementService';
import paginationAction from 'modules/shared/pagination/paginationAction';
import selectors from 'modules/levelSectorElement/list/levelSectorElementListSelectors';
import { i18n } from 'i18n';
import exporterFields from 'modules/levelSectorElement/list/levelSectorElementListExporterFields';

const prefix = 'LevelSectorElement_LIST';

export default paginationAction(
  prefix,
  LevelSectorElementService.list,
  selectors,
  i18n('entities.LevelSectorElement.exporterFileName'),
  exporterFields,
);
