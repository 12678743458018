import { connectRouter } from 'connected-react-router';
import layout from 'modules/layout/layoutReducers';
import auth from 'modules/auth/authReducers';
import matter from 'modules/matter/matterReducers';
import semester from 'modules/semester/semesterReducers';
import exam from 'modules/exam/examReducers';
import teachers from 'modules/teachers/teachersReducers';
import classroom from 'modules/classroom/classroomReducers';
import level from 'modules/level/levelReducers';
import students from 'modules/students/studentsReducers';
import classroomTeacherMatter from 'modules/classroomTeacherMatter/classroomTeacherMatterReducers';
import roomsession from 'modules/roomSession/roomSessionReducers';
import assignments from 'modules/assignments/assignmentsReducers';
import absence from 'modules/absence/absenceReducers';
import responsibles from 'modules/responsibles/responsiblesReducers';
import educDirector from 'modules/educDirector/educDirectorReducers';
import responsibleCycle from 'modules/responsibleCycle/responsibleCycleReducers';
import educDirectorCycle from 'modules/educDirectorCycle/educDirectorCycleReducers';
import cycle from 'modules/cycle/cycleReducers';
import parent from 'modules/parent/parentReducers';
import security from 'modules/security/securityReducers';
import transport from 'modules/transport/transportReducers';
import assistant from 'modules/assistant/assistantReducers';
import sector from 'modules/sector/sectorReducers';
import framer from 'modules/framer/framerReducers';
import element from 'modules/element/elementReducers';
import register from 'modules/register/registerReducers';
import levelSector from 'modules/levelSector/levelSectorReducers';
import levelSectorElement from 'modules/levelSectorElement/levelSectorElementReducers';
import levelSectorExam from 'modules/levelSectorExam/levelSectorExamReducers';
import levelSectorMatterExam from 'modules/levelSectorMatterExam/levelSectorMatterExamReducers';
import schoolYear from 'modules/schoolYear/schoolYearReducers';
import chat from 'modules/chat/chatReducers';
import courseStudents from 'modules/courseStudents/courseStudentsReducers';
import timeTable from 'modules/timeTable/timeTableReducers';
import framerMatterCycle from 'modules/framerMatterCycle/framerMatterCycleReducers';
import week from 'modules/week/weekReducers';
import circuit from 'modules/circuit/circuitReducers';
import gradesManager from 'modules/gradesManager/gradesManagerReducers';
import neighborhood from 'modules/neighborhood/neighborhoodReducers';
import roomURL from 'modules/roomURL/roomURLReducers';
import studentStatus from 'modules/studentStatus/studentStatusReducers';
import roomController from 'modules/roomController/roomControllerReducers';
import qrCodeGenerator from 'modules/qrCodeGenerator/qrCodeGeneratorReducers';

import auditLog from 'modules/auditLog/auditLogReducers';
import settings from 'modules/settings/settingsReducers';
import { combineReducers } from 'redux';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    matter,
    semester,
    exam,
    gradesManager,
    classroom,
    neighborhood,
    transport,
    studentStatus,
    level,
    cycle,
    circuit,
    chat,
    element,
    levelSectorExam,
    levelSectorMatterExam,
    timeTable,
    schoolYear,
    roomController,
    register,
    roomURL,
    security,
    assistant,
    qrCodeGenerator,
    students,
    week,
    assignments,
    absence,
    responsibles,
    responsibleCycle,
    courseStudents,
    levelSector,
    levelSectorElement,
    educDirectorCycle,
    educDirector,
    framerMatterCycle,
    parent,
    framer,
    sector,
    roomsession,
    classroomTeacherMatter,
    auditLog,
    teachers,
    settings,
  });
