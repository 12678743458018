import destroyActions from 'modules/shared/destroy/destroyActions';
import listActions from 'modules/levelSectorMatterExam/list/levelSectorMatterExamListActions';
import LevelSectorMatterExamService from 'modules/levelSectorMatterExam/levelSectorMatterExamService';

const prefix = 'LevelSectorMatterExam_DESTROY';

export default destroyActions({
  prefix,
  destroyAllFn: LevelSectorMatterExamService.destroyAll,
  destroySuccessMessageI18nKey:
    'entities.LevelSectorMatterExam.destroy.success',
  destroyAllSuccessMessageI18nKey:
    'entities.LevelSectorMatterExam.destroyAll.success',
  redirectTo: '/levelSectorMatterExam',
  listActions,
});
