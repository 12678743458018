import ExamService from 'modules/exam/examService';
import formActions from 'modules/shared/form/formActions';

const prefix = 'MATTER_FORM';

export default formActions({
  prefix,
  createFn: ExamService.create,
  createSuccessMessageI18nKey:
    'entities.Exam.create.success',
  updateFn: ExamService.update,
  updateSuccessMessageI18nKey:
    'entities.Exam.update.success',
  findFn: ExamService.find,
  redirectTo: '/Exam',
});
