import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';
import role from 'security/roles';
import authService from 'modules/auth/authService';

export default class GradesManagerService {
  static async update(id, data) {

    console.log(data)

    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        update_level_sector_matter_exam(where: {id: {_eq: ${id}}}, _set: {id_level_sector_exam: ${data.levelSectorExam}, id_element_matter: ${data.element.id}, grade_plafond: ${data.grade_plafond}, coefficient: ${data.coefficient}}) {
          affected_rows
        }
      }      
      `,
    });

    return response.data.update_level_sector_matter_exam;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        delete_level_sector_matter_exam(where: {id: {_eq: ${ids}}}) {
          affected_rows
        }
      }
      `,
    });

    return response.data.delete_level_sector_matter_exam;
  }

  static async create(data) {

    // console.log(data)

    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation upsert_grades(
        $data: [grades_insert_input!]!
      ) {
        insert_grades(
          objects: $data
          on_conflict: {
            constraint: grades_pkey
            update_columns: [grade]
          }
        ) {
          returning {
            id
          }
        }
      }
      `,
      variables: {
        data: Object.keys(data.GradesData).map( id => ( {
          id_level_sector_matter_exam: data.GradesData[id].level_sector_matter_exams,
          id_register: data.GradesData[id].register,
          id_school_year: data.GradesData[id].schoolYear,
          grade: data.GradesData[id].grades.length > 0 ? data.GradesData[id].grades[0].grade : null,
        }))
      }
    });

    return response.data.upsert_article;

  }

  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          level_sector_matter_exam(
            where: {id: {_eq: ${id}}}
          ) {
            grade_plafond
            coefficient
            level_sector_exam {
              name
              level_sector {
                sector {
                  name
                  id
                }
                level {
                  name
                  id
                  cycle {
                    id
                    name
                  }
                }
              }
              exam {
                id
                name
              }
              semester {
                id
                name
              }
              id
            }
            element_matter {
              id
              name
              matter {
                id
                name
              }
            }
            id
            updated_at
            created_at
          }
        }
      `,
    });

    return response.data.level_sector_matter_exam;
  }

  static async list(filter) {
    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`query MyQuery {
          students_aggregate(where: {registers: {school_year: {id: ${
            filter.schoolYear
              ? `{_eq: ${filter.schoolYear}}`
              : '{}'
          }, registers: {classroom: {level_sector: {level_sector_exams: {id_exam: ${
            filter.exam
              ? `{_eq: ${filter.exam}}`
              : '{}'
          }, id_semester: ${
            filter.semester
              ? `{_eq: ${filter.semester}}`
              : '{}'
          }, level_sector_matter_exams: {element_matter: {id: ${
            filter.element
              ? `{_eq: ${filter.element}}`
              : '{}'
          }, id_matter: ${
            filter.matter
              ? `{_eq: ${filter.matter}}`
              : '{}'
          }}}}}}}}, classroom: {id: ${
            filter.classroom
              ? `{_eq: ${filter.classroom}}`
              : '{}'
          }}}}) {
            aggregate {
              count
            }
          }
          students(where: {registers: {school_year: {id: ${
            filter.schoolYear
              ? `{_eq: ${filter.schoolYear}}`
              : '{}'
          }, registers: {classroom: {level_sector: {level_sector_exams: {id_exam: ${
            filter.exam
              ? `{_eq: ${filter.exam}}`
              : '{}'
          }, id_semester: ${
            filter.semester
              ? `{_eq: ${filter.semester}}`
              : '{}'
          }, level_sector_matter_exams: {element_matter: {id: ${
            filter.element
              ? `{_eq: ${filter.element}}`
              : '{}'
          }, id_matter: ${
            filter.matter
              ? `{_eq: ${filter.matter}}`
              : '{}'
          }}}}}}}}, classroom: {id: ${
            filter.classroom
              ? `{_eq: ${filter.classroom}}`
              : '{}'
          }}}}) {
            id
            first_name
            last_name
            registers {
              id
              grades {
                id
                grade
              }
              school_year {
                id
                name
              }
              classroom {
                id
                name
                level_sector {
                  id
                  level_sector_exams {
                    exam {
                      id
                      name
                    }
                    semester {
                      id
                      name
                    }
                    level_sector_matter_exams(where: {element_matter: {id: ${
                      filter.element
                        ? `{_eq: ${filter.element}}`
                        : '{}'
                    }, matter: {id: ${
                      filter.matter
                        ? `{_eq: ${filter.matter}}`
                        : '{}'
                    }}}}) {
                      id
                      grade_plafond
                      element_matter {
                        id
                        name
                        matter {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        `,
      });

    let data = response.data.students;
    // let newData = data.map((item) => ({
    //   ...item,
    //   id: item.id,
    //   grade_plafond: item.grade_plafond,
    //   coefficient: item.coefficient,
    //   grade: item.grades ? item.grades.grade : null,
    // }));

    // console.log(response.data);

    data.count = response.data.students_aggregate.aggregate.count;

    return data;
  }

}











// query MyQuery {
//   students(where: {registers: {school_year: {id: {_eq: 10}}, grades: {level_sector_matter_exam: {level_sector_exam: {semester: {id: {_eq: 10}, level_sector_exams: {exam: {id: {_eq: 10}}}}, level_sector: {classrooms: {id: {_eq: 10}}}}, element_matter: {matter: {id: {_eq: 10}}, id: {_eq: 10}}}}}}) {
//     id
//     first_name
//     last_name
//     registers {
//       id
//       school_year {
//         id
//         name
//       }
//       grades {
//         school_year {
//           id
//           name
//         }
//         id
//         grade
//         level_sector_matter_exam {
//           element_matter {
//             id
//             name
//             matter {
//               id
//               name
//             }
//           }
//           level_sector_exam {
//             id
//             exam {
//               id
//               name
//             }
//             semester {
//               id
//               name
//             }
//             level_sector {
//               id
//               classrooms {
//                 id
//                 name
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
