import model from 'modules/circuit/circuitModel';

const { fields } = model;

export default [
  fields.id,
  fields.assistant,
  fields.name,
  fields.transport,
];
