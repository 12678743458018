import list from 'modules/semester/list/semesterListReducers';
import form from 'modules/semester/form/semesterFormReducers';
import view from 'modules/semester/view/semesterViewReducers';
import destroy from 'modules/semester/destroy/semesterDestroyReducers';
import importerReducer from 'modules/semester/importer/semesterImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
