import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/transport/importer/transportImporterSelectors';
import TransportService from 'modules/transport/transportService';
import fields from 'modules/transport/importer/transportImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'transport',
  selectors,
  TransportService.import,
  fields,
  i18n('entities.Transport.importer.fileName'),
);
