import viewActions from "modules/shared/view/viewActions";
import AssistantService from "modules/assistant/assistantService";

const prefix = "Assistant_VIEW";

export default viewActions({
  prefix,
  findFn: AssistantService.find,
  redirectToOnError: "/assistant",
});
