import list from 'modules/exam/list/examListReducers';
import form from 'modules/exam/form/examFormReducers';
import view from 'modules/exam/view/examViewReducers';
import destroy from 'modules/exam/destroy/examDestroyReducers';
import importerReducer from 'modules/exam/importer/examImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
