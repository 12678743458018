import model from 'modules/levelSectorMatterExam/levelSectorMatterExamModel';

const { fields } = model;

export default [
  fields.id,
  fields.id_level,
  fields.level,
  fields.LevelSectorExam,
  fields.id_sector,
  fields.sector,
  fields.cycle,
  fields.grade_plafond,
  fields.coefficient,
];
