import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';
import moment from 'moment';
import config from 'config';
import axios from 'axios';
import service from 'modules/roomSession/roomSessionService';

export default class QrCodeGeneratorService {
  static async generate_qr(data, email) {
    console.log('email ***************** :', email);
    var response = await axios.post(`${config.qrCodeApi}`, {
      email: email,
      data: data,
    });

    return response;
  }

  static async list() {
    const date = new Date();
    const Day = date.getDay();

    var current_date_time = await service.get_current_time();
    var currentDate = current_date_time;
    var currentTime = current_date_time.split("T")[1];
    currentTime = currentTime.slice(0, -4)+'1';

    console.log(currentTime);
    console.log(current_date_time)

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {
          roomsession(where: {day: {_eq: ${Day}}, start_time: {_lte: "${currentTime}"}, end_time: {_gte: "${currentTime}"}, week: {start_date: {_lte: "${currentDate}"}, end_date: {_gte: "${currentDate}"}}}) {
             id
            classroom_teacher_matter {
              classroom {
                registers {
                  student {
                    id
                    first_name
                    last_name
                    manual_qrCodeGenerators(where: {roomsession: {{day: {_eq: ${Day}}, start_time: {_lte: "${currentTime}"}, end_time: {_gte: "${currentTime}"}, week: {start_date: {_lte: "${currentDate}"}, end_date: {_gte: "${currentDate}"}}}}) {
                      id_roomsessione
                    }
                  }
                }
              }
            }
          }
        }        
        `,
      });
    return response.data.roomsession;
  }

  static async listAdmin(
    filter = {},
    orderBy,
    limit,
    offset,
  ) {
    if (
      filter &&
      filter.createdAt &&
      filter.createdAtRange
    ) {
      delete filter.createdAt;
    }
    if (filter == undefined) filter = {};

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {

          students_aggregate(where: {manual_qrCodeGenerators: {roomsession: {day: {_eq: ${
            filter.day || null
          }}, start_time: {_lte: "${moment
          .tz(filter.start_time, 'Africa/Casablanca')
          .format(
            'HH:mm:ss z',
          )}"}, end_time: {_gte: "${moment
          .tz(filter.end_time, 'Africa/Casablanca')
          .format('HH:mm:ss z')}"}, id_week: {_eq: ${
          filter.week || null
        }}, classroom_teacher_matter: {id_classroom: {_eq: ${
          filter.classroom || null
        }}}}}}){
            aggregate{
              count
            }
          }
          students(where: {manual_qrCodeGenerators: {roomsession: {day: {_eq: ${
            filter.day || null
          }}, start_time: {_lte: "${moment
          .tz(filter.start_time, 'Africa/Casablanca')
          .format(
            'HH:mm:ss z',
          )}"}, end_time: {_gte: "${moment
          .tz(filter.end_time, 'Africa/Casablanca')
          .format('HH:mm:ss z')}"}, id_week: {_eq: ${
          filter.week || null
        }}, classroom_teacher_matter: {id_classroom: {_eq: ${
          filter.classroom || null
        }}}}}}) {
            id
            first_name
            last_name
            code_massar
            registers(where: {student: { manual_qrCodeGenerators: {roomsession: {day: {_eq: ${
              filter.day || null
            }}, start_time: {_lte: "${moment
          .tz(filter.start_time, 'Africa/Casablanca')
          .format(
            'HH:mm:ss z',
          )}"}, end_time: {_gte: "${moment
          .tz(filter.end_time, 'Africa/Casablanca')
          .format('HH:mm:ss z')}"}, id_week: {_eq: ${
          filter.week || null
        }}, classroom_teacher_matter: {id_classroom: {_eq: ${
          filter.classroom || null
        }}}}} }}) {
              classroom {
                id
                name
              }
            }
            manual_qrCodeGenerators(where: {roomsession: {day: {_eq: ${
              filter.day || null
            }}, start_time: {_lte: "${moment
          .tz(filter.start_time, 'Africa/Casablanca')
          .format(
            'HH:mm:ss z',
          )}"}, end_time: {_gte: "${moment
          .tz(filter.end_time, 'Africa/Casablanca')
          .format('HH:mm:ss z')}"}, id_week: {_eq: ${
          filter.day || null
        }}, classroom_teacher_matter: {id_classroom: {_eq: ${
          filter.classroom || null
        }}}}}) {
              id
            }
          }
        }
        `,
      });
    var data = response.data.students;
    data.count =
      response.data.students_aggregate.aggregate.count;
    return data;
  }
}
