import Service from './chatService';
const prefix = 'CHAT';

const actions = {
  LISTE_MESSAGE_STARTED: `${prefix}_FETCH_STARTED`,
  LISTE_MESSAGE_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  LISTE_MESSAGE_ERROR: `${prefix}_FETCH_ERROR`,

  doClearErrorMessage() {
    return {
      type: actions.ERROR_MESSAGE_CLEARED,
    };
  },
  FindCreateConversationUserId: (user_id) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: actions.LISTE_MESSAGE_STARTED,
      });
      let chats = await Service.findChat(user_id);
      var chat_id = null;
      if (chats.length > 0) {
        chat_id = chats[0].id;
      } else {
        chat_id = await Service.CreateChate(user_id);
      }

      var messages = await Service.listeMessages(chat_id);
      dispatch({
        type: actions.LISTE_MESSAGE_SUCCESS,
        payload: { messages, chat_id },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: actions.LISTE_MESSAGE_ERROR,
      });
    }
  },

  FindMessagesChat: (chat_id) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: actions.LISTE_MESSAGE_STARTED,
      });

      var messages = await Service.listeMessages(chat_id);
      dispatch({
        type: actions.LISTE_MESSAGE_SUCCESS,
        payload: { messages, chat_id },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: actions.LISTE_MESSAGE_ERROR,
      });
    }
  },
};

export default actions;
