import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/studentStatus/importer/studentStatusImporterSelectors';
import StudentStatusService from 'modules/studentStatus/studentStatusService';
import fields from 'modules/studentStatus/importer/studentStatusImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'studentStatus',
  selectors,
  StudentStatusService.import,
  fields,
  i18n('entities.StudentStatus.importer.fileName'),
);
