import destroyActions from 'modules/shared/destroy/destroyActions';
import listActions from 'modules/levelSectorElement/list/levelSectorElementListActions';
import LevelSectorElementService from 'modules/levelSectorElement/levelSectorElementService';

const prefix = 'LevelSectorElement_DESTROY';

export default destroyActions({
  prefix,
  destroyAllFn: LevelSectorElementService.destroyAll,
  destroySuccessMessageI18nKey:
    'entities.LevelSectorElement.destroy.success',
  destroyAllSuccessMessageI18nKey:
    'entities.LevelSectorElement.destroyAll.success',
  redirectTo: '/levelSectorElement',
  listActions,
});
