import service from 'modules/roomController/roomControllerService';
import Errors from 'modules/shared/error/errors';
import Message from 'view/shared/message';
import { i18n } from 'i18n';
import { getHistory } from "modules/store";
import { getStore } from 'modules/store';
import Permissions from 'security/permissions';
import PermissionChecker from 'modules/auth/permissionChecker';

const permissions = Permissions.values;

const prefix = 'RoomController';

const actions = {
  ERROR_MESSAGE_CLEARED: `${prefix}_ERROR_MESSAGE_CLEARED`,

  RoomController_START: `${prefix}_RoomController_START`,
  RoomController_SUCCESS: `${prefix}_RoomController_SUCCESS`,
  RoomController_ERROR: `${prefix}_RoomController_ERROR`,
  RoomController_FAILED: `${prefix}RoomController_FAILED`,

  doClearErrorMessage() {
    return {
      type: actions.ERROR_MESSAGE_CLEARED,
    };
  },

  doGetRoom: () => async (dispatch) => {
    try {
      dispatch({ type: actions.RoomController_START });

      var CurrentUser = getStore().getState().auth.currentUser;

      const permissions = Permissions.values;
      const permissionChecker = new PermissionChecker(
        CurrentUser,
      );

      const response = await service.CheckRoomStatus();

      if ( response.length > 0 ) {

        console.log(response);

        if ( !response[0].classroom_teacher_matter.classroom.room_status ) {
          if (permissionChecker.match(permissions.HomePage)) {
            
            if (response[0].classroom_teacher_matter.teacher.teacher_room_urls.length == 0) {
              Message.success(i18n('entities.RoomController.studentURL.NoURL'));
              getHistory().push('/');
              return null;
            }

            var str = '//'+response[0].classroom_teacher_matter.teacher.teacher_room_urls[0].url;
            
            if (window.innerWidth <= 768) {
              window.location = str;
            } else {
              console.log(str)
              window.open(str, "_blank")
              getHistory().push('/');
            }

            Message.success(
              i18n(
                'entities.RoomController.studentURL.success',
              ),
            );
          } else {
              if (permissionChecker.match(permissions.HomeProf)) {
                getHistory().push('/RoomURL');
              }
          }
        } else {
          if (permissionChecker.match(permissions.HomePage)) {
            getHistory().push('/liveStudent');
          } else {
              if (permissionChecker.match(permissions.HomeProf)) {
                getHistory().push('/liveTeacher');
              }
          }
        }

        // dispatch({
        //   type: actions.RoomController_SUCCESS,
        //   payload: { 
        //     rows: response,
        //     count: response.count
        //       ? response.count
        //       : response.length, 
        //   },
        // });

      } else {
        dispatch({ type: actions.RoomController_FAILED });
        getHistory().push('/');
        Message.error(
          i18n(
            'entities.RoomController.No.Roomsession',
          ),
        );
      }

    } catch (error) {
      dispatch({ type: actions.RoomController_ERROR });
      // getHistory().push('/');
    }
  },

};

export default actions;
