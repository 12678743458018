import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/gradesManager/importer/gradesManagerImporterSelectors';
import GradesManagerService from 'modules/gradesManager/gradesManagerService';
import fields from 'modules/gradesManager/importer/gradesManagerImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'level_sector_matter_exam',
  selectors,
  GradesManagerService.import,
  fields,
  i18n('entities.GradesManager.importer.fileName'),
);
