import list from 'modules/roomURL/list/roomURLListReducers';
import form from 'modules/roomURL/form/roomURLFormReducers';
import view from 'modules/roomURL/view/roomURLViewReducers';
import destroy from 'modules/roomURL/destroy/roomURLDestroyReducers';
import importerReducer from 'modules/roomURL/importer/roomURLImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
