import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';
import role from 'security/roles';
import authService from 'modules/auth/authService';

export default class NeighborhoodService {
  static async update(id, data) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        update_neighborhood(where: {id: {_eq: ${id}}}, _set: {id_circuit: ${data.circuit}, name: "${data.name}"}) {
          affected_rows
        }
      }
      `,
    });

    return response.data.update_neighborhood;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        delete_neighborhood(where: {id: {_eq: ${ids}}}) {
          affected_rows
        }
      }
      `,
    });

    return response.data.delete_neighborhood;
  }

  static async create(data) {

    const response = await graphqlClient.config().mutate({
      mutation: gql`
        mutation MyMutation {
          insert_neighborhood(objects: {id_circuit: ${data.circuit}, name: "${data.name}"}) {
            affected_rows
          }
        }
      `,
    });

    return response.data.insert_neighborhood;
  }

  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        neighborhood(
          where: {id: {_eq: ${id}}}
        ) {
          id
          name
          circuit {
            id
            name
          }
          updated_at
          created_at
        }
      }
      `,
    });

    return response.data.neighborhood;
  }

  static async list(filter, orderBy, limit, offset) {
    if (
      filter &&
      filter.created_at &&
      filter.createdAtRange
    ) {
      delete filter.created_at;
    }
    if (filter == undefined) filter = {};

    console.log(`
    query MyQuery {

      neighborhood_aggregate(where: {id: ${
        filter.id ? ` {_eq: ${filter.id}}` : '{}'
      },
              id_circuit:
              ${
                filter.circuit
                  ? `{_eq: ${filter.circuit.id}}`
                  : '{}'
              },
              name:
              ${
                filter.name
                  ? `{_eq: "${filter.name}"}`
                  : '{}'
              }
            
            ${
              filter.created_at
                ? `,created_at:{_eq:"${filter.created_at.format()}"}`
                : ''
            },
          
              updated_at:
          ${
            filter.updated_at
              ? `{_eq:"${filter.updated_at.format()}"}`
              : '{}'
          }
          
          ${
            filter.createdAtRange
              ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
              : ''
          }
            }) {
        aggregate {
          count
        }
      }

      neighborhood( ${
        limit != 0 ? `limit:${limit},` : ''
      } ${
      offset != 0 ? `offset:${offset} ,` : ''
    } order_by:{${
      orderBy ? orderBy : ''
    }}, where: {id: ${
      filter.id ? ` {_eq: ${filter.id}}` : '{}'
    },
          id_circuit:
          ${
            filter.circuit
              ? `{_eq: ${filter.circuit.id}}`
              : '{}'
          },
          name:
          ${
            filter.name
              ? `{_eq: "${filter.name}"}`
              : '{}'
          }
          
          ${
            filter.created_at
              ? `,created_at:{_eq:"${filter.created_at.format()}"}`
              : ''
          },
        
            updated_at:
        ${
          filter.updated_at
            ? `{_eq:"${filter.updated_at.format()}"}`
            : '{}'
        }
        
        ${
          filter.createdAtRange
            ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
            : ''
        }
          }) {
            id
            name
            circuit {
              id
              name
            }
            updated_at
            created_at
          }
        }
    `)

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {

          neighborhood_aggregate(where: {id: ${
            filter.id ? ` {_eq: ${filter.id}}` : '{}'
          },
                  id_circuit:
                  ${
                    filter.circuit
                      ? `{_eq: ${filter.circuit.id}}`
                      : '{}'
                  },
                  name:
                  ${
                    filter.name
                      ? `{_eq: "${filter.name}"}`
                      : '{}'
                  }
                
                ${
                  filter.created_at
                    ? `,created_at:{_eq:"${filter.created_at.format()}"}`
                    : ''
                },
              
                  updated_at:
              ${
                filter.updated_at
                  ? `{_eq:"${filter.updated_at.format()}"}`
                  : '{}'
              }
              
              ${
                filter.createdAtRange
                  ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
                  : ''
              }
                }) {
            aggregate {
              count
            }
          }
    
          neighborhood( ${
            limit != 0 ? `limit:${limit},` : ''
          } ${
          offset != 0 ? `offset:${offset} ,` : ''
        } order_by:{${
          orderBy ? orderBy : ''
        }}, where: {id: ${
          filter.id ? ` {_eq: ${filter.id}}` : '{}'
        },
              id_circuit:
              ${
                filter.circuit
                  ? `{_eq: ${filter.circuit.id}}`
                  : '{}'
              },
              name:
              ${
                filter.name
                  ? `{_eq: "${filter.name}"}`
                  : '{}'
              }
              
              ${
                filter.created_at
                  ? `,created_at:{_eq:"${filter.created_at.format()}"}`
                  : ''
              },
            
                updated_at:
            ${
              filter.updated_at
                ? `{_eq:"${filter.updated_at.format()}"}`
                : '{}'
            }
            
            ${
              filter.createdAtRange
                ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
                : ''
            }
              }) {
                id
                name
                circuit {
                  id
                  name
                }
                updated_at
                created_at
              }
            }
        `,
      });

    let data = response.data.neighborhood;

    return data;
  }

  static async listSelect() {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        neighborhood {
          id
          name
          circuit {
            id
            name
          }
        }
      }
      `,
    });
    return response.data.neighborhood;
  }

}
