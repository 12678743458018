import service from 'modules/timeTable/timeTableService';
import Errors from 'modules/shared/error/errors';
import Message from 'view/shared/message';
import { i18n } from 'i18n';
import { getHistory } from 'modules/store';
import { getStore } from 'modules/store';
import Permissions from 'security/permissions';
import PermissionChecker from 'modules/auth/permissionChecker';
import { getLanguageCode } from 'i18n';
import { v4 as uuidv4 } from 'uuid';
import StudentService from 'modules/students/studentsService';
import Roles from 'security/roles';

const prefix = 'ROOM';

const actions = {
  ERROR_MESSAGE_CLEARED: `${prefix}_ERROR_MESSAGE_CLEARED`,

  LIST_TIMETABLE_START: `${prefix}LIST_TIMETABLE_START`,
  LIST_TIMETABLE_SUCCESS: `${prefix}LIST_TIMETABLE_SUCCESS`,
  LIST_TIMETABLE_ERROR: `${prefix}LIST_TIMETABLE_ERROR`,

  doClearErrorMessage() {
    return {
      type: actions.ERROR_MESSAGE_CLEARED,
    };
  },

  doList: (fields = {}) => async (dispatch) => {
    try {
      dispatch({ type: actions.LIST_TIMETABLE_START });

      var CurrentUser = getStore().getState().auth
        .currentUser;

      const permissions = Permissions.values;
      const permissionChecker = new PermissionChecker(
        CurrentUser,
      );

      var data = await service.getTimeTable(fields);

      var type;
      if (
        permissionChecker.match(
          permissions.timeTableStudent,
        )
      ) {
        type = 'student';
      } else {
        if (permissionChecker.match(
          permissions.Managing_users,
        )) {
          type = 'managers';
        } else {
          type = 'teacher';
        }
      }
      var Result = { type: type, roomsession: data };

      dispatch({
        type: actions.LIST_TIMETABLE_SUCCESS,
        payload: { timeTable: Result },
      });
    } catch (error) {
      dispatch({ type: actions.LIST_TIMETABLE_ERROR });
      // getHistory().push('/');
    }
  },
};

export default actions;
