import list from 'modules/levelSectorElement/list/levelSectorElementListReducers';
import form from 'modules/levelSectorElement/form/levelSectorElementFormReducers';
import view from 'modules/levelSectorElement/view/levelSectorElementViewReducers';
import destroy from 'modules/levelSectorElement/destroy/levelSectorElementDestroyReducers';
import importerReducer from 'modules/levelSectorElement/importer/levelSectorElementImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
    list,
    form,
    view,
    destroy,
    importer: importerReducer,
});