import { i18n } from 'i18n';
import IdField from 'modules/shared/fields/idField';
import DateTimeField from 'modules/shared/fields/dateTimeField';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField';
import StringField from 'modules/shared/fields/stringField';
import EnumeratorField from 'modules/shared/fields/enumeratorField';
import DecimalRangeField from 'modules/shared/fields/decimalRangeField';
import DecimalField from 'modules/shared/fields/decimalField';
import RelationToOneField from 'modules/shared/fields/relationToOneField';
import FilesField from 'modules/shared/fields/filesField';
import ImagesField from 'modules/shared/fields/imagesField';
import JsonField from 'modules/shared/fields/jsonField';
import DateField from 'modules/shared/fields/dateField';
import BooleanField from 'modules/shared/fields/booleanField';

function label(name) {
  return i18n(`entities.Assignments.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(
    `entities.Assignments.enumerators.${name}.${value}`,
  );
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {
    required: true,
  }),

  hidden: new EnumeratorField(
    'hidden',
    label('hidden'),
    [
      {
        id: false,
        label: enumeratorLabel('hidden', 'shown'),
      },
      {
        id: true,
        label: enumeratorLabel('hidden', 'hidden'),
      },
    ],
    {
      required: true,
    },
  ),

  coursHtml: new StringField(
    'coursHTML',
    label('coursHTML'),
    // {
    //   required: true,
    // },
  ),
  youtubeLink: new StringField(
    'youtubeLink',
    label('youtubeLink'),
    // {
    //   required: true,
    // },
  ),
  file: new FilesField(
    'payload',
    label('file'),
    'assignement/',
    {
      size: 3000000,
    },
  ),
  matter: new JsonField('matter', label('matter')),
  teacher: new JsonField('teacher', label('teacher')),
  student: new JsonField('student', label('student')),
  classroom: new JsonField('classroom', label('classroom')),
  listClassrooms: new JsonField('listClassrooms', label('listClassrooms'),
  {
    required: true,
  }),
  cycle: new JsonField('cycle', label('cycle')),
  types_assignment: new JsonField(
    'types_assignment',
    label('types_assignment'),
    {
      required: true,
    },
  ),
  type_course: new EnumeratorField(
    'type_course',
    label('type_course'),
    [
      {
        id: 0,
        label: enumeratorLabel('type_course', 'Standard'),
      },
      {
        id: 1,
        label: enumeratorLabel('type_course', 'Editable'),
      },
      {
        id: 2,
        label: enumeratorLabel('type_course', 'External_Link'),
      },
    ],
    {
      required: true,
    },
  ),
  start_date: new DateField(
    'start_date',
    label('start_date')
  ),
  end_date: new DateField('end_date', label('end_date')),
  element: new JsonField('element', label('element')),
  createdAt: new JsonField('createdAt', label('createdAt')),
  updatedAt: new JsonField('updatedAt', label('updatedAt')),
  createdAtRange: new JsonField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export default {
  fields,
};
