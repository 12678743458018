import StudentStatusService from "modules/studentStatus/studentStatusService";
import formActions from "modules/shared/form/formActions";

const prefix = "StudentStatus_FORM";

export default formActions({
  prefix,
  createFn: StudentStatusService.create,
  createSuccessMessageI18nKey: "entities.StudentStatus.create.success",
  updateFn: StudentStatusService.update,
  updateSuccessMessageI18nKey: "entities.StudentStatus.update.success",
  findFn: StudentStatusService.find,
  redirectTo: "/studentStatus",
});
