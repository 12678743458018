import SemesterService from 'modules/semester/semesterService';
import paginationAction from 'modules/shared/pagination/paginationAction';
import selectors from 'modules/semester/list/semesterListSelectors';
import { i18n } from 'i18n';
import exporterFields from 'modules/semester/list/semesterListExporterFields';

const prefix = 'MATTER_LIST';

export default paginationAction(
  prefix,
  SemesterService.list,
  selectors,
  i18n('entities.Semester.exporterFileName'),
  exporterFields,
);
