import importerActions from "modules/shared/importer/importerActions";
import selectors from "modules/assistant/importer/assistantImporterSelectors";
import AssistantService from "modules/assistant/assistantService";
import fields from "modules/assistant/importer/assistantImporterFields";
import { i18n } from "i18n";

export default importerActions(
  "assistant",
  selectors,
  AssistantService.import,
  fields,
  i18n("entities.Assistant.importer.fileName")
);
