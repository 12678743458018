import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';
import moment from 'moment';

export default class WeekService {
  static async update(id, data) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        update_week(where: {id: {_eq: ${id}}}, _set: {id_school_year:  ${
        data.schoolYear.id
      },start_date: "${moment(data.start_date).format(
        'YYYY-MM-DD',
      )}", end_date: "${moment(data.end_date).format(
        'YYYY-MM-DD',
      )}"}) {
          affected_rows
        }
      }
      `,
    });

    return response.data.update_week;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        delete_week(where: {id: {_eq: ${ids}}}) {
          affected_rows
        }
      }
      `,
    });

    return response.data.delete_week;
  }

  static async create(data) {
    console.log(`
    mutation MyMutation {
      insert_week(objects: {id_school_year:  ${
        data.schoolYear
      }, start_date: "${moment(data.start_date).format(
      'YYYY-MM-DD',
    )}", end_date: "${moment(data.end_date).format(
      'YYYY-MM-DD',
    )}"}) {
        affected_rows
      }
    }
    `)
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        insert_week(objects: {id_school_year:  ${
          data.schoolYear
        }, start_date: "${moment(data.start_date).format(
        'YYYY-MM-DD',
      )}", end_date: "${moment(data.end_date).format(
        'YYYY-MM-DD',
      )}"}) {
          affected_rows
        }
      }
      `,
    });

    return response.data.insert_week;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation booking_IMPORT(
          $data: bookingInput!
          $importHash: String!
        ) {
          bookingImport(
            data: $data
            importHash: $importHash
          )
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.WeekImport;
  }

  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        week(where: {id: {_eq: ${id}}}) {
          id
          created_at
          updated_at
          school_year {
            id
            name
          }
          name
          start_date
          end_date
        }
      }
      `,
    });

    return response.data.week;
  }

  static async list(filter, orderBy, limit, offset) {
    if (
      filter &&
      filter.createdAt &&
      filter.createdAtRange
    ) {
      delete filter.createdAt;
    }
    if (filter == undefined) filter = {};

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {

          week_aggregate(where: {id: {_eq: ${
            filter.id || null
          }},
          start_date: ${
            filter.start_date
              ? `{_eq:"${filter.start_date}"}`
              : '{}'
          },
          end_date: ${
            filter.end_date
              ? `{_eq:"${filter.end_date}"}`
              : '{}'
          },
          id_school_year: {_eq:  ${filter.schoolYear || null}},
            
            ${
              filter.createdAt
                ? `,created_at:{_eq:"${filter.createdAt.format()}"}`
                : ''
            },          
            updated_at:
          ${
            filter.updatedAt
              ? `{_eq:"${filter.updatedAt.format()}"}`
              : '{}'
          }          
          ${
            filter.createdAtRange
              ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
              : ''
          }
            }) {
            aggregate {
              count
            }
          }

          week(  ${limit != 0 ? `limit:${limit},` : ''} ${
          offset != 0 ? `offset:${offset} ,` : ''
        } order_by:{${
          orderBy ? orderBy : ''
        }}, where: {id: {_eq: ${filter.id || null}},
        start_date: ${
          filter.start_date
            ? `{_eq:"${filter.start_date}"}`
            : '{}'
        },
        end_date: ${
          filter.end_date
            ? `{_eq:"${filter.end_date}"}`
            : '{}'
        },
        id_school_year: {_eq:  ${filter.schoolYear || null}},
            ${
              filter.createdAt
                ? `,created_at:{_eq:"${filter.createdAt.format()}"}`
                : ''
            },
          
            updated_at:
          ${
            filter.updatedAt
              ? `{_eq:"${filter.updatedAt.format()}"}`
              : '{}'
          }
          
          ${
            filter.createdAtRange
              ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
              : ''
          }
          }){
              id
              created_at
              updated_at
              school_year {
                id
                name
              }
              name
              start_date
              end_date
            }
          }
        `,
      });

    console.log(response.data);

    var data = response.data.week;

    var new_Data = data.map((e) => ({
      ...e,
    }));
    new_Data.count =
      response.data.week_aggregate.aggregate.count;
    return new_Data;
  }

  static async listSelect(id) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        week(where: {id_school_year: {_eq: ${
          id || null
        }}},order_by: {start_date: asc}) {
          name
          id
          start_date
          end_date
        }
      }
      
      `,
    });
    return response.data.week;
  }
}
