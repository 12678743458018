import RoomURLService from 'modules/roomURL/roomURLService';
import formActions from 'modules/shared/form/formActions';

const prefix = 'MATTER_FORM';

export default formActions({
  prefix,
  createFn: RoomURLService.create,
  createSuccessMessageI18nKey:
    'entities.RoomURL.create.success',
  updateFn: RoomURLService.update,
  updateSuccessMessageI18nKey:
    'entities.RoomURL.update.success',
  findFn: RoomURLService.find,
  redirectTo: '/roomController',
});
