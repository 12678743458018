import model from 'modules/roomURL/roomURLModel';

const { fields } = model;

export default [
    fields.id,
    fields.url,
    fields.createdAt,
    fields.updatedAt,
    fields.createdAtRange,
];