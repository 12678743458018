import list from 'modules/transport/list/transportListReducers';
import form from 'modules/transport/form/transportFormReducers';
import view from 'modules/transport/view/transportViewReducers';
import destroy from 'modules/transport/destroy/transportDestroyReducers';
import importerReducer from 'modules/transport/importer/transportImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
    list,
    form,
    view,
    destroy,
    importer: importerReducer,
});