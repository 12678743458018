import LevelSectorElementService from 'modules/levelSectorElement/levelSectorElementService';
import formActions from 'modules/shared/form/formActions';

const prefix = 'LevelSectorElement_FORM';

export default formActions({
    prefix,
    createFn: LevelSectorElementService.create,
    createSuccessMessageI18nKey:
        'entities.LevelSectorElement.create.success',
    updateFn: LevelSectorElementService.update,
    updateSuccessMessageI18nKey:
        'entities.LevelSectorElement.update.success',
    findFn: LevelSectorElementService.find,
    redirectTo: '/levelSectorElement',
});