import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';

export default class RegisterService {
  static async update(id, data) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        update_register(where: {id: {_eq: ${id}}}, _set: {id_classroom: ${data.classroom}, id_student:  ${data.student.id}, id_school_year:  ${data.schoolYear.id}, transport: ${data.transport}, online: ${data.online}, canteen: ${data.canteen}}) {
          affected_rows
        }
      }
      `,
    });

    return response.data.update_register;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        delete_register(where: {id: {_eq: ${ids}}}) {
          affected_rows
        }
      }
      `,
    });

    return response.data.delete_register;
  }

  static async create(data) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        insert_register(objects: {id_classroom: ${data.classroom}, id_student:  ${data.student.id}, id_school_year:  ${data.schoolYear}, transport: ${data.transport}, online: ${data.online}, canteen: ${data.canteen}}) {
          affected_rows
        }
      }
      `,
    });

    return response.data.insert_register;
  }

  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        register(where: {id: {_eq: ${id}}}) {
          id
          created_at
          updated_at
          transport
          online
          canteen
          classroom {
            id
            name
            level_sector {
              sector {
                id
                name
              }
              level {
                id
                name
                cycle {
                  id
                  name
                }
              }
            }
          }
          school_year {
            id
            name
          }
          student {
            id
            first_name
            last_name
          }
        }
      }
      `,
    });

    return response.data.register;
  }

  static async list(filter = {}, orderBy, limit, offset) {
    if (
      filter &&
      filter.createdAt &&
      filter.createdAtRange
    ) {
      delete filter.createdAt;
    }

    console.log(filter);

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {


          register_view_aggregate(where: {
            id: {_eq: ${filter.id || null}}
            id_classroom: {_eq: ${filter.classroom || null}}
            id_student: {_eq: ${
              filter.student ? filter.student.id : null
            }}
            created_at: {_eq:  ${
              filter.created_at
                ? filter.created_at.format()
                : null
            }}
            updated_at: {_eq:  ${
              filter.updated_at
                ? filter.updated_at.format()
                : null
            }}
            id_school_year: {_eq: ${
              filter.schoolYear || null
            }}
            id_level: {_eq: ${
              filter.level || null
            }}
            id_sector: {_eq: ${
              filter.sector || null
            }}
            id_cycle: {_eq: ${
              filter.cycle || null
            }}
          }) {
            aggregate {
              count
            }
          }

          register_view( ${
            limit != 0 ? `limit:${limit},` : ''
          } ${
          offset != 0 ? `offset:${offset} ,` : ''
        } order_by:{${orderBy ? orderBy : ''}}, where: {
          id: {_eq: ${filter.id || null}}
          id_classroom: {_eq: ${filter.classroom || null}}
          id_student: {_eq: ${
            filter.student ? filter.student.id : null
          }}
          created_at: {_eq:  ${
            filter.created_at
              ? filter.created_at.format()
              : null
          }}
          updated_at: {_eq:  ${
            filter.updated_at
              ? filter.updated_at.format()
              : null
          }}
          id_school_year: {_eq: ${
            filter.schoolYear || null
          }}
          id_level: {_eq: ${
            filter.level || null
          }}
          id_sector: {_eq: ${
            filter.sector || null
          }}
          id_cycle: {_eq: ${
            filter.cycle || null
          }}
          }){
            id
            updated_at
            created_at
            transport
            online
            canteen
            id_classroom
            name_classroom
            id_sector
            name_sector
            id_level
            name_level
            id_cycle
            name_cycle
            id_school_year
            name_school_year
            id_student
            first_name_student
            last_name_student
            code_massar_student
            matr_student
            email_student
            phone_student
            first_name_father
            last_name_father
            email_father
            phone_father
          }
        }
        `,
      });

    var data = response.data.register_view;

    var new_Data = data.map((e) => ({
      student: {
        id: e.id_student,
        first_name: e.first_name_student,
        last_name: e.last_name_student
      },
      school_year: {
        id: e.id_school_year,
        name: e.name_school_year
      },
      cycle: {
        id: e.id_cycle,
        name: e.name_cycle
      },
      level: {
        id: e.id_level,
        name: e.name_level
      },
      sector: {
        id: e.id_sector,
        name: e.name_sector
      },
      classroom: {
        id: e.id_classroom,
        name: e.name_classroom
      },
      studentName:
        e.first_name_student + ' ' + e.last_name_student,
      studentEmail: e.email_student,
      studentPhone: e.phone_student,
      code_massar: e.code_massar_student,
      matr: e.matr_student,

      parentName: 
        e.first_name_father + ' ' + e.last_name_father,

      parentPhone: e.phone_father
        ? e.phone_father
        : '',

      parentEmail: e.email_father
        ? e.email_father
        : null,
      className: e.name_classroom,
      ...e,
    }));
    new_Data.count =
      response.data.register_view_aggregate.aggregate.count;
    return new_Data;
  }
}
