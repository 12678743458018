import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import moment from 'moment';
import momentTz from 'moment-timezone';
import service from 'modules/roomSession/roomSessionService';

export default class RoomControllerService {
  static async CheckRoomStatus() {

    const date = new Date();
    const Day = date.getDay();

    var current_date_time = await service.get_current_time();
    var currentDate = current_date_time;
    var currentTime = current_date_time.split("T")[1];
    currentTime = currentTime.slice(0, -4)+'1';

    console.log(currentTime);
    console.log(current_date_time)

    const response = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          roomsession(
            where: {
              day: { _eq: ${Day} }
              start_time: { _lte: "${currentTime}" }
              end_time: { _gte: "${currentTime}" }
              week: {
                start_date: { _lte: "${currentDate}" }
                end_date: { _gte: "${currentDate}" }
              }
            }
          ) {
            id
            name
            start_time
            end_time
            password
            classroom_teacher_matter {
              matter {
                name
              }
              classroom {
                room_status
              }
              teacher {
                id
                first_name
                last_name
                teacher_room_urls {
                  id
                  url
                  created_at
                  updated_at
                }
              }
            }
          }
        }
        `,
      });

    return response.data.roomsession;
  }

}
