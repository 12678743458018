import destroyActions from "modules/shared/destroy/destroyActions";
import listActions from "modules/studentStatus/list/studentStatusListActions";
import StudentStatusService from "modules/studentStatus/studentStatusService";

const prefix = "StudentStatus_DESTROY";

export default destroyActions({
  prefix,
  destroyAllFn: StudentStatusService.destroyAll,
  destroySuccessMessageI18nKey: "entities.StudentStatus.destroy.success",
  destroyAllSuccessMessageI18nKey: "entities.StudentStatus.destroyAll.success",
  redirectTo: "/studentStatus",
  listActions,
});
