import actions from './chatAction.js';

const initialData = {
  loading: false,
  chat_id: null,
  messages: [],
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.LISTE_MESSAGE_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.LISTE_MESSAGE_SUCCESS) {
    return {
      ...state,
      loading: false,
      chat_id: payload.chat_id,
      messages: payload.messages,
    };
  }

  if (type === actions.LISTE_MESSAGE_ERROR) {
    return {
      ...state,
      loading: false,
      messages: [],
    };
  }
  return state;
};
