import ExamService from 'modules/exam/examService';
import paginationAction from 'modules/shared/pagination/paginationAction';
import selectors from 'modules/exam/list/examListSelectors';
import { i18n } from 'i18n';
import exporterFields from 'modules/exam/list/examListExporterFields';

const prefix = 'MATTER_LIST';

export default paginationAction(
  prefix,
  ExamService.list,
  selectors,
  i18n('entities.Exam.exporterFileName'),
  exporterFields,
);
