import formActions from 'modules/shared/form/formActions';
import service from 'modules/gradesManager/gradesManagerService';
import { getHistory } from 'modules/store';
import Message from 'view/shared/message';
import Errors from 'modules/shared/error/errors';

const prefix = 'GRADES';

const actions = {
  ERROR_MESSAGE_CLEARED: `${prefix}_ERROR_MESSAGE_CLEARED`,

  LIST_GRADES_START: `${prefix}LIST_GRADES_START`,
  LIST_GRADES_SUCCESS: `${prefix}LIST_GRADES_SUCCESS`,
  LIST_GRADES_ERROR: `${prefix}LIST_GRADES_ERROR`,
  UPDATE_GRADES_START: `${prefix}UPDATE_GRADES_START`,
  UPDATE_GRADES_SUCCESS: `${prefix}UPDATE_GRADES_SUCCESS`,
  UPDATE_GRADES_ERROR: `${prefix}UPDATE_GRADES_ERROR`,
  INSERT_GRADES_START: `${prefix}INSERT_GRADES_START`,
  INSERT_GRADES_SUCCESS: `${prefix}INSERT_GRADES_SUCCESS`,
  INSERT_GRADES_ERROR: `${prefix}INSERT_GRADES_ERROR`,
  doClearErrorMessage() {
    return {
      type: actions.ERROR_MESSAGE_CLEARED,
    };
  },

  doList: (fields = {}) => async (dispatch) => {
    try {
      dispatch({ type: actions.LIST_GRADES_START });

      var data = await service.list(fields);

      dispatch({
        type: actions.LIST_GRADES_SUCCESS,
        payload: { rows: data },
      });
    } catch (error) {
      dispatch({ type: actions.LIST_GRADES_ERROR });
      // getHistory().push('/');
    }
  },

  doCreate: (data = []) => async (
    dispatch,
  ) => {
    try {
      dispatch({ type: actions.INSERT_GRADES_START });

      console.log(data)

      await service.create(data);

      dispatch({
        type: actions.INSERT_GRADES_SUCCESS,
      });
      Message.success('Absence a été sauvgarder');

      window.location.replace('/');

    } catch (error) {
      Errors.handle(error);
      dispatch({ type: actions.INSERT_GRADES_ERROR });
      // getHistory().push('/');
    }
  },

  doUpdate: (data = [], idroomsession) => async (
    dispatch,
  ) => {
    try {
      dispatch({ type: actions.UPDATE_GRADES_START });

    //   await service.delete_manual_absence(idroomsession);
    //   var data_formater = data
    //     .filter((e) => e.status == true)
    //     .map((e) => ({
    //       id_roomsession: idroomsession,
    //       id_student: e.id,
    //     }));
    //   console.log(data_formater);
    //   await service.insert_absence(data_formater);

      dispatch({
        type: actions.UPDATE_GRADES_SUCCESS,
      });
      Message.success('Absence a été sauvgarder');

      window.location.replace('/');
    } catch (error) {
      Errors.handle(error);
      dispatch({ type: actions.UPDATE_GRADES_ERROR });
      // getHistory().push('/');
    }
  },
};
export default actions;
