import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/circuit/importer/circuitImporterSelectors';
import CircuitService from 'modules/circuit/circuitService';
import fields from 'modules/circuit/importer/circuitImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'level_sector',
  selectors,
  CircuitService.import,
  fields,
  i18n('entities.Circuit.importer.fileName'),
);
