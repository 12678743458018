import destroyActions from 'modules/shared/destroy/destroyActions';
import listActions from 'modules/circuit/list/circuitListActions';
import CircuitService from 'modules/circuit/circuitService';

const prefix = 'Circuit_DESTROY';

export default destroyActions({
  prefix,
  destroyAllFn: CircuitService.destroyAll,
  destroySuccessMessageI18nKey:
    'entities.Circuit.destroy.success',
  destroyAllSuccessMessageI18nKey:
    'entities.Circuit.destroyAll.success',
  redirectTo: '/circuit',
  listActions,
});
