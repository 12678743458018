import AssistantService from "modules/assistant/assistantService";
import formActions from "modules/shared/form/formActions";

const prefix = "Assistant_FORM";

export default formActions({
  prefix,
  createFn: AssistantService.create,
  createSuccessMessageI18nKey: "entities.Assistant.create.success",
  updateFn: AssistantService.update,
  updateSuccessMessageI18nKey: "entities.Assistant.update.success",
  findFn: AssistantService.find,
  redirectTo: "/assistant",
});
