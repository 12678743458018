import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/semester/importer/semesterImporterSelectors';
import SemesterService from 'modules/semester/semesterService';
import fields from 'modules/semester/importer/semesterImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'semester',
  selectors,
  SemesterService.import,
  fields,
  i18n('entities.Semester.importer.fileName'),
);
