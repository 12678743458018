import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';

export default class ClassroomService {
  static async update(id, data) {
    const response1 = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        level_sector(where: {id_sector: {_eq: ${data.sector.id}}, id_level: {_eq: ${data.level.id}}}) {
          id
        }
      }
      `,
    });

    var levelSectorId = response1.data.level_sector[0].id;

    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation($data:classroom_set_input!) {
        update_classroom(where: {id: {_eq: ${id}}}, _set: $data) {
          affected_rows
        }
      }
      `,
      variables: {
        data: {
          name: data.name,
          id_level_sector: levelSectorId,
          schedule: data.schedule,
          room_status: data.room_status,
          code_massar: data.codeMassar,
        },
      },
    });

    return response.data.update_classroom;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        delete_classroom(where: {id: {_eq: ${ids}}}) {
          affected_rows
        }
      }
`,
    });

    return response.data.delete_classroom;
  }

  static async create(data) {
    const response1 = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        level_sector(where: {id_sector: {_eq: ${data.sector}}, id_level: {_eq: ${data.level}}}) {
          id
        }
      }
      `,
    });

    var levelSectorId = response1.data.level_sector[0].id;

    const response = await graphqlClient.config().mutate({
      mutation: gql`
        mutation MyMutation(
          $data: [classroom_insert_input!]!
        ) {
          insert_classroom(objects: $data) {
            affected_rows
          }
        }
      `,
      variables: {
        data: {
          name: data.name,
          id_level_sector: levelSectorId,
          schedule: data.schedule,
          code_massar: data.codeMassar,
          room_status: data.room_status,
        },
      },
    });

    return response.data.insert_classroom;
  }

  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        classroom(where: {id: {_eq: ${id}}}) {
          id
          name
          created_at
          id_level_sector
          updated_at
          schedule
          code_massar
          room_status
          level_sector {
            id
            sector {
              name
              id
            }
            level {
              id
              name
              cycle {
                id
                name
              }
            }
          }
        }
      }
      `,
    });

    return response.data.classroom[0];
  }

  static async list(filter, orderBy, limit, offset) {
    if (
      filter &&
      filter.created_at &&
      filter.created_atRange
    ) {
      delete filter.created_at;
    }
    if (filter == undefined) filter = {};

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {
    
          classroom_aggregate(where: {id:{_eq:${
            filter.id ? filter.id : null
          }},
          room_status: {_eq: ${ filter.room_status ? filter.room_status : null } },
          name:
            ${
              filter.name
                ? `{_like:"%${filter.name.label}%"}`
                : '{}'
            },code_massar:
            ${
              filter.codeMassar
                ? `{_like:"%${filter.codeMassar}%"}`
                : '{}'
            },level_sector: {id_level: {_eq: ${
            filter.level || null
          }}, id_sector: {_eq: ${
            filter.sector || null
          }}, level: {id_cycle: {_eq: ${
            filter.cycle || null
          }}}}}) {
            aggregate {
              count
            }
          }
          classroom(order_by: {${orderBy || ''}}, offset: ${
          offset != 0 ? offset : null
        }, limit: ${
          limit != 0 ? limit : null
        }, where: {id:{_eq:${
          filter.id ? filter.id : null
        }},
        room_status: {_eq: ${ filter.room_status ? filter.room_status : null } },
        name:
          ${
            filter.name
              ? `{_like:"%${filter.name.label}%"}`
              : '{}'
          },code_massar:
          ${
            filter.codeMassar
              ? `{_like:"%${filter.codeMassar}%"}`
              : '{}'
          },level_sector: {id_level: {_eq: ${
          filter.level || null
        }}, id_sector: {_eq: ${
          filter.sector || null
        }}, level: {id_cycle: {_eq: ${
          filter.cycle || null
        }}}}}) {
            name
            created_at
            id_level_sector
            updated_at
            schedule
            id
            code_massar
            room_status
            level_sector {
              id
              sector {
                name
                id
              }
              level {
                id
                name
                cycle {
                  id
                  name
                }
              }
            }
          }
        }
        `,
      });

    var data = response.data.classroom;
    data.count =
      response.data.classroom_aggregate.aggregate.count;
    return data;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          classroom(where: {name: {_like: "%${query}%"}}) {
            name
            id
          }
        }
      `,
    });

    return response.data.classroom;
  }

  static async listSelect(level, sector, cycle, element) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        classroom(
          where: {
            level_sector: {
              level: {id_cycle: {  ${
                cycle ? `_eq: ${cycle.id || cycle}` : ``
              }  }},
              id_sector: { ${
                sector ? `_eq: ${sector.id || sector}` : ``
              } }
              id_level: { ${
                level ? `_eq: ${level.id || level}` : ``
              } }
              ${
                element
                  ? `elements: {
                      id_element: {_eq: ${element}}
                  }`
                  : ''
              }
            }
          }
        ) {
          name
          id
          ${
            element
              ? `level_sector {
                  elements(where: {id_element: {_eq: ${element}}}) {
                    id_element
                  }
              }`
              : ''
          }
        }
      }
      `,
    });

    return response.data.classroom;
  }
}
