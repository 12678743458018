import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';
import role from 'security/roles';
import authService from 'modules/auth/authService';

export default class LevelSectorMatterExamService {
  static async update(id, data) {

    console.log(data)

    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        update_level_sector_matter_exam(where: {id: {_eq: ${id}}}, _set: {id_level_sector_exam: ${data.levelSectorExam}, id_element_matter: ${data.element.id}, grade_plafond: ${data.grade_plafond}, coefficient: ${data.coefficient}}) {
          affected_rows
        }
      }      
      `,
    });

    return response.data.update_level_sector_matter_exam;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        delete_level_sector_matter_exam(where: {id: {_eq: ${ids}}}) {
          affected_rows
        }
      }
      `,
    });

    return response.data.delete_level_sector_matter_exam;
  }

  static async create(data) {

    const response = await graphqlClient.config().mutate({
      mutation: gql`
        mutation MyMutation {
          insert_level_sector_matter_exam(objects: {id_level_sector_exam: ${data.levelSectorExam}, id_element_matter: ${data.element}, grade_plafond: ${data.grade_plafond}, coefficient: ${data.coefficient}}) {
            affected_rows
          }
        }
      `,
    });

    return response.data.insert_level_sector_matter_exam;
  }

  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          level_sector_matter_exam(
            where: {id: {_eq: ${id}}}
          ) {
            grade_plafond
            coefficient
            level_sector_exam {
              name
              level_sector {
                sector {
                  name
                  id
                }
                level {
                  name
                  id
                  cycle {
                    id
                    name
                  }
                }
              }
              exam {
                id
                name
              }
              semester {
                id
                name
              }
              id
            }
            element_matter {
              id
              name
              matter {
                id
                name
              }
            }
            id
            updated_at
            created_at
          }
        }
      `,
    });

    return response.data.level_sector_matter_exam;
  }

  static async list(filter, orderBy, limit, offset) {
    if (
      filter &&
      filter.created_at &&
      filter.createdAtRange
    ) {
      delete filter.created_at;
    }
    if (filter == undefined) filter = {};

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {

          level_sector_matter_exam_aggregate(where: {id: ${
            filter.id ? ` {_eq: ${filter.id}}` : '{}'
          },
          level_sector_exam: {         
            level_sector: {
              id_sector:
                  ${
                    filter.sector
                      ? `{_eq: ${filter.sector.id}}`
                      : '{}'
                  },
                id_level:
                ${
                  filter.level
                    ? `{_eq: ${filter.level.id}}`
                    : '{}'
                }
            },
            id_semester:
              ${
                filter.semester
                  ? `{_eq: ${filter.semester.id}}`
                  : '{}'
              }
            }
          ,
          grade_plafond:
            ${
              filter.grade_plafond
                ? `{_eq: ${filter.grade_plafond}}`
                : '{}'
            }
          ,
          coefficient:
            ${
              filter.coefficient
                ? `{_eq: ${filter.coefficient}}`
                : '{}'
            }
          ,
          element_matter: {
            id: 
            ${
              filter.element
                ? `{_eq: ${filter.element.id}}`
                : '{}'
            }
            matter: {
              id: 
              ${
                filter.matter
                  ? `{_eq: ${filter.matter.id}}`
                  : '{}'
              }
            }
          }
                
                ${
                  filter.created_at
                    ? `,created_at:{_eq:"${filter.created_at.format()}"}`
                    : ''
                },
              
                  updated_at:
              ${
                filter.updated_at
                  ? `{_eq:"${filter.updated_at.format()}"}`
                  : '{}'
              }
              
              ${
                filter.createdAtRange
                  ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
                  : ''
              }
                }) {
            aggregate {
              count
            }
          }

          level_sector_matter_exam( ${
            limit != 0 ? `limit:${limit},` : ''
          } ${
          offset != 0 ? `offset:${offset} ,` : ''
        } order_by:{${
          orderBy ? orderBy : ''
        }}, where: {id: ${
          filter.id ? ` {_eq: ${filter.id}}` : '{}'
        },
        level_sector_exam: {         
          level_sector: {
            id_sector:
                ${
                  filter.sector
                    ? `{_eq: ${filter.sector.id}}`
                    : '{}'
                },
              id_level:
              ${
                filter.level
                  ? `{_eq: ${filter.level.id}}`
                  : '{}'
              }
          },
          id_semester:
            ${
              filter.semester
                ? `{_eq: ${filter.semester.id}}`
                : '{}'
            }
          }
        ,
        grade_plafond:
          ${
            filter.grade_plafond
              ? `{_eq: ${filter.grade_plafond}}`
              : '{}'
          }
        ,
        coefficient:
          ${
            filter.coefficient
              ? `{_eq: ${filter.coefficient}}`
              : '{}'
          }
        ,
        element_matter: {
          id: 
          ${
            filter.element
              ? `{_eq: ${filter.element.id}}`
              : '{}'
          }
          matter: {
            id: 
            ${
              filter.matter
                ? `{_eq: ${filter.matter.id}}`
                : '{}'
            }
          }
        }
              
              ${
                filter.created_at
                  ? `,created_at:{_eq:"${filter.created_at.format()}"}`
                  : ''
              },
            
                updated_at:
            ${
              filter.updated_at
                ? `{_eq:"${filter.updated_at.format()}"}`
                : '{}'
            }
            
            ${
              filter.createdAtRange
                ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
                : ''
            }
              }) {
                grade_plafond
                coefficient
                level_sector_exam {
                  name
                  level_sector {
                    sector {
                      name
                      id
                    }
                    level {
                      name
                      id
                      cycle {
                        id
                        name
                      }
                    }
                  }
                  exam {
                    id
                    name
                  }
                  semester {
                    id
                    name
                  }
                  id
                }
                element_matter {
                  id
                  name
                  matter {
                    id
                    name
                  }
                }
                id
                updated_at
                created_at
              }
            }
        `,
      });

    let data = response.data.level_sector_matter_exam;
    let newData = data.map((item) => ({
      ...item,
      id: item.id,
      grade_plafond: item.grade_plafond,
      coefficient: item.coefficient,
    }));

    newData.count = response.data.level_sector_matter_exam_aggregate.aggregate.count;

    return newData;
  }

}
