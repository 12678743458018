import list from "modules/assistant/list/assistantListReducers";
import form from "modules/assistant/form/assistantFormReducers";
import view from "modules/assistant/view/assistantViewReducers";
import destroy from "modules/assistant/destroy/assistantDestroyReducers";
import importerReducer from "modules/assistant/importer/assistantImporterReducers";
import { combineReducers } from "redux";

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
