import list from "modules/studentStatus/list/studentStatusListReducers";
import form from "modules/studentStatus/form/studentStatusFormReducers";
import view from "modules/studentStatus/view/studentStatusViewReducers";
import destroy from "modules/studentStatus/destroy/studentStatusDestroyReducers";
import importerReducer from "modules/studentStatus/importer/studentStatusImporterReducers";
import { combineReducers } from "redux";

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
