import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';
import role from 'security/roles';
import authService from 'modules/auth/authService';

export default class LevelSectorElementService {
  static async update(id, data) {

    // const response1 = await graphqlClient.config().query({
    //   query: gql`
    //     query MyQuery {
    //       level_sector(where: { id_level: { _eq: ${data.level} }, id_sector: { _eq: ${data.sector} } }) {
    //         id
    //       }
    //     }
    //   `,
    // });
    // var id_levelSector = response1.data.level_sector[0].id;

    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        update_level_sector_element(where: {id: {_eq: ${id}}}, _set: {id_element: ${data.element}, id_level_sector: ${data.levelSector}}) {
          affected_rows
        }
      }      
      `,
    });

    return response.data.update_level_sector_element;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        delete_level_sector_element(where: {id: {_eq: ${ids}}}) {
          affected_rows
        }
      }
      `,
    });

    return response.data.delete_level_sector_element;
  }

  static async create(data) {

    // const response1 = await graphqlClient.config().query({
    //   query: gql`
    //     query MyQuery {
    //       level_sector(where: { id_level: { _eq: ${data.level} }, id_sector: { _eq: ${data.sector} } }) {
    //         id
    //       }
    //     }
    //   `,
    // });
    // var id_levelSector = response1.data.level_sector[0].id;

    const response = await graphqlClient.config().mutate({
      mutation: gql`
        mutation MyMutation {
          insert_level_sector_element(objects: {id_element: ${data.element}, id_level_sector: ${data.levelSector}}) {
            affected_rows
          }
        }
      `,
    });

    return response.data.insert_level_sector_element;
  }

  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        level_sector_element(
          where: {id: {_eq: ${id}}}
        ) {
          level_sector {
            id
            name
            sector {
              name
              id
            }
            level {
              name
              id
              cycle {
                id
                name
              }
            }
          }
          element_matter {
            id
            name
            matter {
              id
              name
            }
          }
          id
          updated_at
          created_at
        }
      }
      `,
    });

    return response.data.level_sector_element;
  }

  static async list(filter, orderBy, limit, offset) {
    if (
      filter &&
      filter.created_at &&
      filter.createdAtRange
    ) {
      delete filter.created_at;
    }
    if (filter == undefined) filter = {};

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {

          level_sector_element_aggregate(where: {id: ${
            filter.id ? ` {_eq: ${filter.id}}` : '{}'
          },                 
          level_sector: {
            id_sector:
                ${
                  filter.sector
                    ? `{_eq: ${filter.sector.id}}`
                    : '{}'
                },
              id_level:
              ${
                filter.level
                  ? `{_eq: ${filter.level.id}}`
                  : '{}'
              }
          },
          element_matter: {
            id: ${
              filter.element
                ? `{_eq: ${filter.element.id}}`
                : '{}'
            }
            matter: {
              id: ${
                filter.matter
                  ? `{_eq: ${filter.matter.id}}`
                  : '{}'
              }
            }
          }
                
                ${
                  filter.created_at
                    ? `,created_at:{_eq:"${filter.created_at.format()}"}`
                    : ''
                },
              
                  updated_at:
              ${
                filter.updated_at
                  ? `{_eq:"${filter.updated_at.format()}"}`
                  : '{}'
              }
              
              ${
                filter.createdAtRange
                  ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
                  : ''
              }
                }) {
            aggregate {
              count
            }
          }

          level_sector_element( ${
            limit != 0 ? `limit:${limit},` : ''
          } ${
          offset != 0 ? `offset:${offset} ,` : ''
        } order_by:{${
          orderBy ? orderBy : ''
        }}, where: {id: ${
          filter.id ? ` {_eq: ${filter.id}}` : '{}'
        },              
          level_sector: {
            id_sector:
                ${
                  filter.sector
                    ? `{_eq: ${filter.sector.id}}`
                    : '{}'
                },
              id_level:
              ${
                filter.level
                  ? `{_eq: ${filter.level.id}}`
                  : '{}'
              }
          },
          element_matter: {
            id: ${
              filter.element
                ? `{_eq: ${filter.element.id}}`
                : '{}'
            }
            matter: {
              id: ${
                filter.matter
                  ? `{_eq: ${filter.matter.id}}`
                  : '{}'
              }
            }
          }
              
              ${
                filter.created_at
                  ? `,created_at:{_eq:"${filter.created_at.format()}"}`
                  : ''
              },
            
                updated_at:
            ${
              filter.updated_at
                ? `{_eq:"${filter.updated_at.format()}"}`
                : '{}'
            }
            
            ${
              filter.createdAtRange
                ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
                : ''
            }
              }) {
                level_sector {
                  id
                  name
                  sector {
                    name
                    id
                  }
                  level {
                    name
                    id
                    cycle {
                      id
                      name
                    }
                  }
                }
                element_matter {
                  id
                  name
                  matter {
                    id
                    name
                  }
                }
                id
                updated_at
                created_at
              }
            }
        `,
      });

    let data = response.data.level_sector_element;
    let newData = data.map((item) => ({
      ...item,
      id: item.id,
      sector: item.level_sector.sector.name,
      level: item.level_sector.level.name,
      cycle: item.level_sector.level.cycle.name,
      element: item.element_matter.name,
      matter: item.element_matter.matter.name,
    }));
    newData.count =
      response.data.level_sector_element_aggregate.aggregate.count;

    return newData;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        level_sector_element(where: {level: {user: {name: {_like: "%${query}%"}}}}) {
          sector {
            name
            id
          }
          level {
            name
            id
            cycle {
              id
              name
            }
          }
          id
          updated_at
          created_at
        }
      }
      `,
    });

    return response.data.level_sector_element;
  }

  static async listSelect(limit) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        level {
          id
          updated_at
          created_at
          name
        }
      }
      `,
    });
    return response.data.level;
  }
}
