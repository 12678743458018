import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';
import moment from 'moment';
import service from 'modules/roomSession/roomSessionService';

export default class AbsenceService {
  static async update(id, data) {
    const response2 = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          teachers {
            id
          }
        }
      `,
    });

    var teacherId = response2.data.teachers[0].id;

    const response1 = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        classroom_teacher_matter(where: {id_classroom: {_eq: ${data.classroom.id}}, id_teacher: {_eq: ${teacherId}}, id_matter: {_eq: ${data.matter.id}}}) {
          id
        }
      }
      `,
    });

    var id_CTM =
      response1.data.classroom_teacher_matter[0].id;

    const response = await graphqlClient.config().mutate({
      mutation: gql`
        mutation MyMutation($data: absence_set_input!) {
          update_absence(
            where: { id: { _eq: ${id} } }
            _set: $data
          ) {
            affected_rows
          }
        }
      `,
      variables: {
        data: {
          name: data.name,
          id_type: data.types_absence.id,
          id_classroom_teacher_matter: id_CTM,
          payload: data.payload,
          id_element: data.element,
          type_course: data.type_course,
        },
      },
    });

    return response.data.update_absence;
  }

  static async delete_manual_absence(ids) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        delete_manual_absence(where: {id: {_eq: ${ids}}}) {
          affected_rows
        }
      }
      `,
    });

    return response.data;
  }

  static async create(data) {
    var rows, response;

    await data.map((item, index) => {
      response = graphqlClient.config().mutate({
        mutation: gql`
          mutation MyMutation {
            insert_manual_absence(objects: {id_student: ${item.students}, id_roomsession: ${item.roomsessions}}) {
              affected_rows
            }
          }
        `,
      });
      rows = response.data.insert_manual_absence;
    });

    return rows;
  }

  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          absence(where: { id: { _eq: ${id} } }) {
            id
            coursHTML
            classroom_teacher_matter {
              classroom {
                id
                name
                level_sector {
                  sector {
                    id
                    name
                  }
                  level {
                    id
                    name
                    cycle {
                      id
                      name
                    }
                  }
                  elements {
                    id
                    name
                  }
                }
              }
              matter {
                id
                name
              }
            }
            name
            type_course
            types_absence {
              id
              name
            }
            payload
            created_at
            updated_at
          }
        }
      `,
    });

    let data = response.data.absence[0];

    // data.level = data.classroom.level.level;
    // delete data.classroom.level.level;
    // data.level = data.classroom.level;
    // delete data.classroom.level;

    return data;
  }

  static async list() {
    const date = new Date();
    const Day = date.getDay();

    var current_date_time = await service.get_current_time();
    var currentDate = current_date_time;
    var currentTime = current_date_time.split("T")[1];
    currentTime = currentTime.slice(0, -4)+'1';

    console.log(currentTime);
    console.log(current_date_time);

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {
          roomsession(where: {day: {_eq: ${Day}}, start_time: {_lte: "${currentTime}"}, end_time: {_gte: "${currentTime}"}, week: {start_date: {_lte: "${currentDate}"}, end_date: {_gte: "${currentDate}"}}}) {
             id
            classroom_teacher_matter {
              classroom {
                registers {
                  student {
                    id
                    first_name
                    last_name
                    manual_absences(where: {roomsession: {day: {_eq: ${Day}}, start_time: {_lte: "${currentTime}"}, end_time: {_gte: "${currentTime}"}, week: {start_date: {_lte: "${currentDate}"}, end_date: {_gte: "${currentDate}"}}}}) {
                      id_roomsession
                    }
                  }
                }
              }
            }
          }
        }        
        `,
      });
    return response.data.roomsession;
  }

  static async listAdmin(
    filter = {},
    orderBy,
    limit,
    offset,
  ) {
    if (
      filter &&
      filter.createdAt &&
      filter.createdAtRange
    ) {
      delete filter.createdAt;
    }
    if (filter == undefined) filter = {};

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {

          students_aggregate(where: {manual_absences: {roomsession: {day: {_eq: ${
            filter.day || null
          }}, start_time: {_lte: "${moment
          .tz(filter.start_time, 'Africa/Casablanca')
          .format(
            'HH:mm:ss z',
          )}"}, end_time: {_gte: "${moment
          .tz(filter.end_time, 'Africa/Casablanca')
          .format('HH:mm:ss z')}"}, id_week: {_eq: ${
          filter.week || null
        }}, classroom_teacher_matter: {id_classroom: {_eq: ${
          filter.classroom || null
        }}}}}}){
            aggregate{
              count
            }
          }
          students(where: {manual_absences: {roomsession: {day: {_eq: ${
            filter.day || null
          }}, start_time: {_lte: "${moment
          .tz(filter.start_time, 'Africa/Casablanca')
          .format(
            'HH:mm:ss z',
          )}"}, end_time: {_gte: "${moment
          .tz(filter.end_time, 'Africa/Casablanca')
          .format('HH:mm:ss z')}"}, id_week: {_eq: ${
          filter.week || null
        }}, classroom_teacher_matter: {id_classroom: {_eq: ${
          filter.classroom || null
        }}}}}}) {
            id
            first_name
            last_name
            code_massar
            registers(where: {student: { manual_absences: {roomsession: {day: {_eq: ${
              filter.day || null
            }}, start_time: {_lte: "${moment
            .tz(filter.start_time, 'Africa/Casablanca')
            .format(
              'HH:mm:ss z',
            )}"}, end_time: {_gte: "${moment
            .tz(filter.end_time, 'Africa/Casablanca')
            .format('HH:mm:ss z')}"}, id_week: {_eq: ${
            filter.week || null
          }}, classroom_teacher_matter: {id_classroom: {_eq: ${
            filter.classroom || null
          }}}}} }}) {
              classroom {
                id
                name
              }
            }
            manual_absences(where: {roomsession: {day: {_eq: ${
              filter.day || null
            }}, start_time: {_lte: "${moment
          .tz(filter.start_time, 'Africa/Casablanca')
          .format(
            'HH:mm:ss z',
          )}"}, end_time: {_gte: "${moment
          .tz(filter.end_time, 'Africa/Casablanca')
          .format('HH:mm:ss z')}"}, id_week: {_eq: ${
          filter.day || null
        }}, classroom_teacher_matter: {id_classroom: {_eq: ${
          filter.classroom || null
        }}}}}) {
              id
            }
          }
        }
        `,
      });
    var data = response.data.students;
    data.count =
      response.data.students_aggregate.aggregate.count;
    return data;
  }

  static async insert_absence(data) {
    console.log(data)
    const response = await graphqlClient.config().mutate({
      mutation: gql`
        mutation insert(
          $data: [manual_absence_insert_input!]!
        ) {
          insert_manual_absence(objects: $data) {
            affected_rows
          }
        }
      `,
      variables: { data },
    });
    console.log(response);

    return response.data;
  }
}
