import model from 'modules/register/registerModel';

const { fields } = model;

export default [
  fields.id,
  fields.className,
  fields.studentName,
  fields.transport,
  fields.online,
  fields.canteen,
  fields.code_massar,
  fields.matr,
  fields.studentEmail,
  fields.studentPhone,
  fields.parentName,
  fields.parentEmail,
  fields.parentPhone,
];
