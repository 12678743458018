import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';
import role from 'security/roles';
import authService from 'modules/auth/authService';

export default class StudentsService {
  static async update(id, data) {
    const response1 = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation{
        update_students(where: {id: {_eq: ${id}}},
          _set: {
            code_massar: "${data.code_massar}",
            matr: ${data.matr},
            id_neighborhood: ${data.neighborhood},
            last_name: "${data.last_name}",
            first_name: "${data.first_name}"
            ${
              data.father
                ? `,id_father: ${data.father.id}`
                : ''
            }
            ${
              data.mother
                ? `,id_mother: ${data.mother.id}`
                : ''
            }
            ${
              data.tutor1
                ? `,id_tutor1: ${data.tutor1.id}`
                : ''
            }
            ${
              data.tutor2
                ? `,id_tutor2: ${data.tutor2.id}`
                : ''
            }
            ${
              data.quality_tutor1
                ? `,quality_tutor1: ${parseInt(
                    data.quality_tutor1,
                  )}`
                : ''
            }
            ${
              data.quality_tutor2
                ? `,quality_tutor2: ${parseInt(
                    data.quality_tutor2,
                  )}`
                : ''
            }
          }) {
            affected_rows
        }
      }
      `,
    });

    return response1.data.update_students;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        delete_students(where: {id: {_eq: ${ids}}}) {
          affected_rows
          returning {
            id_user
          }
        }
      }
      `,
    });
    let id_user =
      response.data.delete_students.returning[0].id_user;

    const response1 = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        delete_users(where: {id: {_eq: "${id_user}"}}) {
          affected_rows
        }
      }
      `,
    });

    return response1.data.delete_users;
  }

  static async create(data) {
    let userId = await authService.createUser({
      email: data.email,
      phone: data.phone,
      role: role.values.student,
    });

    const resp1 = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        insert_students(objects: {
          id_user: "${userId}",
          code_massar: "${data.code_massar}",
          matr: ${data.matr},
          id_neighborhood: ${data.neighborhood},
          last_name: "${data.last_name}",
          first_name: "${data.first_name}",
          ${
            data.father
              ? `,id_father: ${data.father.id}`
              : ''
          }
          ${
            data.mother
              ? `,id_mother: ${data.mother.id}`
              : ''
          }
          ${
            data.tutor1
              ? `,id_tutor1: ${data.tutor1.id}`
              : ''
          }
          ${
            data.tutor2
              ? `,id_tutor2: ${data.tutor2.id}`
              : ''
          }
          ${
            data.quality_tutor1
              ? `,quality_tutor1: ${parseInt(
                  data.quality_tutor1,
                )}`
              : ''
          }
          ${
            data.quality_tutor2
              ? `,quality_tutor2: ${parseInt(
                  data.quality_tutor2,
                )}`
              : ''
          }
        }) {
          affected_rows
        }
      }
      `,
    });

    return resp1.data.insert_students;
  }

  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          students_view(where: {id: {_eq: ${id}}}) {
            id
            matr
            updated_at
            created_at
            first_name
            last_name
            email
            phone
            user_id
            code_massar
            neighborhood_id
            neighborhood_name
            address
            city
            date_start
            date_of_birth
          }
        }
      `,
    });
    var data = response.data.students_view;

    var new_Data = data.map((e) => ({
      ...e,
      user: {
        email: e.email,
        phone: e.phone,
        id: e.user_id,
      },
      neighborhood: {
        id: e.neighborhood_id,
        name: e.neighborhood_name,
      },
    }));

    return new_Data[0];
  }

  static async findStudents(idClassroom) {
    const response = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          students(where: {registers: {classroom: {id: {_eq: ${idClassroom}}}}}) {
            id
            id_user
            code_massar
            matr
            first_name
            last_name
            neighborhood {
              id
              name
            }
            registers {
              transport
              online
              canteen
              id
              school_year {
                id
                name
              }
              classroom {
                id
                name
              }
            }
          }
        }
      `,
    });

    return response.data.students;
  }

  static async list(filter, orderBy, limit, offset) {
    if (
      filter &&
      filter.createdAt &&
      filter.createdAtRange
    ) {
      delete filter.createdAt;
    }
    if (filter == undefined) filter = {};

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
          query MyQuery {

            students_view_aggregate(where: {code_massar: {_like: "%${
              filter.code_massar || ''
            }%"}, first_name: {_like: "%${
          filter.first_name || ''
        }%"}, last_name: {_like: "%${
          filter.last_name || ''
        }%"}, id: {_eq: ${
          filter.id || null
        }},
          phone:
          ${
            filter.phone
              ? `{_like: "%${filter.phone}%"}`
              : '{}'
          },
          email:
          ${
            filter.email
              ? `{_like: "%${filter.email}%"}`
              : '{}'
          }
          ${
            filter.neighborhood
              ? `,neighborhood_id:
                    {_eq: ${filter.neighborhood}}
                  `
                  : ''
          },
        updated_at:
      ${
        filter.updatedAt
          ? `{_eq:"${filter.updatedAt.format()}"}`
          : '{}'
      }
      ${
        filter.createdAtRange
          ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
          : ''
      }
      }) {
              aggregate {
                count
              }
            }
  
            students_view(where: {code_massar: {_like: "%${
              filter.code_massar || ''
            }%"}, first_name: {_like: "%${
          filter.first_name || ''
        }%"}, last_name: {_like: "%${
          filter.last_name || ''
        }%"}, id: {_eq: ${
          filter.id || null
        }},
          phone:
          ${
            filter.phone
              ? `{_like: "%${filter.phone}%"}`
              : '{}'
          },
          email:
          ${
            filter.email
              ? `{_like: "%${filter.email}%"}`
              : '{}'
          }
        ${
          filter.neighborhood
            ? `,neighborhood_id:
                  {_eq: ${filter.neighborhood}}
                `
                : ''
        },
        updated_at:
      ${
        filter.updatedAt
          ? `{_eq:"${filter.updatedAt.format()}"}`
          : '{}'
      }
      ${
        filter.createdAtRange
          ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
          : ''
      }
      }, order_by: {${
          orderBy || ''
        }}, limit:${limit}, offset: ${offset}) {
              id
              matr
              updated_at
              created_at
              first_name
              last_name
              email
              phone
              user_id
              code_massar
              neighborhood_id
              neighborhood_name
              address
              city
              date_start
              date_of_birth
            }
          }
        `,
      });
    var data = response.data.students_view;

    var new_Data = data.map((e) => ({
      ...e,
      user: {
        email: e.email,
        phone: e.phone,
        id: e.user_id,
      },
      neighborhood: {
        id: e.neighborhood_id,
        name: e.neighborhood_name,
      },
    }));

    new_Data.count =
      response.data.students_view_aggregate.aggregate.count;
    return new_Data;
  }

  static async listAutocomplete(query) {
    var Myarray = [];
    var response = {};
    // if (parseInt(query) === NaN) {
    if (isNaN(query)) {
      Myarray = query.split(' ');
      if (Myarray.length == 1) {
        Myarray[1] = '';
      }
      response = await graphqlClient.config().query({
        query: gql`
        query MyQuery {
          students(where: {
            _or: [
              {last_name: {_like: "%${Myarray[0]}%"}, first_name: {_like: "%${Myarray[1]}%"}},
              {first_name: {_like: "%${Myarray[0]}%"}, last_name: {_like: "%${Myarray[1]}%"}}
            ]
          }) {
            id
            first_name
            last_name
          }
        }
        `,
      });
    } else {
      response = await graphqlClient.config().query({
        query: gql`
        query MyQuery {
          students(where: {matr: {_eq: ${parseInt(query)}}}) {
            id
            first_name
            last_name
          }
        }
        `,
      });
    }

    

    var newMap = response.data.students.map((item) => {
      return {
        id: item.id,
        label: item.first_name + ' ' + item.last_name,
      };
    });

    return newMap;
  }

  static async listSelect(limit) {
    const response = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          students(where: {}) {
            id
            first_name
            last_name
          }
        }
      `,
    });

    return response.data.students;
  }
}
