import list from 'modules/levelSectorExam/list/levelSectorExamListReducers';
import form from 'modules/levelSectorExam/form/levelSectorExamFormReducers';
import view from 'modules/levelSectorExam/view/levelSectorExamViewReducers';
import destroy from 'modules/levelSectorExam/destroy/levelSectorExamDestroyReducers';
import importerReducer from 'modules/levelSectorExam/importer/levelSectorExamImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
    list,
    form,
    view,
    destroy,
    importer: importerReducer,
});