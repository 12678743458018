import LevelSectorExamService from 'modules/levelSectorExam/levelSectorExamService';
import paginationAction from 'modules/shared/pagination/paginationAction';
import selectors from 'modules/levelSectorExam/list/levelSectorExamListSelectors';
import { i18n } from 'i18n';
import exporterFields from 'modules/levelSectorExam/list/levelSectorExamListExporterFields';

const prefix = 'LevelSectorExam_LIST';

export default paginationAction(
  prefix,
  LevelSectorExamService.list,
  selectors,
  i18n('entities.LevelSectorExam.exporterFileName'),
  exporterFields,
);
