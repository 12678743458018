import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';
import role from 'security/roles';
import authService from 'modules/auth/authService';

export default class CircuitService {
  static async update(id, data) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        update_circuit(where: {id: {_eq: ${id}}}, _set: {id_assistant: ${data.assistant}, id_transport: ${data.transport}, name: "${data.name}"}) {
          affected_rows
        }
      }
      `,
    });

    return response.data.update_circuit;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        delete_circuit(where: {id: {_eq: ${ids}}}) {
          affected_rows
        }
      }
      `,
    });

    return response.data.delete_circuit;
  }

  static async create(data) {

    const response = await graphqlClient.config().mutate({
      mutation: gql`
        mutation MyMutation {
          insert_circuit(objects: {id_transport: ${data.transport}, id_assistant: ${data.assistant}, name: "${data.name}"}) {
            affected_rows
          }
        }
      `,
    });

    return response.data.insert_circuit;
  }

  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        circuit(
          where: {id: {_eq: ${id}}}
        ) {
          id
          name
          assistant {
            id
            first_name
            last_name
          }
          transport {
            id
            number
            brand
            model
          }
          updated_at
          created_at
        }
      }
      `,
    });

    return response.data.circuit;
  }

  static async list(filter, orderBy, limit, offset) {
    if (
      filter &&
      filter.created_at &&
      filter.createdAtRange
    ) {
      delete filter.created_at;
    }
    if (filter == undefined) filter = {};

    console.log(`
    query MyQuery {

      circuit_aggregate(where: {id: ${
        filter.id ? ` {_eq: ${filter.id}}` : '{}'
      },              
        id_assistant:
              ${
                filter.assistant
                  ? `{_eq: ${filter.assistant.id}}`
                  : '{}'
              },
              id_transport:
              ${
                filter.transport
                  ? `{_eq: ${filter.transport.id}}`
                  : '{}'
              },
              name:
              ${
                filter.name
                  ? `{_eq: "${filter.name}"}`
                  : '{}'
              }
            
            ${
              filter.created_at
                ? `,created_at:{_eq:"${filter.created_at.format()}"}`
                : ''
            },
          
              updated_at:
          ${
            filter.updated_at
              ? `{_eq:"${filter.updated_at.format()}"}`
              : '{}'
          }
          
          ${
            filter.createdAtRange
              ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
              : ''
          }
            }) {
        aggregate {
          count
        }
      }

      circuit( ${
        limit != 0 ? `limit:${limit},` : ''
      } ${
      offset != 0 ? `offset:${offset} ,` : ''
    } order_by:{${
      orderBy ? orderBy : ''
    }}, where: {id: ${
      filter.id ? ` {_eq: ${filter.id}}` : '{}'
    },                
    id_assistant:
          ${
            filter.assistant
              ? `{_eq: ${filter.assistant.id}}`
              : '{}'
          },
          id_transport:
          ${
            filter.transport
              ? `{_eq: ${filter.transport.id}}`
              : '{}'
          },
          name:
          ${
            filter.name
              ? `{_eq: "${filter.name}"}`
              : '{}'
          }
          
          ${
            filter.created_at
              ? `,created_at:{_eq:"${filter.created_at.format()}"}`
              : ''
          },
        
            updated_at:
        ${
          filter.updated_at
            ? `{_eq:"${filter.updated_at.format()}"}`
            : '{}'
        }
        
        ${
          filter.createdAtRange
            ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
            : ''
        }
          }) {
            id
            name
            assistant {
              id
              first_name
              last_name
            }
            transport {
              id
              number
              brand
              model
            }
            updated_at
            created_at
          }
        }
    `)

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {

          circuit_aggregate(where: {id: ${
            filter.id ? ` {_eq: ${filter.id}}` : '{}'
          },              
            id_assistant:
                  ${
                    filter.assistant
                      ? `{_eq: ${filter.assistant.id}}`
                      : '{}'
                  },
                  id_transport:
                  ${
                    filter.transport
                      ? `{_eq: ${filter.transport.id}}`
                      : '{}'
                  },
                  name:
                  ${
                    filter.name
                      ? `{_eq: "${filter.name}"}`
                      : '{}'
                  }
                
                ${
                  filter.created_at
                    ? `,created_at:{_eq:"${filter.created_at.format()}"}`
                    : ''
                },
              
                  updated_at:
              ${
                filter.updated_at
                  ? `{_eq:"${filter.updated_at.format()}"}`
                  : '{}'
              }
              
              ${
                filter.createdAtRange
                  ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
                  : ''
              }
                }) {
            aggregate {
              count
            }
          }

          circuit( ${
            limit != 0 ? `limit:${limit},` : ''
          } ${
          offset != 0 ? `offset:${offset} ,` : ''
        } order_by:{${
          orderBy ? orderBy : ''
        }}, where: {id: ${
          filter.id ? ` {_eq: ${filter.id}}` : '{}'
        },                
        id_assistant:
              ${
                filter.assistant
                  ? `{_eq: ${filter.assistant.id}}`
                  : '{}'
              },
              id_transport:
              ${
                filter.transport
                  ? `{_eq: ${filter.transport.id}}`
                  : '{}'
              },
              name:
              ${
                filter.name
                  ? `{_eq: "${filter.name}"}`
                  : '{}'
              }
              
              ${
                filter.created_at
                  ? `,created_at:{_eq:"${filter.created_at.format()}"}`
                  : ''
              },
            
                updated_at:
            ${
              filter.updated_at
                ? `{_eq:"${filter.updated_at.format()}"}`
                : '{}'
            }
            
            ${
              filter.createdAtRange
                ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
                : ''
            }
              }) {
                id
                name
                assistant {
                  id
                  first_name
                  last_name
                }
                transport {
                  id
                  number
                  brand
                  model
                }
                updated_at
                created_at
              }
            }
        `,
      });

    let data = response.data.circuit;

    return data;
  }

  static async listSelect() {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        circuit {
          id
          name
          assistant {
            id
            first_name
            last_name
          }
          transport {
            id
            number
            brand
            model
          }
        }
      }
      `,
    });
    return response.data.circuit;
  }

}
