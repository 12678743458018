import LevelSectorExamService from 'modules/levelSectorExam/levelSectorExamService';
import formActions from 'modules/shared/form/formActions';

const prefix = 'LevelSectorExam_FORM';

export default formActions({
    prefix,
    createFn: LevelSectorExamService.create,
    createSuccessMessageI18nKey:
        'entities.LevelSectorExam.create.success',
    updateFn: LevelSectorExamService.update,
    updateSuccessMessageI18nKey:
        'entities.LevelSectorExam.update.success',
    findFn: LevelSectorExamService.find,
    redirectTo: '/levelSectorExam',
});