import NeighborhoodService from 'modules/neighborhood/neighborhoodService';
import formActions from 'modules/shared/form/formActions';

const prefix = 'Neighborhood_FORM';

export default formActions({
    prefix,
    createFn: NeighborhoodService.create,
    createSuccessMessageI18nKey:
        'entities.Neighborhood.create.success',
    updateFn: NeighborhoodService.update,
    updateSuccessMessageI18nKey:
        'entities.Neighborhood.update.success',
    findFn: NeighborhoodService.find,
    redirectTo: '/neighborhood',
});