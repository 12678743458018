import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/neighborhood/importer/neighborhoodImporterSelectors';
import NeighborhoodService from 'modules/neighborhood/neighborhoodService';
import fields from 'modules/neighborhood/importer/neighborhoodImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'level_sector',
  selectors,
  NeighborhoodService.import,
  fields,
  i18n('entities.Neighborhood.importer.fileName'),
);
