import importerActions from "modules/shared/importer/importerActions";
import selectors from "modules/security/importer/securityImporterSelectors";
import SecurityService from "modules/security/securityService";
import fields from "modules/security/importer/securityImporterFields";
import { i18n } from "i18n";

export default importerActions(
  "security",
  selectors,
  SecurityService.import,
  fields,
  i18n("entities.Security.importer.fileName")
);
