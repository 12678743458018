const en = {
  common: {
    or: 'or',
    cancel: 'Cancel',
    reset: 'Reset',
    save: 'Save',
    search: 'Search',
    edit: 'Edit',
    publish: 'Publish',
    closing: 'Closing',
    duplicate: 'Duplicate',
    archive: 'Archive',
    cancelPub: 'Cancel the publication',
    remove: 'Remove',
    new: 'New',
    export: 'Export to Excel',
    noDataToExport: 'No data to export',
    import: 'Import',
    discard: 'Discard',
    yes: 'Yes',
    no: 'No',
    pause: 'Pause',
    areYouSure: 'Are you sure?',
    view: 'View',
    destroy: 'Delete',
    mustSelectARow: 'Must select a row',
  },

  app: {
    // title: 'Fadaa Al Ouloume',
    title: 'Ecole Taiba',
  },

  auth: {
    accountSeting: {
      title: 'Settings',
    },
    menu: 'Settings',

    profile: {
      title: 'Edit Profile',
      success: 'Profile updated successfully',
    },
    editpassword: {
      title: 'Edit Password',
      success: 'Password updated successfully',
      error: 'Password Incorrect',
      errornewpassword: 'Passwords do not match',
    },
    createAnAccount: 'Create an account',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password',
    signin: 'Sign in',
    signup: 'Sign up',
    signout: 'Sign out',
    alreadyUsed: 'Already used',
    alreadyHaveAnAccount:
      'Already have an account? Sign in.',
    signinWithAnotherAccount:
      'Sign in with another account',
    emailUnverified: {
      message: `Please confirm your email at <strong>{0}</strong> to continue.`,
      submit: `Resend email verification`,
    },
    emptyPermissions: {
      message: `You have no permissions yet. Wait for the admin to grant you privileges.`,
    },
    passwordReset: {
      message: 'Send password reset email',
      message1: 'Resend mail',
      error: `Email not recognized`,
    },
    emailAddressVerificationEmail: {
      error: `Email not recognized`,
    },
    verificationEmailSuccess: `Verification email sent successfully`,
    alreadyActive: 'Account already Active',

    passwordResetSuccess: `Password reset email sent successfully`,

    passwordResetErrorEmail: `Email is invalid`,
  },

  Home: {
    Classe: 'Class',
    Classes: 'Class list',
    menu: {
      Cours: 'Courses',
      Classes: 'Live Classe',
      records: 'Records',
      Access: 'Access',
    },
  },

  entities: {
    Assignments: {
      menu: 'Assignments',
      list: {
        title: 'Assignments list',
      },
      fields: {
        id: 'id',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        createdAtRange: 'Created At Range',
        assignmentsName: 'assignment Name',
        types_assignment: 'Assignment Type',
      },
    },
    Matter: {
      menu: 'matters',
      list: {
        title: 'matters',
      },
      fields: {
        id: 'id',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        name: 'Matter Name',
        createdAtRange: 'Created At Range',
      },
    },
    Teachers: {
      menu: 'Teachers',
      list: {
        title: 'Teachers',
      },
      fields: {
        id: 'id',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        createdAtRange: 'Created At Range',
        name: 'Username',
        first_name: 'First Name',
        last_name: 'Last Name',
        email: 'E-mail',
      },
    },
    ClassroomStudent: {
      menu: 'Student - Classe',
      list: {
        title: 'Student - Classe',
      },
      fields: {
        id: 'id',
        created_at: 'Created At',
        updated_at: 'Updated At',
        createdAtRange: 'Created At Range',
        students: 'Student',
        class: 'Class',
        level: 'Level',
        level: 'Level',
        matter: 'Matter',
      },
    },

    Students: {
      menu: 'Students',
      list: {
        title: 'School Students',
      },
      fields: {
        id: 'id',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        createdAtRange: 'Created At Range',
        name: 'Username',
        first_name: 'First Name',
        last_name: 'Last Name',
        email: 'E-mail',
      },
    },
    Responsibles: {
      menu: 'Responsibles',
      list: {
        title: 'School Responsibles',
      },
      fields: {
        id: 'id',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        createdAtRange: 'Created At Range',
        Username: 'Username',
        Firstname: 'First Name',
        Lastname: 'Last Name',
        Email: 'E-mail',
      },
    },
    Level: {
      menu: 'Levels',
      list: {
        title: 'Levels',
      },
      fields: {
        id: 'id',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        createdAtRange: 'Created At Range',
        name: 'Level Name',
        level: 'Level',
      },
    },
    Classroom: {
      menu: 'Class Names',
      list: {
        title: 'Class Names',
      },
      fields: {
        id: 'id',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        createdAtRange: 'Created At Range',
        name: 'Class Name',
        level: 'Level',
        level: 'Level',
        matter: 'Matter',
      },
    },
    ClassroomTeacherMatter: {
      menu: 'Class - Teacher - Matter',
      list: {
        title: 'Class - Teacher - Matter',
      },
      fields: {
        id: 'id',
        created_at: 'Created At',
        updated_at: 'Updated At',
        createdAtRange: 'Created At Range',
        class: 'Class',
        level: 'Level',
        matter: 'Matter',
        teachers: 'Teacher',
      },
    },
    Roomsession: {
      menu: 'Room Sessions Confuguration',
      list: {
        title: 'Room Sessions Confuguration (Classroom)',
      },
      live: {
        openRoom: {
          success: 'Join Classroom',
          error: 'Teacher not Join Yet ',
        },
      },
      fields: {
        id: 'id',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        createdAtRange: 'Created At Range',
        RoomName: 'Room Name',
        classroomStudent: 'Classroom - Student',
        days: 'Day',
        start_time: 'Start Time',
        end_time: 'End Time',
        class: 'Class',
        level: 'Level',
        matter: 'Matter',
        teacher: 'Teacher',
      },
    },

    Departments: {
      menu: 'Departments',
      list: {
        title: 'School Departments',
      },
      fields: {
        id: 'id',
        created_at: 'Created At',
        updated_at: 'Updated At',
        createdAtRange: 'Created At Range',
        name: 'Department Name',
      },
    },
    CourseStudents: {
      menu: 'cours Etudiant',
      list: {
        title: 'cours Etudiant',
      },
      fields: {
        id: 'id',
        created_at: 'Created At',
        updated_at: 'Updated At',
        createdAtRange: 'Created At Range',
        student: "nom de l\'étudiant",
        cycle: 'Cycle',
        matter: 'Matière',
        classroom: 'Nom de la classe',
        teacher: 'Enseignant',
      },
    },
  },

  roles: {
    user: {
      label: 'user',
      description: 'Full access to all resources',
    },
    editor: {
      label: 'Editor',
      description: 'Edit access to all resources',
    },
    viewer: {
      label: 'Viewer',
      description: 'View access to all resources',
    },
  },

  user: {
    fields: {
      id: 'Id',
      authenticationUid: 'Authentication Uid',
      avatars_url: 'Avatar',
      email: 'Email',
      emails: 'Email(s)',
      fullName: 'Name',
      name: 'Username',

      first_name: 'First Name',
      last_name: 'Last Name',
      status: 'Status',
      disabled: 'Disabled',
      phoneNumber: 'Phone Number',
      role: 'Role',
      created_at: 'Created at',
      updatedAt: 'Updated at',
      roleUser: 'Role/User',
      roles: 'Roles',
      createdAtRange: 'Created at',
      password: 'Password',
      confirmPassword: 'Confirm password',

      newpassword: 'New Password',

      confirm_password: 'Confirm Password',
      rememberMe: 'Remember me',
      language: 'language',
      timezone: 'timezone',
      placeholder: {
        timezone: 'Select a timzone',
      },
    },
    enabled: 'Enabled',
    disabled: 'Disabled',
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} is invalid',
    },
  },

  auditLog: {
    menu: 'Audit Logs',
    title: 'Audit Logs',
    exporterFileName: 'audit_log_export',
    entityNamesHint:
      'Separate multiple entities using the comma character.',
    fields: {
      id: 'Id',
      timestampRange: 'Period',
      entityName: 'Entity',
      entityNames: 'Entities',
      entityId: 'Entity ID',
      action: 'Action',
      values: 'Values',
      timestamp: 'Date',
      createdByEmail: 'User Email',
    },
  },
  settings: {
    title: 'Settings',
    menu: 'Settings',
    save: {
      success:
        'Settings saved successfully. The page will reload in {0} seconds for changes to take effect.',
    },
    fields: {
      theme: 'Theme',
    },
    colors: {
      default: 'Default',
      cyan: 'Cyan',
      'geek-blue': 'Geek Blue',
      gold: 'Gold',
      lime: 'Lime',
      magenta: 'Magenta',
      orange: 'Orange',
      'polar-green': 'Polar Green',
      purple: 'Purple',
      red: 'Red',
      volcano: 'Volcano',
      yellow: 'Yellow',
    },
  },
  home: {
    menu: 'Accueil',
    message: `Cette page utilise de fausses données à des fins de démonstration uniquement. Vous pouvez la modifier à l'adresse frontend/view/home/HomePage.js.`,
    charts: {
      day: 'Journée',
      red: 'Rouge',
      green: 'Vert',
      yellow: 'Jaune',
      grey: 'Gris',
      blue: 'Bleu',
      sales: 'Ventes',
      visitor: 'Visiteurs',
      months: {
        1: 'Janvier',
        2: 'Février',
        3: 'Mars',
        4: 'Avril',
        5: 'Mai',
        6: 'Juin',
        7: 'Juillet',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
    },
  },
  live: {
    menu: 'Live',
  },

  errors: {
    backToHome: 'Back to home',
    403: `Sorry, you don't have access to this page`,
    404: 'Sorry, the page you visited does not exist',
    500: 'Sorry, the server is reporting an error',
    forbidden: {
      message: 'Forbidden',
    },
    validation: {
      message: 'An error occurred',
    },
    defaultErrorMessage: 'Ops, an error occurred',
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is required',
      oneOf:
        '${path} must be one of the following values: ${values}',
      notOneOf:
        '${path} must not be one of the following values: ${values}',
      notType: ({ path, type, value, originalValue }) => {
        return `${path} must be a ${type}`;
      },
    },
    string: {
      length:
        '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches:
        '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected',
    },
    number: {
      min:
        '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer',
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}',
    },
    boolean: {},
    object: {
      noUnknown:
        '${path} field cannot have keys not specified in the object shape',
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max:
        '${path} field must have less than or equal to ${max} items',
    },
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Invalid format. Must be '{0}'.`,
  },
  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: `{0} impo removed successfully',
  },
  roles: {
    label: 'Roles',
    doRemoveAllSelectedSuccess:
      rted, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage:
      'Do not navigate away from this page or import will be stopped.',
    completed: {
      success:
        'Import completed. All rows were successfully imported.',
      someErrors:
        'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.',
    },
    form: {
      downloadTemplate: 'Download the template',
      hint:
        'Click or drag the file to this area to continue',
    },
    list: {
      discardConfirm:
        'Are you sure? Non-imported data will be lost.',
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel:
        'Only excel (.xlsx) files are allowed',
      invalidFileUpload:
        'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported',
    },
  },

  autocomplete: {
    loading: 'Loading...',
  },

  imagesViewer: {
    noImage: 'No image',
  },
};

const fr_en = {
  common: {
    or: 'ou',
    cancel: 'Annuler',
    reset: 'Réinitialiser',
    save: 'Sauvegarder',
    search: 'Recherche',
    edit: 'Modifier',
    editing: 'M',
    publish: 'Publier',
    closing: 'Clôturer',
    duplicate: 'Dupliquer',
    archive: 'Archiver',
    cancelPub: 'Annuler la publication',
    remove: 'Supprimer',
    new: 'Nouveau',
    export: 'Exporter en Excel',
    noDataToExport: 'Pas de données à exporter',
    import: 'Importer',
    discard: 'Jetez',
    yes: 'Oui',
    no: 'Non',
    pause: 'Pause',
    areYouSure: 'Êtes-vous sûr ?',
    view: 'Consulter',
    viewing: 'C',
    destroy: 'Supprimer',
    destroying: 'S',
    mustSelectARow: 'Vous devez sélectionner une ligne',
  },
  dictionary: {
    day: [
      'Dimanche',
      'Lundi',
      'Mardi',
      'Mercredi',
      'Jeudi',
      'Vendredi',
      'Samedi',
    ],
  },
  //App_Title
  app: {
    // title: 'Fadaa Al Ouloume',
    title: 'Ecole Taiba',
  },

  auth: {
    accountSeting: {
      title: 'Langue & Fuseau horaire',
    },
    menu: 'Paramétrage',
    profile: {
      title: 'Modifier le profil',
      success: 'Profil mis à jour avec succès',
    },
    editpassword: {
      title: 'Modifier le mot de passe',
      success: 'Mot de passe mis à jour avec succès',
      error: 'Mot de passe incorrect',
      errornewpassword:
        'Les mots de passes ne sont pas identiques',
    },
    createAnAccount: 'Créer un compte',
    rememberMe: 'Souvenez-vous de moi.',
    forgotPassword: 'Mot de passe oublié',
    signin: "S'identifier",
    signup: "S'inscrire",
    signout: 'Se déconnecter',
    alreadyUsed: 'Cette adresse email est déjà utilisée',

    alreadyHaveAnAccount:
      'Vous avez déjà un compte ? Connectez-vous.',
    signinWithAnotherAccount:
      'Vous avez déjà un compte ? Connectez-vous.',
    emailUnverified: {
      message: `Veuillez confirmer votre courriel à <strong>{0}</strong> pour continuer.`,
      submit: `Renvoyer la vérification de l'email`,
    },
    emptyPermissions: {
      message: `Vous n'avez pas encore d'autorisations. Attendez que l'administrateur vous accorde des privilèges`,
    },
    passwordReset: {
      message:
        'Envoyer un courriel de réinitialisation du mot de passe',
      message1: 'Renvoyer le courriel',
      error: `Courriel non reconnu`,
    },
    emailAddressVerificationEmail: {
      error: `Courriel non reconnu`,
    },
    verificationEmailSuccess: `Courriel de vérification envoyé avec succès`,
    alreadyActive: 'Le compte est deja activé',
    passwordResetSuccess: `Courriel de réinitialisation du mot de passe envoyé avec succès`,
  },

  liveButtons: {
    chat: {
      title: 'Chat de la Classe',
    },
  },

  Home: {
    Classe: 'Classe',
    Classes: 'Liste des Classes',
    menu: {
      Cours: 'Cours',
      Classes: 'Classes',
      records: 'Enregistrements',
      Access: 'Accéder',
      Meetings: 'Réunions',
      Statistics: 'Statistiques',
    },
  },

  fields: {
    StudentClassroom: 'Classe',
    studentName: 'Nom de l\'enfant',
    transport: 'Transport ?',
    online: 'En ligne ?',
  },

  entities: {
    StudentStatus: {
      list: {
        title: 'Status de mes étudiants',
      },
      menu: 'Status Etudiants',
    },
    CourseCreation: {
      menu: 'Création des cours',
    },
    CourseStudents: {
      menu: 'Cours',
      fields: {
        id: 'id',
        created_at: 'Created At',
        updated_at: 'Updated At',
        createdAtRange: 'Created At Range',
        student: "nom de l\'étudiant",
        cycle: 'Cycle',
        matter: 'Matière',
        classroom: 'Nom de la classe',
        teacher: 'Enseignant',
      },
    },
    LevelSector: {
      list: {
        title: 'Affectation niveaux filière',
      },
      menu: 'Affectation niveaux filière',
      edit: {
        title: 'Modifier affectation',
      },
      new: {
        title: 'Ajout affectation',
      },
      view: {
        title: 'Niveau Filière',
      },
      save: {
        success: 'Affec NF éffectué avec succès',
      },
      destroy: {
        success: 'Affec NF supprimé avec succès',
      },
      create: {
        success: 'Affec NF créé avec succès',
      },
      update: {
        success: 'Affec NF modifiè avec succès',
      },
      exporter: 'Affectation Niveau Filière',
      fields: {
        id: 'id',
        sector: 'Filière',
        level: 'Niveau',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        cycle: 'Cycle',
      },
    },
    Assignments: {
      menu: 'Insertion des cours',
      list: {
        title: 'Espace des Cours',
      },
      view: {
        title: 'Cours',
      },
      edit: {
        title: 'Modification Cours',
      },
      new: {
        title: 'Ajouter Cours',
      },
      save: {
        success: 'Assignments éffectué avec succès',
      },
      destroy: {
        success: 'Assignments supprimé avec succès',
      },
      create: {
        success: 'Assignments créé avec succès',
      },
      update: {
        success: 'Assignments modifiè avec succès',
      },
      exporter: 'Assignments',
      enumerators: {
        type_course: {
          Standard: 'Fichier',
          Editable: 'éditeur de contenu',
          External_Link: 'Lien de video éxterne',
        },
      },
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        assignmentsName: "Nom d'assignement",
        name: 'Nom',
        classroom: 'Classe',
        matter: 'Matière',
        level: 'Niveau',
        cycle: 'Cycle',
        sector: 'Filière',
        element: 'Elément',
        types_assignment: 'Type de Cours',
        type_course: 'Type De cours',
        file: 'Fichier',
      },
    },
    RoomURL: {
      menu: 'Lien du Roomession',
      list: {
        title: 'Lien du Roomession',
      },
      view: {
        title: 'Lien du Roomession',
      },
      edit: {
        title: 'Modification Du Lien du Roomession',
      },
      new: {
        title: 'Ajouter Du Lien du Roomession',
      },
      save: {
        success: 'URL éffectué avec succès',
      },
      destroy: {
        success: 'URL supprimé avec succès',
      },
      create: {
        success: 'URL créé avec succès',
      },
      update: {
        success: 'URL modifiè avec succès',
      },
      exporter: 'teacher_room_url',
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        url: 'lien url',
      },
    },
    Matter: {
      menu: 'matières',
      list: {
        title: 'matière',
      },
      view: {
        title: 'Matière',
      },
      edit: {
        title: 'Modifier Matière',
      },
      new: {
        title: 'Ajout Matière',
      },
      save: {
        success: 'matière éffectué avec succès',
      },
      destroy: {
        success: 'matière supprimé avec succès',
      },
      create: {
        success: 'matière créé avec succès',
      },
      update: {
        success: 'matière modifiè avec succès',
      },
      exporter: 'Assignments',
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        name: 'Nom de matière',
        subname: 'Matière',
        createdAtRange: 'Période',
      },
    },
    Teachers: {
      menu: 'enseignants',
      list: {
        title: 'Enseignants',
      },
      edit: {
        title: 'Modifier enseignant',
      },
      new: {
        title: 'Ajout enseignant',
      },
      view: {
        title: 'Enseignant',
      },
      save: {
        success: 'enseignant éffectué avec succès',
      },
      destroy: {
        success: 'enseignant supprimé avec succès',
      },
      create: {
        success: 'enseignant créé avec succès',
      },
      update: {
        success: 'enseignant modifiè avec succès',
      },
      exporter: 'enseignant',
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        name: "Nom d'utilisateur",
        first_name: 'Prénom',
        last_name: 'Nom',
        email: 'E-mail',
        phone: 'Téléphone',
      },
    },
    Level: {
      menu: 'Niveaux',
      list: {
        title: "Niveaux de l'école",
      },
      edit: {
        title: 'Modifier niveau',
      },
      new: {
        title: 'Ajout niveau',
      },
      view: {
        title: 'Niveau',
      },
      save: {
        success: 'niveau éffectué avec succès',
      },
      destroy: {
        success: 'niveau supprimé avec succès',
      },
      create: {
        success: 'niveau créé avec succès',
      },
      update: {
        success: 'niveau modifiè avec succès',
      },
      exporter: 'niveau',
      fields: {
        id: 'id',
        name: 'Nom de niveaux',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
      },
    },
    Students: {
      menu: 'Etudiants',
      list: {
        title: "Etudiants de l'école",
      },
      edit: {
        title: 'Modifier étudiant',
      },
      new: {
        title: 'Ajout étudiant',
      },
      view: {
        title: 'étudiant',
      },
      save: {
        success: 'étudiant éffectué avec succès',
      },
      destroy: {
        success: 'étudiant supprimé avec succès',
      },
      create: {
        success: 'étudiant créé avec succès',
      },
      update: {
        success: 'étudiant modifiè avec succès',
      },
      exporter: 'étudiant',
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        name: "Nom d'utilisateur",
        first_name: 'Prénom',
        last_name: 'Nom',
        email: 'E-mail',
        phone: 'Téléphone',
        code_massar: 'Code Massar',
        father: 'Père',
        mother: 'Mère',
        tutor1: 'Tuteur 1',
        tutor2: 'Tuteur 2',
        quality_tutor1: 'Qualité Tuteur 1',
        quality_tutor2: 'Qualité Tuteur 2',
      },
    },
    Responsibles: {
      menu: 'Responsables',
      list: {
        title: "Responsables de l'école",
      },
      edit: {
        title: 'Modifier responsable',
      },
      new: {
        title: 'Ajout responsable',
      },
      view: {
        title: 'Responsable',
      },
      save: {
        success: 'responsable éffectué avec succès',
      },
      destroy: {
        success: 'responsable supprimé avec succès',
      },
      create: {
        success: 'responsable créé avec succès',
      },
      update: {
        success: 'responsable modifiè avec succès',
      },
      exporter: 'responsable',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        Username: "Nom d'utilisateur",
        first_name: 'Prénom',
        last_name: 'Nom',
        email: 'E-mail',
        phone: 'Téléphone',
      },
    },
    Classroom: {
      menu: 'Noms des Classes',
      list: {
        title: 'Noms des Classes',
      },
      edit: {
        title: 'Modifier classe',
      },
      new: {
        title: 'Ajout classe',
      },
      view: {
        title: 'Classe',
      },
      save: {
        success: 'classe éffectué avec succès',
      },
      destroy: {
        success: 'classe supprimé avec succès',
      },
      create: {
        success: 'classe créé avec succès',
      },
      update: {
        success: 'classe modifiè avec succès',
      },
      exporter: 'classe',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        name: 'Nom de la classe',
        subname: 'Classe',
        level: 'Niveau',
        matter: 'Matière',
        cycle: 'Cycle',
        sector: 'Filière',
      },
    },
    FramerMatterCycle: {
      menu: 'Encadrant - Cycle - Matière',
      list: {
        title: 'Affectation Encadrant - Cycle - Matières',
      },
      edit: {
        title: 'Modifier Affectation ECM',
      },
      new: {
        title: 'Ajout Affectation ECM',
      },
      view: {
        title: 'Encadrant - Cycle - Matière',
      },
      save: {
        success: 'Affec ECM éffectué avec succès',
      },
      destroy: {
        success: 'Affec ECM supprimé avec succès',
      },
      create: {
        success: 'Affec ECM créé avec succès',
      },
      update: {
        success: 'Affec ECM modifiè avec succès',
      },
      exporter: 'Affec ECM',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        cycle: 'Cycle',
        level: 'Niveau',
        matter: 'Matière',
        framer: 'Encadrant',
      },
    },
    ClassroomTeacherMatter: {
      menu: 'Classe - Enseignant - Matière',
      list: {
        title:
          'Affectation classes - enseignants - matières',
      },
      edit: {
        title: 'Modifier Affectation CEM',
      },
      new: {
        title: 'Ajout Affectation CEM',
      },
      view: {
        title: 'Classe - Enseignant - Matière',
      },
      save: {
        success: 'Affec CEM éffectué avec succès',
      },
      destroy: {
        success: 'Affec CEM supprimé avec succès',
      },
      create: {
        success: 'Affec CEM créé avec succès',
      },
      update: {
        success: 'Affec CEM modifiè avec succès',
      },
      exporter: 'Affec CEM',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        class: 'Classe',
        level: 'Niveau',
        matter: 'Matière',
        teachers: 'Enseignants',
      },
    },
    Roomsession: {
      menu: 'Emploi Du Temps',
      live: {
        openRoom: {
          success: 'Joindre le cours',
          error: "L'ensegnant n'a pas encore rejoint",
          errorTeacher:
            "vous n'avez aucune seance a ce moment",
        },
      },
      list: {
        title: 'Emploi Du Temps',
      },
      edit: {
        title: 'Modifier Emploi Du Temps',
      },
      new: {
        title: 'Ajout Emploi Du Temps',
      },
      view: {
        title: 'Emploi Du Temps',
      },
      save: {
        success: 'Emploi Du Temps éffectué avec succès',
      },
      destroy: {
        success: 'Emploi Du Temps supprimé avec succès',
      },
      create: {
        success: 'Emploi Du Temps créé avec succès',
      },
      update: {
        success: 'Emploi Du Temps modifiè avec succès',
      },
      exporter: 'Emploi Du Temps',
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        name: 'Nom de la session',
        classroomStudent: 'Classe - Etudiant',
        day: 'Jour',
        start_time: 'Heure de début',
        end_time: 'Heure de fin',
        classroom: 'Classe',
        level: 'Niveau',
        matter: 'Matière',
        teacher: 'Enseignants',
        cycle: 'Cycle',
        sector: 'Filière',
        element: 'Element de matière',
        schoolYear: 'Année scolaire',
        week: 'Semaine',
      },
      enumerators: {
        day: {
          Sunday: 'Dimanche',
          Monday: 'Lundi',
          Tuesday: 'Mardi',
          Wednesday: 'Mercredi',
          Thursday: 'Jeudi',
          Friday: 'Vendredi',
          Saturday: 'Samedi',
        },
        quality_tutor: {
          friend: 'Ami',
          brother: 'Frère',
          grand_father: 'Grand Père',
          uncle: 'Oncle',
          sister: 'Soeur',
          aunt: 'Tante',
        },
      },
    },

    Framer: {
      menu: 'Encadrant',
      list: {
        title: "Encadrants de l'école",
      },
      edit: {
        title: 'Modifier Encadrant',
      },
      new: {
        title: 'Ajout Encadrant',
      },
      view: {
        title: 'Encadrant',
      },
      save: {
        success: 'Encadrant éffectué avec succès',
      },
      destroy: {
        success: 'Encadrant supprimé avec succès',
      },
      create: {
        success: 'Encadrant créé avec succès',
      },
      update: {
        success: 'Encadrant modifiè avec succès',
      },
      exporter: 'Encadrant',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        Username: "Nom d'utilisateur",
        first_name: 'Prénom',
        last_name: 'Nom',
        email: 'E-mail',
        phone: 'Téléphone',
      },
    },
    Cycle: {
      menu: 'Cycles',
      list: {
        title: "Cycles de l'école",
      },
      edit: {
        title: 'Modifier Cycle',
      },
      new: {
        title: 'Ajout Cycle',
      },
      view: {
        title: 'Cycle',
      },
      save: {
        success: 'Cycle éffectué avec succès',
      },
      destroy: {
        success: 'Cycle supprimé avec succès',
      },
      create: {
        success: 'Cycle créé avec succès',
      },
      update: {
        success: 'Cycle modifiè avec succès',
      },
      exporter: 'Cycle',
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        name: 'Nom du cycle',
      },
    },
    Sector: {
      menu: 'Filières',
      list: {
        title: "Filières de l'école",
      },
      edit: {
        title: 'Modifier Filière',
      },
      new: {
        title: 'Ajout Filière',
      },
      view: {
        title: 'Filière',
      },
      save: {
        success: 'Filière éffectué avec succès',
      },
      destroy: {
        success: 'Filière supprimé avec succès',
      },
      create: {
        success: 'Filière créé avec succès',
      },
      update: {
        success: 'Filière modifiè avec succès',
      },
      exporter: 'Filière',
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        name: 'Nom de la filière',
      },
    },
    Week: {
      menu: 'Semaines',
      list: {
        title: "Semaines de l'année",
      },
      edit: {
        title: 'Modifier Semaine',
      },
      new: {
        title: 'Ajout Semaine',
      },
      view: {
        title: 'Semaines',
      },
      save: {
        success: 'Semaine éffectué avec succès',
      },
      destroy: {
        success: 'Semaine supprimé avec succès',
      },
      create: {
        success: 'Semaine créé avec succès',
      },
      update: {
        success: 'Semaine modifiè avec succès',
      },
      exporter: 'Semaine',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        name: 'Nom de la Semaine',
        schoolYear: 'Année scolaire',
        end_date: 'Date fin',
        start_date: 'Date debut',
      },
    },
    timeTable: {
      menu: 'Emploi du temps',
      list: {
        title: 'Emploi du temps',
      },
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        classroom: 'Classe',
        matter: 'Matière',
        element: 'Element',
        week: 'week',
        cycle: 'cycle',
        level: 'level',
        sector: 'sector',
        student: 'Etudiant',
        school_year: "l'année scolaire",
        day: ' jour',
        teache_name: 'ensegnant',
      },
    },
    Element: {
      menu: 'Elements de matières',
      list: {
        title: 'Elements de matières',
      },
      edit: {
        title: 'Modifier Element',
      },
      new: {
        title: 'Ajout Element',
      },
      view: {
        title: 'Element de Matière',
      },
      save: {
        success: 'Element éffectué avec succès',
      },
      destroy: {
        success: 'Element supprimé avec succès',
      },
      create: {
        success: 'Element créé avec succès',
      },
      update: {
        success: 'Element modifiè avec succès',
      },
      exporter: 'Element',
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        name: "Nom de l'element",
        matter: 'Matière',
        sector: 'Filière',
        cycle: 'Cycle',
        level: 'Niveau',
      },
    },
    SchoolYear: {
      menu: 'Années Scolaires',
      list: {
        title: 'Années Scolaires',
      },
      edit: {
        title: 'Modifier Année Scolaire',
      },
      new: {
        title: 'Ajout Année Scolaire',
      },
      view: {
        title: 'Année Scolaire',
      },
      save: {
        success: 'Année Scolaire éffectué avec succès',
      },
      destroy: {
        success: 'Année Scolaire supprimé avec succès',
      },
      create: {
        success: 'Année Scolaire créé avec succès',
      },
      update: {
        success: 'Année Scolaire modifiè avec succès',
      },
      exporter: 'Année Scolaire',
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        name: "Nom de l'année scolaire",
      },
    },
    Register: {
      menu: 'Inscription des éléves',
      list: {
        title: 'Inscription des éléves',
      },
      edit: {
        title: 'Modifier Inscription',
      },
      new: {
        title: 'Ajout Nouveau Inscrit',
      },
      view: {
        title: 'Inscrit',
      },
      save: {
        success: 'Inscription éffectué avec succès',
      },
      destroy: {
        success: 'Inscription supprimé avec succès',
      },
      create: {
        success: 'Inscription créé avec succès',
      },
      update: {
        success: 'Inscription modifiè avec succès',
      },
      exporter: 'Inscription',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        schoolYear: "Nom de l'année scolaire",
        cycle: 'Cycle',
        level: 'Niveau',
        sector: 'Filière',
        classroom: 'Classe',
        student: 'Etudiant',
        transport: 'Transport',
        online: 'Online',
      },
    },
    Chat: {
      menu: 'Chat',
      list: {
        title: 'Chat',
      },
      fields: {
        id: 'id',
        createdAt: 'Créer à',
        updatedAt: 'Modifier à',
        createdAtRange: 'Période',
        name: 'Chat',
      },
    },
    EducDirector: {
      menu: 'Directeurs Pédagogiques',
      list: {
        title: "Directeurs Pédagogiques de l'école",
      },
      edit: {
        title: 'Modifier Directeur Pédagogique',
      },
      new: {
        title: 'Ajout Directeur Pédagogique',
      },
      view: {
        title: 'Directeur Pédagogique',
      },
      save: {
        success: 'Direc Pédago éffectué avec succès',
      },
      destroy: {
        success: 'Direc Pédago supprimé avec succès',
      },
      create: {
        success: 'Direc Pédago créé avec succès',
      },
      update: {
        success: 'Direc Pédago modifiè avec succès',
      },
      exporter: 'Direc Pédago',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        Username: "Nom d'utilisateur",
        first_name: 'Prénom',
        last_name: 'Nom',
        email: 'E-mail',
        phone: 'Téléphone',
      },
    },
    Parent: {
      menu: 'Parents',
      list: {
        title: 'Parents',
      },
      edit: {
        title: 'Modifier Parent',
      },
      new: {
        title: 'Ajout Parent',
      },
      view: {
        title: 'Parent',
      },
      save: {
        success: 'Parent éffectué avec succès',
      },
      destroy: {
        success: 'Parent supprimé avec succès',
      },
      create: {
        success: 'Parent créé avec succès',
      },
      update: {
        success: 'Parent modifiè avec succès',
      },
      exporter: 'Parent',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        Username: "Nom d'utilisateur",
        first_name: 'Prénom',
        last_name: 'Nom',
        email: 'E-mail',
        phone: 'Téléphone',
      },
    },
    Security: {
      menu: 'Sécurité',
      list: {
        title: 'Sécurité',
      },
      edit: {
        title: 'Modifier Sécurité',
      },
      new: {
        title: 'Ajout Sécurité',
      },
      view: {
        title: 'Sécurité',
      },
      save: {
        success: 'Sécurité éffectué avec succès',
      },
      destroy: {
        success: 'Sécurité supprimé avec succès',
      },
      create: {
        success: 'Sécurité créé avec succès',
      },
      update: {
        success: 'Sécurité modifiè avec succès',
      },
      exporter: 'Sécurité',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        Username: "Nom d'utilisateur",
        first_name: 'Prénom',
        last_name: 'Nom',
        email: 'E-mail',
        phone: 'Téléphone',
      },
    },
    ResponsibleCycle: {
      menu: 'Responsables - Cycles',
      list: {
        title: 'Affectation Responsables - Cycles',
      },
      edit: {
        title: 'Modifier RC',
      },
      new: {
        title: 'Ajout RC',
      },
      view: {
        title: 'Affectation Responsable - Cycle',
      },
      save: {
        success: 'affec RC éffectué avec succès',
      },
      destroy: {
        success: 'affec RC supprimé avec succès',
      },
      create: {
        success: 'affec RC créé avec succès',
      },
      update: {
        success: 'affec RC modifiè avec succès',
      },
      exporter: 'affectation Responsables Cycles',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        responsible: 'Responsable',
        cycle: 'Cycle',
      },
    },
    EducDirectorCycle: {
      menu: 'Direct. Pédag. - Cycles',
      list: {
        title:
          'Affectation Directeurs Pédagogiques - Cycles',
      },
      edit: {
        title: 'Modifier DC',
      },
      new: {
        title: 'Ajout DC',
      },
      view: {
        title: 'Affectation Directeur Pédagogique - Cycle',
      },
      save: {
        success: 'affec DC éffectué avec succès',
      },
      destroy: {
        success: 'affec DC supprimé avec succès',
      },
      create: {
        success: 'affec DC créé avec succès',
      },
      update: {
        success: 'affec DC modifiè avec succès',
      },
      exporter: 'affectation Directeur pedagogique Cycles',
      fields: {
        id: 'id',
        created_at: 'Créer à',
        updated_at: 'Modifier à',
        createdAtRange: 'Période',
        educDirector: 'Directeur Pédagogique',
        cycle: 'Cycle',
      },
    },
  },

  roles: {
    user: {
      label: 'utilisateur',
      description: 'Accès complet à toutes les ressources',
    },
    editor: {
      label: 'Rédacteur en chef',
      description:
        "Modifier l'accès à toutes les ressources",
    },
    viewer: {
      label: 'Viewer',
      description: 'View access to all resources',
    },
  },

  user: {
    fields: {
      id: 'Id',
      authenticationUid: 'Authentication Uid',
      avatars_url: 'Avatar',
      email: 'Courriel',
      emails: 'Courriel(s)',
      fullName: 'Nom',
      name: "Nom d'utilisateur",

      first_name: 'Prénom',
      last_name: 'Nom',
      status: 'Statut',
      disabled: 'Desactivé ?',
      phoneNumber: 'Téléphone',
      role: 'Rôle',
      created_at: 'Crée le',
      updatedAt: 'Mise à jour le',
      roleUser: 'Rôle utilisateur',
      roles: 'Rôles',
      createdAtRange: 'Crée le',
      password: 'Mot de passe',
      newpassword: 'Nouveau Mot de passe',
      confirmPassword: 'Confirmation de mot de passe',
      confirm_password: 'Confirmation de mot de passe',

      rememberMe: 'Se souvenir de moi',
      language: 'Langue',
      timezone: 'Fuseau horaire',
      placeholder: {
        timezone: 'Sélectionnez un fuseau horaire',
      },
    },
    enabled: 'Activé ?',
    disabled: 'Désactivé ?',
    validations: {
      // eslint-disable-next-line
      email: 'Adresse mail ${value} invalide',
    },
  },

  auditLog: {
    menu: 'Audit Logs',
    title: 'Audit Logs',
    exporterFileName: 'audit_log_export',
    entityNamesHint:
      'Separate multiple entities using the comma character.',
    fields: {
      id: 'Id',
      timestampRange: 'Period',
      entityName: 'Entity',
      entityNames: 'Entities',
      entityId: 'Entity ID',
      action: 'Action',
      values: 'Values',
      timestamp: 'Date',
      createdByEmail: 'User Email',
    },
  },

  settings: {
    title: 'Réglages',
    menu: 'Réglages',
    save: {
      success:
        'Réglages enregistrés avec succès. La page se rechargera dans {0} secondes pour que les changements prennent effet.',
    },
    fields: {
      theme: 'Theme',
    },
    colors: {
      default: 'Default',
      cyan: 'Cyan',
      'geek-blue': 'Geek Blue',
      gold: 'Gold',
      lime: 'Lime',
      magenta: 'Magenta',
      orange: 'Orange',
      'polar-green': 'Polar Green',
      purple: 'Purple',
      red: 'Red',
      volcano: 'Volcano',
      yellow: 'Yellow',
    },
  },

  home: {
    menu: 'Accueil',
    message: `Cette page utilise de fausses données à des fins de démonstration uniquement. Vous pouvez la modifier à l'adresse frontend/view/home/HomePage.js.`,
    charts: {
      day: 'Journée',
      red: 'Rouge',
      green: 'Vert',
      yellow: 'Jaune',
      grey: 'Gris',
      blue: 'Bleu',
      sales: 'Ventes',
      visitor: 'Visiteurs',
      months: {
        1: 'Janvier',
        2: 'Février',
        3: 'Mars',
        4: 'Avril',
        5: 'Mai',
        6: 'Juin',
        7: 'Juillet',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
    },
  },

  live: {
    menu: 'Live',
  },

  errors: {
    backToHome: 'Retour à l Accueil',
    403: `Désolé, vous n'avez pas accès à cette page`,
    404: "Désolé, la page que vous avez visitée n'existe pas",
    500: 'Désolé, le serveur signale une erreur',
    forbidden: {
      message: 'Interdit',
    },
    validation: {
      message: 'Une erreur est survenue',
    },
    defaultErrorMessage: 'Ops, Une erreur est survenue',
  },

  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} est invalide',
      required: '${path} est exigé',
      oneOf:
        "${path} doit être l'une des valeurs suivantes :  ",
      notOneOf:
        "${path} ne doit pas être l'une des valeurs suivantes : ${values}",
      notType: ({ path, type, value, originalValue }) => {
        return `${path} doit être un ${type}`;
      },
    },
    string: {
      length:
        '${path} doivent être exactement des caractères ',
      min: '${path} doit être au moins ${min} characters',
      max: '${path} doit être au maximum ${max} characters',
      matches:
        '${path} doit correspondre à ce qui suit : "${regex}"',
      email: '${path} doit être un email valide',
      url: '${path} doit être une URL valide',
      trim: '${path} doit être une corde coupée',
      lowercase:
        '${path} doit être une chaîne en minuscules',
      uppercase:
        '${path} doit être une chaîne en majuscules',
      selected: '${path} doit être sélectionné',
    },
    number: {
      min: '${path} doit être supérieure ou égale à ',
      max: '${path} doit être inférieure ou égale à ${max}',
      lessThan: '${path} doit être inférieur à ${less}',
      moreThan: '${path} doit être supérieure à ${more}',
      notEqual:
        '${path} ne doit pas être égal à ${notEqual}',
      positive: '${path} doit être un nombre positif',
      negative: '${path} doit être un nombre négatif',
      integer: '${path} doit être un nombre entier',
    },
    date: {
      min: '${path} Le champ doit être postérieur à ${min}',
      max:
        '${path}  le champ doit être à une date antérieure à ${max}',
    },
    boolean: {},
    object: {
      noUnknown:
        "${path} Le champ ne peut pas avoir de clés non spécifiées dans la forme de l'objet",
    },
    array: {
      min:
        '${path} Le champ doit avoir au moins ${min} items',
      max:
        "${path} Le champ doit avoir un nombre d'éléments inférieur ou égal à ${max}.",
    },
  },

  /* eslint-disable */
  fileUploader: {
    upload: 'Télécharger',
    image: 'Vous devez télécharger une image',
    size:
      'Le dossier est trop gros. La taille maximale autorisée est {0}',
    formats: `Format non valide. Doit être '{0}'.`,
  },

  importer: {
    line: 'Ligne',
    status: 'Statut',
    pending: 'En attente',
    imported: 'Imported',
    error: 'Erreur',
    total: `{0} impo removed successfully',
  },
  roles: {
    label: 'Roles',
    doRemoveAllSelectedSuccess:
      rted, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage:
      'Do not navigate away from this page or import will be stopped.',
    completed: {
      success:
        'Import completed. All rows were successfully imported.',
      someErrors:
        'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.',
    },
    form: {
      downloadTemplate: 'Download the template',
      hint:
        'Click or drag the file to this area to continue',
    },
    list: {
      discardConfirm:
        'Are you sure? Non-imported data will be lost.',
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel:
        'Only excel (.xlsx) files are allowed',
      invalidFileUpload:
        'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported',
    },
  },

  autocomplete: {
    loading: 'Loading...',
  },

  imagesViewer: {
    noImage: 'No image',
  },
};

export default fr_en;
