import destroyActions from 'modules/shared/destroy/destroyActions';
import listActions from 'modules/gradesManager/list/gradesManagerListActions';
import GradesManagerService from 'modules/gradesManager/gradesManagerService';

const prefix = 'GradesManager_DESTROY';

export default destroyActions({
  prefix,
  destroyAllFn: GradesManagerService.destroyAll,
  destroySuccessMessageI18nKey:
    'entities.GradesManager.destroy.success',
  destroyAllSuccessMessageI18nKey:
    'entities.GradesManager.destroyAll.success',
  redirectTo: '/gradesManager',
  listActions,
});
