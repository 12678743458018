import AssistantService from "modules/assistant/assistantService";
import paginationAction from "modules/shared/pagination/paginationAction";
import selectors from "modules/assistant/list/assistantListSelectors";
import { i18n } from "i18n";
import exporterFields from "modules/assistant/list/assistantListExporterFields";

const prefix = "Assistant_LIST";

export default paginationAction(
  prefix,
  AssistantService.list,
  selectors,
  i18n("entities.Assistant.exporterFileName"),
  exporterFields
);
