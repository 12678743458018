import TransportService from 'modules/transport/transportService';
import paginationAction from 'modules/shared/pagination/paginationAction';
import selectors from 'modules/transport/list/transportListSelectors';
import { i18n } from 'i18n';
import exporterFields from 'modules/transport/list/transportListExporterFields';

const prefix = 'Transport_LIST';

export default paginationAction(
  prefix,
  TransportService.list,
  selectors,
  i18n('entities.Transport.exporterFileName'),
  exporterFields,
);
