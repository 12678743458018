import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';

export default class studentStatusService {
  static async update(id, data) {

    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        update_register(where: {id: {_eq: ${id}}}, _set: {transport: ${data.transport}, online: ${data.online}, canteen: ${data.canteen}}) {
          affected_rows
        }
      }      
      `,
    });

    return response.data.update_register;
  }

  static async list(filter = {}, orderBy, limit, offset) {
    if (
      filter &&
      filter.createdAt &&
      filter.createdAtRange
    ) {
      delete filter.createdAt;
    }

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {


          register_aggregate(where: {
            id: {_eq: ${filter.id || null}}
            id_classroom: {_eq: ${filter.classroom || null}}
            id_student: {_eq: ${
              filter.student ? filter.student.id : null
            }}
            created_at: {_eq:  ${
              filter.created_at
                ? filter.created_at.format()
                : null
            }}
            updated_at: {_eq:  ${
              filter.updated_at
                ? filter.updated_at.format()
                : null
            }}
          }) {
            aggregate {
              count
            }
          }

          register( ${
            limit != 0 ? `limit:${limit},` : ''
          } ${
          offset != 0 ? `offset:${offset} ,` : ''
        } order_by:{${orderBy ? orderBy : ''}}, where: {
          id: {_eq: ${filter.id || null}}
          id_classroom: {_eq: ${filter.classroom || null}}
          id_student: {_eq: ${
            filter.student ? filter.student.id : null
          }}
          created_at: {_eq:  ${
            filter.created_at
              ? filter.created_at.format()
              : null
          }}
          updated_at: {_eq:  ${
            filter.updated_at
              ? filter.updated_at.format()
              : null
          }}
          id_school_year: {_eq: ${
            filter.schoolYear || null
          }}
          classroom: {level_sector: {id_level: {_eq: ${
            filter.level || null
          }}, sector: {id: {_eq: ${
          filter.sector || null
        }}}}}
            }){
                id
                updated_at
                created_at
                transport
                online
                canteen
                classroom {
                  id
                  name
                  level_sector {
                    sector {
                      id
                      name
                    }
                    level {
                      id
                      name
                      cycle {
                        id
                        name
                      }
                    }
                  }
                }
                school_year {
                  id
                  name
                }
                student {
                  id
                  first_name
                  last_name
                  code_massar
                  matr
                }
              }
        }
        `,
      });

    var data = response.data.register;

    var new_Data = data.map((e) => ({
      studentName:
        e.student.first_name + ' ' + e.student.last_name,
      code_massar: e.student.code_massar,
      matr: e.student.matr,
      className: e.classroom.name,

      ...e,
    }));
    new_Data.count =
      response.data.register_aggregate.aggregate.count;
    return new_Data;
  }

  static async ListStudents() {

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {


          register_aggregate {
            aggregate {
              count
            }
          }

          register {
                id
                updated_at
                created_at
                transport
                online
                canteen
                classroom {
                  id
                  name
                  level_sector {
                    sector {
                      id
                      name
                    }
                    level {
                      id
                      name
                      cycle {
                        id
                        name
                      }
                    }
                  }
                }
                school_year {
                  id
                  name
                }
                student {
                  id
                  first_name
                  last_name
                  code_massar
                  matr
                }
              }
        }
        `,
      });

    var data = response.data.register;

    var new_Data = data.map((e) => ({
      studentName:
        e.student.first_name + ' ' + e.student.last_name,
      code_massar: e.student.code_massar,
      matr: e.student.matr,
      className: e.classroom.name,

      ...e,
    }));
    new_Data.count =
      response.data.register_aggregate.aggregate.count;

      // console.log(new_Data);

    return new_Data;
  }


}
