import destroyActions from 'modules/shared/destroy/destroyActions';
import listActions from 'modules/levelSectorExam/list/levelSectorExamListActions';
import LevelSectorExamService from 'modules/levelSectorExam/levelSectorExamService';

const prefix = 'LevelSectorExam_DESTROY';

export default destroyActions({
  prefix,
  destroyAllFn: LevelSectorExamService.destroyAll,
  destroySuccessMessageI18nKey:
    'entities.LevelSectorExam.destroy.success',
  destroyAllSuccessMessageI18nKey:
    'entities.LevelSectorExam.destroyAll.success',
  redirectTo: '/levelSectorExam',
  listActions,
});
