// import StudentStatusService from "modules/studentStatus/studentStatusService";
// import paginationAction from "modules/shared/pagination/paginationAction";
// import selectors from "modules/studentStatus/list/studentStatusListSelectors";
// import { i18n } from "i18n";
// import exporterFields from "modules/studentStatus/list/studentStatusListExporterFields";
// import { getStore } from 'modules/store';
// import Permissions from 'security/permissions';
// import PermissionChecker from 'modules/auth/permissionChecker';
import service from 'modules/studentStatus/studentStatusService';
import { getHistory } from 'modules/store';

const prefix = "StudentStatus";

// export default paginationAction(
//   prefix,
//   StudentStatusService.list,
//   selectors,
//   i18n("entities.StudentStatus.exporterFileName"),
//   exporterFields
// );

const actions = {
  ERROR_MESSAGE_CLEARED: `${prefix}_ERROR_MESSAGE_CLEARED`,

  LIST_StudentStatus_START: `${prefix}LIST_StudentStatus_START`,
  LIST_StudentStatus_SUCCESS: `${prefix}LIST_StudentStatus_SUCCESS`,
  LIST_StudentStatus_ERROR: `${prefix}LIST_StudentStatus_ERROR`,

  doClearErrorMessage() {
    return {
      type: actions.ERROR_MESSAGE_CLEARED,
    };
  },

  doList: () => async (dispatch) => {
    try {
      dispatch({ type: actions.LIST_StudentStatus_START });

      const response = await service.ListStudents();

      dispatch({
        type: actions.LIST_StudentStatus_SUCCESS,
        payload: { 
          rows: response,
          count: response.count
            ? response.count
            : response.length, 
        },
      });
    } catch (error) {
      dispatch({ type: actions.LIST_StudentStatus_ERROR });
      // getHistory().push('/');
    }
  },

  doChangeStatus: (id, flag, choice, data) => async (dispatch) => {
    try {
      dispatch({ type: actions.LIST_StudentStatus_START });

      data.map((e) => {
        if(e.id == id) {
          if(choice == 0) {
            e.transport = flag;
          } else {
            if (choice == 1) {
              e.online = flag;
            } else {
              if (choice == 2) {
                e.canteen = flag;
              }
            }
          }
        }
      });
    
      dispatch({
        type: actions.LIST_StudentStatus_SUCCESS,
        payload: { 
          rows: data,
          count: data.count
            ? data.count
            : data.length,
        },
      });
    } catch (error) {
      dispatch({ type: actions.LIST_StudentStatus_ERROR });
      // getHistory().push('/');
    }
  },

  doUpdate: (data) => async (dispatch) => {
    try {
      dispatch({ type: actions.LIST_StudentStatus_START });

      await data.map( async (item) => {
        await service.update(item.id, item);
      })
      
      window.location.replace("/");

    } catch (error) {
      dispatch({ type: actions.LIST_StudentStatus_ERROR });
    }
  },
};

export default actions;
