import service from 'modules/roomSession/roomSessionService';
import Errors from 'modules/shared/error/errors';
import Message from 'view/shared/message';
import { i18n } from 'i18n';
import { getHistory } from 'modules/store';
import { getStore } from 'modules/store';
import { getLanguageCode } from 'i18n';
import { v4 as uuidv4 } from 'uuid';
var generator = require('generate-password');

const prefix = 'ROOM';

const actions = {
  ERROR_MESSAGE_CLEARED: `${prefix}_ERROR_MESSAGE_CLEARED`,

  ROOM__START: `${prefix}_ROOM_START`,
  ROOM_SUCCESS: `${prefix}_ROOM_SUCCESS`,
  ROOM_ERROR: `${prefix}_ROOM_ERROR`,

  doClearErrorMessage() {
    return {
      type: actions.ERROR_MESSAGE_CLEARED,
    };
  },

  doOpenRoomTeacher: () => async (dispatch) => {
    try {
      dispatch({ type: actions.ROOM__START });
      var roomRequest = await service.getRoom(true);

      if (roomRequest.length < 0) throw ' erreur ';

      var roomName = roomRequest[0].name;
      var password = roomRequest[0].password;

      if (roomName == null && password == null) {
        roomName = uuidv4();
        password = generator.generate({
          length: 10,
          numbers: true,
        });
      }

      let status = await service.CheckRoomStatus();

      console.log(status)

      if ( status.length > 0 && status[0].classroom_teacher_matter.classroom.room_status ) {

        let room = await service.SetRoomActif(
          roomName,
          password,
        );
  
        if (room.length > 0) {
          dispatch({
            type: actions.ROOM_SUCCESS,
            payload: {
              room: room[0],
            },
          });
          Message.success(
            i18n(
              'entities.Roomsession.live.openRoom.success',
            ),
          );
        } else {
          dispatch({ type: actions.ROOM_ERROR });
          Message.error(
            i18n(
              'entities.Roomsession.live.openRoom.errorTeacher',
            ),
          );
          getHistory().push('/');
        }

      } else {
        dispatch({ type: actions.ROOM_ERROR });
          Message.error(
            i18n('entities.Roomsession.live.openRoom.liveNotPermitted.error'),
          );

          getHistory().push('/');
      }
    } catch (error) {
      Message.error(
        i18n(
          'entities.Roomsession.live.openRoom.errorTeacher',
        ),
      );
      dispatch({ type: actions.ROOM_ERROR });
      getHistory().push('/');
    }
  },

  doOpenRoomStudent: () => async (dispatch) => {
    try {
      dispatch({ type: actions.ROOM__START });

      let status = await service.CheckRoomStatus();

      console.log(status)

      if ( status.length > 0 && status[0].classroom_teacher_matter.classroom.room_status ) {

        let room = await service.getRoom();

        if (room.length > 0) {
          dispatch({
            type: actions.ROOM_SUCCESS,
            payload: {
              room: room[0],
            },
          });
          Message.success(
            i18n(
              'entities.Roomsession.live.openRoom.success',
            ),
          );
        } else {
          dispatch({ type: actions.ROOM_ERROR });
          Message.error(
            i18n('entities.Roomsession.live.openRoom.error'),
          );

          getHistory().push('/');
        }
      } else {
        dispatch({ type: actions.ROOM_ERROR });
          Message.error(
            i18n('entities.Roomsession.live.openRoom.liveNotPermitted.error'),
          );

          getHistory().push('/');
      }
    } catch (error) {
      Message.error(
        i18n('entities.Roomsession.live.openRoom.error'),
      );
      dispatch({ type: actions.ROOM_ERROR });
      getHistory().push('/');
    }
  },
};

export default actions;