import model from 'modules/classroomTeacherMatter/classroomTeacherMatterModel';

const { fields } = model;

export default [
  fields.id,
  fields.id_teacher,
  fields.id_matter,
  fields.id_classroom,
  fields.id_school_year,
];
