import destroyActions from 'modules/shared/destroy/destroyActions';
import listActions from 'modules/transport/list/transportListActions';
import TransportService from 'modules/transport/transportService';

const prefix = 'Transport_DESTROY';

export default destroyActions({
  prefix,
  destroyAllFn: TransportService.destroyAll,
  destroySuccessMessageI18nKey:
    'entities.Transport.destroy.success',
  destroyAllSuccessMessageI18nKey:
    'entities.Transport.destroyAll.success',
  redirectTo: '/transport',
  listActions,
});
