import LevelSectorMatterExamService from 'modules/levelSectorMatterExam/levelSectorMatterExamService';
import paginationAction from 'modules/shared/pagination/paginationAction';
import selectors from 'modules/levelSectorMatterExam/list/levelSectorMatterExamListSelectors';
import { i18n } from 'i18n';
import exporterFields from 'modules/levelSectorMatterExam/list/levelSectorMatterExamListExporterFields';

const prefix = 'LevelSectorMatterExam_LIST';

export default paginationAction(
  prefix,
  LevelSectorMatterExamService.list,
  selectors,
  i18n('entities.LevelSectorMatterExam.exporterFileName'),
  exporterFields,
);
