import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/levelSectorElement/importer/levelSectorElementImporterSelectors';
import LevelSectorElementService from 'modules/levelSectorElement/levelSectorElementService';
import fields from 'modules/levelSectorElement/importer/levelSectorElementImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'level_sector_element',
  selectors,
  LevelSectorElementService.import,
  fields,
  i18n('entities.LevelSectorElement.importer.fileName'),
);
