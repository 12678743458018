import { i18n } from 'i18n';
import IdField from 'modules/shared/fields/idField';
import DateTimeField from 'modules/shared/fields/dateTimeField';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField';
import StringField from 'modules/shared/fields/stringField';
import EnumeratorField from 'modules/shared/fields/enumeratorField';
import DecimalRangeField from 'modules/shared/fields/decimalRangeField';
import DecimalField from 'modules/shared/fields/decimalField';
import RelationToOneField from 'modules/shared/fields/relationToOneField';
import FilesField from 'modules/shared/fields/filesField';
import ImagesField from 'modules/shared/fields/imagesField';
import RelationToManyField from 'modules/shared/fields/relationToManyField';
import JsonField from 'modules/shared/fields/jsonField';
import BooleanField from 'modules/shared/fields/booleanField';

function label(name) {
  return i18n(`entities.Register.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(
    `entities.Register.enumerators.${name}.${value}`,
  );
}

const fields = {
  id: new IdField('id', label('id')),
  transport: new EnumeratorField(
    'transport',
    label('transport'),
    [      
      { id: false, label: enumeratorLabel('transport', 'WithTransport') },
      { id: true, label: enumeratorLabel('transport', 'WithoutTransport') },
    ],
    {
      required: true,
    },
  ),
  online: new EnumeratorField(
    'online',
    label('online'),
    [      
      { id: false, label: enumeratorLabel('online', 'Presently') },
      { id: true, label: enumeratorLabel('online', 'Online') },
    ],
    {
      required: true,
    },
  ),
  canteen: new EnumeratorField(
    'canteen',
    label('canteen'),
    [      
      { id: true, label: enumeratorLabel('canteen', 'WithCanteem') },
      { id: false, label: enumeratorLabel('canteen', 'WithoutCanteen') },
    ],
    {
      required: true,
    },
  ),
  schoolYear: new JsonField(
    'schoolYear',
    label('schoolYear'),
    {
      required: true,
    },
  ),
  student: new JsonField('student', label('student'), {
    required: true,
  }),
  classroom: new JsonField(
    'classroom',
    label('classroom'),
    {
      required: true,
    },
  ),
  level: new JsonField('level', label('level'), {
    required: true,
  }),
  sector: new JsonField('sector', label('sector'), {
    required: true,
  }),
  cycle: new JsonField('cycle', label('cycle'), {
    required: true,
  }),
  created_at: new DateTimeField(
    'created_at',
    label('created_at'),
  ),
  updated_at: new DateTimeField(
    'updated_at',
    label('updated_at'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
  studentName: new StringField(
    'studentName',
    "Nom d'etudiant",
  ),
  studentEmail: new StringField(
    'studentEmail',
    "email d'etudiant",
  ),
  studentPhone: new StringField(
    'studentPhone',
    "téléphone d'etudiant",
  ),
  parentName: new StringField('parentName', 'nom parent'),
  parentPhone: new StringField(
    'parentPhone',
    'telephone parent',
  ),
  parentEmail: new StringField(
    'parentEmail',
    'email parent',
  ),
  code_massar: new StringField(
    'code_massar',
    'Code Massar',
  ),
  matr: new StringField('matr', 'Matricule'),
  className: new StringField('className', 'nom de classe'),
};

export default {
  fields,
};
