import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/levelSectorExam/importer/levelSectorExamImporterSelectors';
import LevelSectorExamService from 'modules/levelSectorExam/levelSectorExamService';
import fields from 'modules/levelSectorExam/importer/levelSectorExamImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'level_sector_exam',
  selectors,
  LevelSectorExamService.import,
  fields,
  i18n('entities.LevelSectorExam.importer.fileName'),
);
