import TransportService from 'modules/transport/transportService';
import formActions from 'modules/shared/form/formActions';

const prefix = 'Transport_FORM';

export default formActions({
    prefix,
    createFn: TransportService.create,
    createSuccessMessageI18nKey:
        'entities.Transport.create.success',
    updateFn: TransportService.update,
    updateSuccessMessageI18nKey:
        'entities.Transport.update.success',
    findFn: TransportService.find,
    redirectTo: '/transport',
});