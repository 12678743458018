import destroyActions from "modules/shared/destroy/destroyActions";
import listActions from "modules/assistant/list/assistantListActions";
import AssistantService from "modules/assistant/assistantService";

const prefix = "Assistant_DESTROY";

export default destroyActions({
  prefix,
  destroyAllFn: AssistantService.destroyAll,
  destroySuccessMessageI18nKey: "entities.Assistant.destroy.success",
  destroyAllSuccessMessageI18nKey: "entities.Assistant.destroyAll.success",
  redirectTo: "/assistant",
  listActions,
});
