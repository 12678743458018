import destroyActions from 'modules/shared/destroy/destroyActions';
import listActions from 'modules/exam/list/examListActions';
import ExamService from 'modules/exam/examService';

const prefix = 'MATTER_DESTROY';

export default destroyActions({
  prefix,
  destroyAllFn: ExamService.destroyAll,
  destroySuccessMessageI18nKey:
    'entities.Exam.destroy.success',
  destroyAllSuccessMessageI18nKey:
    'entities.Exam.destroyAll.success',
  redirectTo: '/Exam',
  listActions,
});
