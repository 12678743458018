import destroyActions from 'modules/shared/destroy/destroyActions';
import listActions from 'modules/roomURL/list/roomURLListActions';
import RoomURLService from 'modules/roomURL/roomURLService';

const prefix = 'MATTER_DESTROY';

export default destroyActions({
  prefix,
  destroyAllFn: RoomURLService.destroyAll,
  destroySuccessMessageI18nKey:
    'entities.RoomURL.destroy.success',
  destroyAllSuccessMessageI18nKey:
    'entities.RoomURL.destroyAll.success',
  redirectTo: '/RoomURL',
  listActions,
});
