import viewActions from 'modules/shared/view/viewActions';
import SemesterService from 'modules/semester/semesterService';

const prefix = 'MATTER_VIEW';

export default viewActions({
  prefix,
  findFn: SemesterService.find,
  redirectToOnError: '/Semester',
});
