import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';
import Permissions from 'security/permissions';
import PermissionChecker from 'modules/auth/permissionChecker';
import { getStore } from 'modules/store';
import moment from 'moment';
import momentTz from 'moment-timezone';
import service from 'modules/roomSession/roomSessionService';

export default class RoomURLService {
  static async update(id, data) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        update_teacher_room_url(where: {id: {_eq: ${id}}}, _set: {url: "${data.url}"}) {
          affected_rows
        }
      }
      
      `,
    });

    return response.data.update_teacher_room_url;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        delete_teacher_room_url(where: {id: {_eq: ${ids}}}) {
          affected_rows
        }
      }
      
      `,
    });

    return response.data.delete_teacher_room_url;
  }

  static async create(data) {
    const response2 = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          teachers {
            id
          }
        }
      `,
    });

    var teacherId = response2.data.teachers[0].id;

    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        insert_teacher_room_url(objects: {url: "${data.url}", id_teacher: ${teacherId}}) {
          affected_rows
        }
     }    
      `,
    });

    return response.data.insert_teacher_room_url;
  }

  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        teacher_room_url(where: {id: {_eq: ${id}}}) {
          id
          url
          created_at
          updated_at
        }
      }
      
      `,
    });

    return response.data.teacher_room_url[0];
  }

  static async list() {

    const CurrentUser = await getStore().getState().auth
      .currentUser;
    const permissions = Permissions.values;
    const permissionChecker = new PermissionChecker(
      CurrentUser,
    );
    const date = new Date();
    const Day = date.getDay();

    var current_date_time = await service.get_current_time();
    var currentDate = current_date_time;
    var currentTime = current_date_time.split("T")[1];
    currentTime = currentTime.slice(0, -4)+'1';

    console.log(currentTime);
    console.log(current_date_time)

    if (permissionChecker.match(permissions.HomePage)) {

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
          query MyQuery {
            roomsession(
              where: {
                day: { _eq: ${Day} }
                start_time: { _lte: "${currentTime}" }
                end_time: { _gte: "${currentTime}" }
                week: {
                  start_date: { _lte: "${currentDate}" }
                  end_date: { _gte: "${currentDate}" }
                }
              }
            ) {
              id
              name
              start_time
              end_time
              password
              classroom_teacher_matter {
                matter {
                  name
                }
                teacher {
                  id
                  first_name
                  last_name
                  teacher_room_urls {
                    id
                    url
                    created_at
                    updated_at
                  }
                }
              }
            }
          }
        `,
      });

      var data = response.data.roomsession;

      return data;

    } else {
      const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {
          teacher_room_url_aggregate {
            aggregate {
              count
            }
          }
          teacher_room_url {
            id
            url
            created_at
            updated_at
          }
        }
        `,
      });

      var data = response.data.teacher_room_url;

      var new_Data = data.map((e) => ({
        ...e,
      }));
      new_Data.count =
        response.data.teacher_room_url_aggregate.aggregate.count;
      return new_Data;
    }
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.config().query({
      query: gql`
        query booking_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          bookingAutocomplete(
            query: $query
            limit: $limit
          ) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.RoomURLAutocomplete;
  }

  static async listSelect(limit) {
    const response = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          teacher_room_url {
            id
            url
          }
        }
      `,
    });
    return response.data.teacher_room_url;
  }
}
