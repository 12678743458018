import model from 'modules/levelSectorElement/levelSectorElementModel';

const { fields } = model;

export default [
  fields.id,
  fields.id_level,
  fields.level,
  fields.id_sector,
  fields.sector,
  fields.cycle,
];
