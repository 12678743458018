import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';
import role from 'security/roles';
import authService from 'modules/auth/authService';

export default class LevelSectorService {
  static async update(id, data) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        update_level_sector(where: {id: {_eq: ${id}}}, _set: {id_sector: ${data.sector}, id_level: ${data.level}, code_massar: "${data.codeMassar}"}) {
          affected_rows
        }
      }
      `,
    });

    return response.data.update_level_sector;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        delete_level_sector(where: {id: {_eq: ${ids}}}) {
          affected_rows
        }
      }
      `,
    });

    return response.data.delete_level_sector;
  }

  static async create(data) {

    const response1 = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          level(where: {id: {_eq: ${data.level}}}) {
            id
            name
          }
          sector(where: {id: {_eq: ${data.sector}}}) {
            name
          }
        }
      `,
    });
    var levelName = response1.data.level[0].name;
    var sectorName = response1.data.sector[0].name;

    const response = await graphqlClient.config().mutate({
      mutation: gql`
        mutation MyMutation {
          insert_level_sector(objects: {id_sector: ${data.sector}, id_level: ${data.level}, code_massar: "${data.codeMassar}", name: "${levelName} - ${sectorName}"}) {
            affected_rows
          }
        }
      `,
    });

    return response.data.insert_level_sector;
  }

  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        level_sector(
          where: {id: {_eq: ${id}}}
        ) {
          sector {
            name
            id
          }
          level {
            name
            id
            cycle {
              id
              name
            }
          }
          id
          code_massar
          updated_at
          created_at
        }
      }
      `,
    });

    return response.data.level_sector;
  }

  static async list(filter, orderBy, limit, offset) {
    if (
      filter &&
      filter.created_at &&
      filter.createdAtRange
    ) {
      delete filter.created_at;
    }
    if( orderBy == "codeMassar:asc" ) {
      orderBy = "code_massar:asc"
    }
    if( orderBy == "codeMassar:desc" ) {
      orderBy = "code_massar:desc"
    }
    if (filter == undefined) filter = {};

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {

          level_sector_aggregate(where: {id: ${
            filter.id ? ` {_eq: ${filter.id}}` : '{}'
          },              
            id_sector:
                  ${
                    filter.sector
                      ? `{_eq: ${filter.sector.id}}`
                      : '{}'
                  },
                  id_level:
                  ${
                    filter.level
                      ? `{_eq: ${filter.level.id}}`
                      : '{}'
                  },
                  code_massar:
                  ${
                    filter.codeMassar
                      ? `{_eq: "${filter.codeMassar}"}`
                      : '{}'
                  }
                
                ${
                  filter.created_at
                    ? `,created_at:{_eq:"${filter.created_at.format()}"}`
                    : ''
                },
              
                  updated_at:
              ${
                filter.updated_at
                  ? `{_eq:"${filter.updated_at.format()}"}`
                  : '{}'
              }
              
              ${
                filter.createdAtRange
                  ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
                  : ''
              }
                }) {
            aggregate {
              count
            }
          }

          level_sector( ${
            limit != 0 ? `limit:${limit},` : ''
          } ${
          offset != 0 ? `offset:${offset} ,` : ''
        } order_by:{${
          orderBy ? orderBy : ''
        }}, where: {id: ${
          filter.id ? ` {_eq: ${filter.id}}` : '{}'
        },              
          id_sector:
                ${
                  filter.sector
                    ? `{_eq: ${filter.sector.id}}`
                    : '{}'
                },
              id_level:
              ${
                filter.level
                  ? `{_eq: ${filter.level.id}}`
                  : '{}'
              },
              code_massar:
              ${
                filter.codeMassar
                  ? `{_eq: "${filter.codeMassar}"}`
                  : '{}'
              }
              
              ${
                filter.created_at
                  ? `,created_at:{_eq:"${filter.created_at.format()}"}`
                  : ''
              },
            
                updated_at:
            ${
              filter.updated_at
                ? `{_eq:"${filter.updated_at.format()}"}`
                : '{}'
            }
            
            ${
              filter.createdAtRange
                ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
                : ''
            }
              }) {
                sector {
                  name
                  id
                }
                level {
                  name
                  id
                  cycle {
                    id
                    name
                  }
                }
                id
                code_massar
                id_level
                id_sector
                updated_at
                created_at
              }
            }
        `,
      });

    let data = response.data.level_sector;
    let newData = data.map((item) => ({
      ...item,
      id: item.id,
      sector: item.sector.name,
      level: item.level.name,
      cycle: item.level.cycle.name,
      code_massar: item.code_massar,
    }));

    return newData;
  }

  static async listSelect() {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        level_sector {
          name
          id
        }
      }
      `,
    });
    return response.data.level_sector;
  }

}
