import model from 'modules/parent/parentModel';

const { fields } = model;

export default [
  fields.id,
  fields.created_at,
  fields.first_name,
  fields.last_name,
  fields.cin,
  fields.status,
  fields.type_parent,
  fields.parent_code,
];
