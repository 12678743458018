import AbsenceService from 'modules/absence/absenceService';
import formActions from 'modules/shared/form/formActions';
import service from 'modules/absence/absenceService';
import { getHistory } from 'modules/store';
import Message from 'view/shared/message';
import Errors from 'modules/shared/error/errors';

const prefix = 'ROOM';

const actions = {
  ERROR_MESSAGE_CLEARED: `${prefix}_ERROR_MESSAGE_CLEARED`,

  LIST_ABSENCE_START: `${prefix}LIST_ABSENCE_START`,
  LIST_ABSENCE_SUCCESS: `${prefix}LIST_ABSENCE_SUCCESS`,
  LIST_ABSENCE_ERROR: `${prefix}LIST_ABSENCE_ERROR`,
  UPDATE_ABSENCE_START: `${prefix}UPDATE_ABSENCE_START`,
  UPDATE_ABSENCE_SUCCESS: `${prefix}UPDATE_ABSENCE_SUCCESS`,
  UPDATE_ABSENCE_ERROR: `${prefix}UPDATE_ABSENCE_ERROR`,
  doClearErrorMessage() {
    return {
      type: actions.ERROR_MESSAGE_CLEARED,
    };
  },

  doList: (fields = {}) => async (dispatch) => {
    try {
      dispatch({ type: actions.LIST_ABSENCE_START });

      var data = await service.list();

      dispatch({
        type: actions.LIST_ABSENCE_SUCCESS,
        payload: { rows: data },
      });
    } catch (error) {
      dispatch({ type: actions.LIST_ABSENCE_ERROR });
      // getHistory().push('/');
    }
  },

  doUpdate: (data = [], idroomsession) => async (
    dispatch,
  ) => {
    try {
      dispatch({ type: actions.UPDATE_ABSENCE_START });

      await service.delete_manual_absence(idroomsession);
      var data_formater = data
        .filter((e) => e.status == true)
        .map((e) => ({
          id_roomsession: idroomsession,
          id_student: e.id,
        }));
      console.log(data_formater);
      await service.insert_absence(data_formater);

      dispatch({
        type: actions.UPDATE_ABSENCE_SUCCESS,
      });
      Message.success('Absence a été sauvgarder');

      window.location.replace('/');
    } catch (error) {
      Errors.handle(error);
      dispatch({ type: actions.UPDATE_ABSENCE_ERROR });
      // getHistory().push('/');
    }
  },
};
export default actions;
