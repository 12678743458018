import service from 'modules/roomSession/roomSessionService';
import { getHistory } from 'modules/store';
import Message from 'view/shared/message';
import { i18n } from 'i18n';
import Errors from 'modules/shared/error/errors';

const prefix = "ROOMSESSION";

const actions = {
  ERROR_MESSAGE_CLEARED: `${prefix}_ERROR_MESSAGE_CLEARED`,

  LIST_ROOMSESSION_START: `${prefix}LIST_ROOMSESSION_START`,
  LIST_ROOMSESSION_SUCCESS: `${prefix}LIST_ROOMSESSION_SUCCESS`,
  LIST_ROOMSESSION_ERROR: `${prefix}LIST_ROOMSESSION_ERROR`,
  
  CREATE_ROOMSESSION_START: `${prefix}CREATE_ROOMSESSION_START`,
  CREATE_ROOMSESSION_SUCCESS: `${prefix}CREATE_ROOMSESSION_SUCCESS`,
  CREATE_ROOMSESSION_ERROR: `${prefix}CREATE_ROOMSESSION_ERROR`,
  
  UPDATE_ROOMSESSION_START: `${prefix}UPDATE_ROOMSESSION_START`,
  UPDATE_ROOMSESSION_SUCCESS: `${prefix}UPDATE_ROOMSESSION_SUCCESS`,
  UPDATE_ROOMSESSION_ERROR: `${prefix}UPDATE_ROOMSESSION_ERROR`,
  
  DESTROY_ROOMSESSION_START: `${prefix}DESTROY_ROOMSESSION_START`,
  DESTROY_ROOMSESSION_SUCCESS: `${prefix}DESTROY_ROOMSESSION_SUCCESS`,
  DESTROY_ROOMSESSION_ERROR: `${prefix}DESTROY_ROOMSESSION_ERROR`,

  doClearErrorMessage() {
    return {
      type: actions.ERROR_MESSAGE_CLEARED,
    };
  },

  doAddRow: (data, row) => async (dispatch) => {
    try {
      dispatch({ type: actions.LIST_ROOMSESSION_START });

      data.push(row);
    
      dispatch({
        type: actions.LIST_ROOMSESSION_SUCCESS,
        payload: { 
          rows: data,
        },
      });
    } catch (error) {
      dispatch({ type: actions.LIST_ROOMSESSION_ERROR });
      // getHistory().push('/');
    }
  },

  doList: (data) => async (dispatch) => {
    try {

      // console.log('Listing.........................');
      // console.log(data);

      dispatch({ type: actions.LIST_ROOMSESSION_START });

      var response = await service.list(data, null, null, null);

      delete response.count;
      
      dispatch({
        type: actions.LIST_ROOMSESSION_SUCCESS,
        payload: { 
          rows: response
        },
      });

    } catch (error) {
      Errors.handle(error);
    }
  },

  doDestroy: (data, id) => async (dispatch) => {
    try {
      // console.log(data)
      // console.log(id)

      dispatch({ type: actions.DESTROY_ROOMSESSION_START });

      await service.destroyAll(id, data);

      dispatch({ type: actions.DESTROY_ROOMSESSION_SUCCESS });

      Message.success(i18n('Supprimé avec succès'));

      delete data.id;
      delete data.teacher;
      delete data.matter;

      dispatch(actions.doList(data));

    } catch (error) {
      dispatch({ type: actions.DESTROY_ROOMSESSION_ERROR });
      // Errors.handle(error);
      Message.error(i18n('Suppression non effectuer emploi du temps déjà utilisé'));
    }
  },

  doCreate: (data) => async (dispatch) => {
    try {

      dispatch({ type: actions.CREATE_ROOMSESSION_START });
      // console.log('Inserting............');

      await service.create(data[0]);

      let newArray = {
        classroom: data[0].classroom,
        level: data[0].level,
        sector: data[0].sector,
        cycle: data[0].cycle,
        schoolYear: data[0].schoolYear,
        week: data[0].week,
      }

      dispatch({ type: actions.CREATE_ROOMSESSION_SUCCESS });

      Message.success(i18n('Ajouté avec succès'));

      dispatch(actions.doList(newArray));

    } catch (error) {
      dispatch({ type: actions.CREATE_ROOMSESSION_ERROR });
      Errors.handle(error);
    }
  },

  doUpdate: (data) => async (dispatch) => {
    
    try {
      // console.log(data);

      dispatch({ type: actions.UPDATE_ROOMSESSION_START });

      await service.update(data[0].id, data[0]);

      let newArray = {
        classroom: data[0].classroom,
        level: data[0].level,
        sector: data[0].sector,
        cycle: data[0].cycle,
        schoolYear: data[0].schoolYear,
        week: data[0].week,
      }

      dispatch({ type: actions.UPDATE_ROOMSESSION_SUCCESS });
      
      Message.success(i18n('Modifiè avec succès'));

      dispatch(actions.doList(newArray));

    } catch (error) {
      dispatch({ type: actions.UPDATE_ROOMSESSION_ERROR });
      Errors.handle(error);
    }
  }

};

export default actions;
