import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';
import {
  message,
} from 'antd';
import Permissions from 'security/permissions';
import PermissionChecker from 'modules/auth/permissionChecker';
import { getStore } from 'modules/store';

export default class AssignmentsService {
  static async update(id, data) {
    const response2 = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          teachers {
            id
          }
        }
      `,
    });

    var teacherId = response2.data.teachers[0].id;

    const response1 = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        classroom_teacher_matter(where: {id_classroom: {_eq: ${data.classroom.id}}, id_teacher: {_eq: ${teacherId}}, id_matter: {_eq: ${data.matter}}}) {
          id
        }
      }
      `,
    });

    var id_CTM =
      response1.data.classroom_teacher_matter[0].id;

      
        
    const response5 = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          level_sector_element(where: {id_element: {_eq: ${data.element}}, element_matter: {matter: {id: {_eq: ${data.matter}}}}, level_sector: {
            classrooms: { id: {_eq: ${data.classroom.id}} }
          }}) {
            id
          }
        }
      `,
    });

    var levelSectorElementId = response5.data.level_sector_element[0].id;

    const response = await graphqlClient.config().mutate({
      mutation: gql`
        mutation MyMutation($data: assignments_set_input!) {
          update_assignments(
            where: { id: { _eq: ${id} } }
            _set: $data
          ) {
            affected_rows
          }
        }
      `,
      variables: {
        data: {
          name: data.name,
          id_type: data.types_assignment.id,
          id_classroom_teacher_matter: id_CTM,
          payload: data.payload,
          id_element: levelSectorElementId,
          type_course: data.type_course,
          youtubeLink: data.youtubeLink,
          start_date: data.start_date,
          end_date: data.end_date,
          hidden: data.hidden,
        },
      },
    });

    return response.data.update_assignments;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        delete_assignments(where: {id: {_eq: ${ids}}}) {
          affected_rows
        }
      }
      
      `,
    });

    return response.data;
  }

  static async create(data) {

    const response2 = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          teachers {
            id
          }
        }
      `,
    });

    var teacherId = response2.data.teachers[0].id;

    await data.listClassrooms.map( async (item, index) => {
      
      const response4 = await graphqlClient.config().query({
        query: gql`
          query MyQuery {
            classroom(where: {id: {_eq: ${item}}}) {
              id
              name
            }
          }
        `,
      });

      var classroomName = response4.data.classroom[0].name;
      
      const response1 = await graphqlClient.config().query({
        query: gql`
        query MyQuery {
          classroom_teacher_matter(where: {id_classroom: {_eq: ${item}}, id_teacher: {_eq: ${teacherId}}, id_matter: {_eq: ${data.matter}}}) {
            id
          }
        }
        `,
      });

      if( response1.data.classroom_teacher_matter.length == 0 ) {
        message.error(`Pas de matière pour ${classroomName} !!!`);
      } else {
        
        const response5 = await graphqlClient.config().query({
          query: gql`
            query MyQuery {
              level_sector_element(where: {id_element: {_eq: ${data.element}}, element_matter: {matter: {id: {_eq: ${data.matter}}}}, level_sector: {
                classrooms: { id: {_eq: ${item}} }
              }}) {
                id
              }
            }
          `,
        });

        var levelSectorElementId = response5.data.level_sector_element[0].id;

        console.log(response5.data.level_sector_element);

        var id_CTM = response1.data.classroom_teacher_matter[0].id;
    
        const response = await graphqlClient.config().mutate({
          mutation: gql`
            mutation MyMutation(
              $object: [assignments_insert_input!]!
            ) {
              insert_assignments(objects: $object) {
                affected_rows
              }
            }
          `,
    
          variables: {
            object: {
              name: data.name,
              id_type: data.types_assignment,
              id_classroom_teacher_matter: id_CTM,
              payload: data.payload,
              id_element: levelSectorElementId,
              type_course: data.type_course,
              coursHTML: data.coursHTML,
              youtubeLink: data.youtubeLink,
              start_date: data.start_date,
              end_date: data.end_date,
              hidden: data.hidden,
            },
          },
        });
      }
    })

    return null;
  }

  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          assignments(order_by: {created_at: desc}, where: { id: { _eq: ${id} } }) {
            id
            coursHTML
            youtubeLink
            start_date
            end_date
            hidden
            element {
              id
              element_matter {
                id
                name
              }
            }
            classroom_teacher_matter {
              classroom {
                id
                name
                level_sector {
                  sector {
                    id
                    name
                  }
                  level {
                    id
                    name
                    cycle {
                      id
                      name
                    }
                  }
                }
              }
              matter {
                id
                name
              }
            }
            name
            type_course
            types_assignment {
              id
              name
            }
            payload
            created_at
            updated_at
          }
        }
      `,
    });

    let data = response.data.assignments[0];

    // data.level = data.classroom.level.level;
    // delete data.classroom.level.level;
    // data.level = data.classroom.level;
    // delete data.classroom.level;

    return data;
  }

  static async list(filter, orderBy, limit, offset) {
    if (
      filter &&
      filter.created_at &&
      filter.created_atRange
    ) {
      delete filter.created_at;
    }
    if (filter == undefined) filter = {};   
    const CurrentUser = await getStore().getState().auth
      .currentUser;
    const permissions = Permissions.values;
    const permissionChecker = new PermissionChecker(
      CurrentUser,
    );

    var hidden = null;

    if (!permissionChecker.match(permissions.HomeParent) || !permissionChecker.match(permissions.HomePage)) {
      if (filter.hidden == true || filter.hidden == false) {
        hidden = filter.hidden
      }
    } else {
      hidden = false;
    }

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {

          assignments_aggregate (where: {name:
              ${
                filter.name
                  ? `{_like:"%${filter.name}%"}`
                  : '{}'
              },
              start_date:
                ${
                  filter.start_date
                    ? `{_eq: "${filter.start_date}"}`
                    : '{}'
                },
                end_date:
                ${
                  filter.end_date
                    ? `{_eq: "${filter.end_date}"}`
                    : '{}'
                },
              element: {

                element_matter: {
                  id:
                    ${
                      filter.element
                        ? `{_eq: ${filter.element}}`
                        : '{}'
                    }
                }
                
              }
              ,
                    classroom_teacher_matter: {
                      classroom: {
                        id:
                        ${
                          filter.classroom
                            ? `{_eq: ${filter.classroom}}`
                            : '{}'
                        }
                        ${
                          filter.student
                            ? `
                              registers: {
                                student: {
                                  id:{_eq: ${filter.student.id}}
                                }
                              }`
                            : ''
                        }
                        level_sector: {
                          level: {
                            cycle: {
                              id:
                              ${
                                filter.cycle
                                  ? `{_eq: ${filter.cycle}}`
                                  : '{}'
                              }
                            }
                          }
                        }
                      }
                      teacher: {
                        id:
                        ${
                          filter.teacher
                            ? `{_eq: ${filter.teacher.id}}`
                            : '{}'
                        }
                      }
                      matter: {
                        id:
                        ${
                          filter.matter
                            ? `{_eq:${filter.matter}}`
                            : '{}'
                        }
                      }
                    },hidden: {_eq: ${hidden}}
            ${
              filter.createdAtRange
                ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
                : ''
            }
          }){
            aggregate{
              count
            }
          }

          assignments( ${
          offset != 0 ? `offset:${offset} ,` : ''
        } order_by: {created_at: desc}, where: {name:
            ${
              filter.name
                ? `{_like:"%${filter.name}%"}`
                : '{}'
            },
            start_date:
              ${
                filter.start_date
                  ? `{_eq: "${filter.start_date}"}`
                  : '{}'
              },
              end_date:
              ${
                filter.end_date
                  ? `{_eq: "${filter.end_date}"}`
                  : '{}'
              },
              element: {

                element_matter: {
                  id:
                    ${
                      filter.element
                        ? `{_eq: ${filter.element}}`
                        : '{}'
                    }
                }
                
              }
            ,
                  classroom_teacher_matter: {
                    classroom: {
                      id:
                      ${
                        filter.classroom
                          ? `{_eq: ${filter.classroom}}`
                          : '{}'
                      }
                      ${
                        filter.student
                          ? `
                            registers: {
                              student: {
                                id:{_eq: ${filter.student.id}}
                              }
                            }`
                          : ''
                      }
                      level_sector: {
                        level: {
                          cycle: {
                            id:
                            ${
                              filter.cycle
                                ? `{_eq: ${filter.cycle}}`
                                : '{}'
                            }
                          }
                        }
                      }
                    }
                    teacher: {
                      id:
                      ${
                        filter.teacher
                          ? `{_eq: ${filter.teacher.id}}`
                          : '{}'
                      }
                    }
                    matter: {
                      id:
                      ${
                        filter.matter
                          ? `{_eq:${filter.matter}}`
                          : '{}'
                      }
                    }
                  },hidden: {_eq: ${hidden}}
          ${
            filter.createdAtRange
              ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
              : ''
          }
        }) {
            id
            youtubeLink
            coursHTML
            start_date
            end_date
            hidden
            element {
              id
              element_matter  {
                id
                name
              }
            }
            classroom_teacher_matter {
              teacher {
                id
                first_name
                last_name
              }
              classroom {
                id
                name
                level_sector {
                  sector {
                    id
                    name
                  }
                  level {
                    id
                    name
                    cycle {
                      id
                      name
                    }
                  }
                }
              }
              matter {
                id
                name
              }
            }
            name
            type_course
            types_assignment {
              name
              id
            }
            payload
            created_at
            updated_at
          }
        }
      `,
      });

    var data = response.data.assignments;
    data.count =
      response.data.assignments_aggregate.aggregate.count;
    return data;
  }

  static async typeAssignment() {
    const response = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          types_assignements {
            id
            name
          }
        }
      `,
    });
    return response.data.types_assignements;
  }
}
