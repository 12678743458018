import list from 'modules/circuit/list/circuitListReducers';
import form from 'modules/circuit/form/circuitFormReducers';
import view from 'modules/circuit/view/circuitViewReducers';
import destroy from 'modules/circuit/destroy/circuitDestroyReducers';
import importerReducer from 'modules/circuit/importer/circuitImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
    list,
    form,
    view,
    destroy,
    importer: importerReducer,
});