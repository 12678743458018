import destroyActions from 'modules/shared/destroy/destroyActions';
import listActions from 'modules/semester/list/semesterListActions';
import SemesterService from 'modules/semester/semesterService';

const prefix = 'MATTER_DESTROY';

export default destroyActions({
  prefix,
  destroyAllFn: SemesterService.destroyAll,
  destroySuccessMessageI18nKey:
    'entities.Semester.destroy.success',
  destroyAllSuccessMessageI18nKey:
    'entities.Semester.destroyAll.success',
  redirectTo: '/Semester',
  listActions,
});
