import LevelSectorMatterExamService from 'modules/levelSectorMatterExam/levelSectorMatterExamService';
import formActions from 'modules/shared/form/formActions';

const prefix = 'LevelSectorMatterExam_FORM';

export default formActions({
    prefix,
    createFn: LevelSectorMatterExamService.create,
    createSuccessMessageI18nKey:
        'entities.LevelSectorMatterExam.create.success',
    updateFn: LevelSectorMatterExamService.update,
    updateSuccessMessageI18nKey:
        'entities.LevelSectorMatterExam.update.success',
    findFn: LevelSectorMatterExamService.find,
    redirectTo: '/levelSectorMatterExam',
});