import viewActions from "modules/shared/view/viewActions";
import StudentStatusService from "modules/studentStatus/studentStatusService";

const prefix = "StudentStatus_VIEW";

export default viewActions({
  prefix,
  findFn: StudentStatusService.find,
  redirectToOnError: "/studentStatus",
});
