import actions from 'modules/roomSession/form/roomsessionFormActions';

const initialData = {
    rows: null,
    loading: true,
    errorMessage: null,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.ERROR_MESSAGE_CLEARED) {
    return {
      ...state,
      errorMessage: null,
    };
  }

  if (type === actions.LIST_ROOMSESSION_START) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.LIST_ROOMSESSION_SUCCESS) {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
    };
  }

  if (type === actions.LIST_ROOMSESSION_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }

  if (type === actions.CREATE_ROOMSESSION_START) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.CREATE_ROOMSESSION_SUCCESS) {
    return {
      ...state,
      loading: false
    };
  }

  if (type === actions.CREATE_ROOMSESSION_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }

  if (type === actions.UPDATE_ROOMSESSION_START) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.UPDATE_ROOMSESSION_SUCCESS) {
    return {
      ...state,
      loading: false,
    };
  }

  if (type === actions.UPDATE_ROOMSESSION_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }

  if (type === actions.DESTROY_ROOMSESSION_START) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.DESTROY_ROOMSESSION_SUCCESS) {
    return {
      ...state,
      loading: false,
    };
  }

  if (type === actions.DESTROY_ROOMSESSION_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }

  return state;
};
