import QrCodeGeneratorService from 'modules/qrCodeGenerator/qrCodeGeneratorService';
import formActions from 'modules/shared/form/formActions';
import service from 'modules/qrCodeGenerator/qrCodeGeneratorService';
import ResponsiblesService from 'modules/responsibles/responsiblesService';
import EducDirectorService from 'modules/educDirector/educDirectorService';
import TeachersService from 'modules/teachers/teachersService';
import StudentsService from 'modules/students/studentsService';
import ParentService from 'modules/parent/parentService';
import FramerService from 'modules/framer/framerService';
import SchoolYearService from 'modules/schoolYear/schoolYearService';
import { getHistory } from 'modules/store';
import Message from 'view/shared/message';
import Errors from 'modules/shared/error/errors';
import config from '../../../config/index';

const prefix = 'QRCODEGENERATOR';

const actions = {
  RESET: `${prefix}_RESET`,
  ERROR_MESSAGE_CLEARED: `${prefix}_ERROR_MESSAGE_CLEARED`,

  LIST_QRCODEGENERATOR_START: `${prefix}LIST_QRCODEGENERATOR_START`,
  LIST_QRCODEGENERATOR_SUCCESS: `${prefix}LIST_QRCODEGENERATOR_SUCCESS`,
  LIST_QRCODEGENERATOR_ERROR: `${prefix}LIST_QRCODEGENERATOR_ERROR`,
  UPDATE_QRCODEGENERATOR_START: `${prefix}UPDATE_QRCODEGENERATOR_START`,
  UPDATE_QRCODEGENERATOR_SUCCESS: `${prefix}UPDATE_QRCODEGENERATOR_SUCCESS`,
  UPDATE_QRCODEGENERATOR_ERROR: `${prefix}UPDATE_QRCODEGENERATOR_ERROR`,
  doClearErrorMessage() {
    return {
      type: actions.ERROR_MESSAGE_CLEARED,
    };
  },

  doExport: (fields = {}, email) => async (dispatch) => {
    try {
      dispatch({
        type: actions.LIST_QRCODEGENERATOR_START,
      });

      var school = config.importDbApi
        .split('http://')[1]
        .split('.')[0];

      var response = [],
        data = [];

      var schoolYear = await SchoolYearService.find(
        fields.schoolYear,
      );

      if (fields.isAll === 0) {
        if (fields.role === 0) {
          response = await ResponsiblesService.find(
            fields.responsible.id,
          );
          data = response.map((item, index) => ({
            ...item,
            schoolYear: schoolYear.name,
            school: school,
            role: 'responsible',
          }));
          response = data;
        } else {
          if (fields.role === 1) {
            response = await EducDirectorService.find(
              fields.educDirector.id,
            );
            data = response.map((item, index) => ({
              ...item,
              schoolYear: schoolYear.name,
              school: school,
              role: 'educDirector',
            }));
            response = data;
          } else {
            if (fields.role === 2) {
              response = await TeachersService.findTeacher(
                fields.teacher.id,
              );
              data = response.map((item, index) => ({
                ...item,
                schoolYear: schoolYear.name,
                school: school,
                role: 'teacher',
                permanent: item.permanent
                  ? 'Permanent'
                  : 'Vacatère',
              }));
              response = data;
            } else {
              if (fields.role === 3) {
                response = await StudentsService.find(
                  fields.student.id,
                );
                data = response.map((item, index) => ({
                  ...item,
                  schoolYear: schoolYear.name,
                  school: school,
                  role: 'student',
                  transport: item.registers[0].transport
                    ? 'Oui'
                    : 'Non',
                  canteen: item.registers[0].canteen
                    ? 'Oui'
                    : 'Non',
                }));
                response = data;
              } else {
                if (fields.role === 4) {
                  response = await ParentService.find(
                    fields.parent.id,
                  );
                  data = response.map((item, index) => ({
                    ...item,
                    schoolYear: schoolYear.name,
                    school: school,
                    role: 'parent',
                  }));
                  response = data;
                } else {
                  if (fields.role === 5) {
                    response = await FramerService.find(
                      fields.framer.id,
                    );
                    data = response.map((item, index) => ({
                      ...item,
                      schoolYear: schoolYear.name,
                      school: school,
                      role: 'framer',
                    }));
                    response = data;
                  }
                }
              }
            }
          }
        }
      } else {
        if (fields.isAll === 1) {
          if (fields.role === 0) {
            response = await ResponsiblesService.find(null);
            data = response.map((item, index) => ({
              ...item,
              schoolYear: schoolYear.name,
              school: school,
              role: 'responsible',
            }));
            response = data;
          } else {
            if (fields.role === 1) {
              response = await EducDirectorService.find(
                null,
              );
              data = response.map((item, index) => ({
                ...item,
                schoolYear: schoolYear.name,
                school: school,
                role: 'educDirector',
              }));
              response = data;
            } else {
              if (fields.role === 2) {
                response = await TeachersService.findTeacher(
                  null,
                );
                data = response.map((item, index) => ({
                  ...item,
                  schoolYear: schoolYear.name,
                  school: school,
                  role: 'teacher',
                  permanent: item.permanent
                    ? 'Permanent'
                    : 'Vacatère',
                }));
                response = data;
              } else {
                if (fields.role === 3) {
                  response = await StudentsService.findStudents(
                    fields.classroom,
                  );
                  data = response.map((item, index) => ({
                    ...item,
                    schoolYear: schoolYear.name,
                    school: school,
                    role: 'student',
                    transport: item.registers[0].transport
                      ? 'Oui'
                      : 'Non',
                    canteen: item.registers[0].canteen
                      ? 'Oui'
                      : 'Non',
                  }));
                  response = data;
                } else {
                  if (fields.role === 4) {
                    response = await ParentService.find(
                      null,
                    );
                    data = response.map((item, index) => ({
                      ...item,
                      schoolYear: schoolYear.name,
                      school: school,
                      role: 'parent',
                    }));
                    response = data;
                  } else {
                    if (fields.role === 5) {
                      response = await FramerService.find(
                        null,
                      );
                      data = response.map(
                        (item, index) => ({
                          ...item,
                          schoolYear: schoolYear.name,
                          school: school,
                          role: 'framer',
                        }),
                      );
                      response = data;
                    }
                  }
                }
              }
            }
          }
        }
      }

      await service.generate_qr(response, email);
      Message.success(
        'Le Fichier sera envoyer a votre boite email !',
      );
      getHistory().push('/');
      // data = await service.list();

      dispatch({
        type: actions.LIST_QRCODEGENERATOR_SUCCESS,
        // payload: { rows: data },
      });
    } catch (error) {
      dispatch({
        type: actions.LIST_QRCODEGENERATOR_ERROR,
      });
      // getHistory().push('/');
    }
  },
};
export default actions;
