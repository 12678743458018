import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';

export default class MatterService {
  static async update(id, data) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        update_transport(where: {id: {_eq: ${id}}}, _set: {number: ${data.number}, brand: "${data.brand}", model: "${data.model}"}) {
          affected_rows
        }
      }      
      `,
    });

    return response.data.update_transport;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
        mutation MyMutation {
          delete_transport(where: {id: {_eq: ${ids}}}) {
            affected_rows
          }
        }
      `,
    });

    return response.data.delete_transport;
  }

  static async create(data) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        insert_transport(objects: {number: ${data.number}, brand: "${data.brand}", model: "${data.model}"}) {
          affected_rows
        }
      }
      `,
    });

    return response.data.insert_transport;
  }

  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          transport(where: { id: { _eq: ${id} } }) {
            id
            number
            brand
            model
            created_at
            updated_at
          }
        }
      `,
    });

    return response.data.transport[0];
  }

  static async list(filter, orderBy, limit, offset) {
    if (
      filter &&
      filter.createdAt &&
      filter.createdAtRange
    ) {
      delete filter.createdAt;
    }
    if (filter == undefined) filter = {};

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {

          transport_aggregate(where: {id:${
            filter.id ? ` {_eq: ${filter.id}}` : '{}'
          },
          brand:
          ${
            filter.brand
              ? `{_like:"%${filter.brand.label}%"}`
              : '{}'
          },
          model:
          ${
            filter.model
              ? `{_like:"%${filter.model.label}%"}`
              : '{}'
          },
            number:
            ${
              filter.number
                ? `{_eq: ${filter.number}}`
                : '{}'
            },
          
          
          ${
            filter.createdAt
              ? `,created_at:{_eq:"${filter.createdAt.format()}"}`
              : ''
          },
        
          updated_at:
        ${
          filter.updatedAt
            ? `{_eq:"${filter.updatedAt.format()}"}`
            : '{}'
        }
        
        ${
          filter.createdAtRange
            ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
            : ''
        }
        
      }) {
            aggregate {
              count
            }
          }

          transport( ${limit != 0 ? `limit:${limit},` : ''} ${
          offset != 0 ? `offset:${offset} ,` : ''
        } order_by:{${
          orderBy ? orderBy : ''
        }}, where: {id:${
          filter.id ? ` {_eq: ${filter.id}}` : '{}'
        },
        brand:
        ${
          filter.brand
            ? `{_like:"%${filter.brand.label}%"}`
            : '{}'
        },
        model:
        ${
          filter.model
            ? `{_like:"%${filter.model.label}%"}`
            : '{}'
        },
          number:
          ${
            filter.number
              ? `{_eq: ${filter.number}}`
              : '{}'
          },
        
        ${
          filter.createdAt
            ? `,created_at:{_eq:"${filter.createdAt.format()}"}`
            : ''
        },
      
        updated_at:
      ${
        filter.updatedAt
          ? `{_eq:"${filter.updatedAt.format()}"}`
          : '{}'
      }
      
      ${
        filter.createdAtRange
          ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
          : ''
      }
      
    }) {
            id
            number
            brand
            model
            created_at
            updated_at
          }
        }
        `,
      });

    console.log(response.data);

    var data = response.data.transport;

    data.count = response.data.transport_aggregate.aggregate.count;
    return data;
  }

  static async listAutocomplete(query, limit) {

    var Myarray = [];
    var response = {};
    if ( isNaN(parseInt(query)) ) {
      response = await graphqlClient.config().query({
        query: gql`
          query MyQuery {
            transport(where: {_or: {brand: {_like: "%${query}%"}, model: {_like: "%${query}%"}}}) {
              number
              brand
              model
              id
            }
          }
        `,
      });
    } else {
      response = await graphqlClient.config().query({
        query: gql`
          query MyQuery {
            transport(where: {number: {_eq: ${query ? parseInt(query): null}}}) {
              number
              brand
              model
              id
            }
          }
        `,
      });
    }

    

    var newMap = response.data.transport.map((item) => {
      return {
        id: item.id,
        label: `${item.number} - ${item.brand} - ${item.model}`,
      };
    });

    return newMap;

  }

  static async listSelect() {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        transport {
          number
          brand
          model
          id
        }
      }
      `,
    });
    return response.data.transport;
  }
}
