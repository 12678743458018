import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/levelSectorMatterExam/importer/levelSectorMatterExamImporterSelectors';
import LevelSectorMatterExamService from 'modules/levelSectorMatterExam/levelSectorMatterExamService';
import fields from 'modules/levelSectorMatterExam/importer/levelSectorMatterExamImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'level_sector_matter_exam',
  selectors,
  LevelSectorMatterExamService.import,
  fields,
  i18n('entities.LevelSectorMatterExam.importer.fileName'),
);
