import RoomURLService from 'modules/roomURL/roomURLService';
import paginationAction from 'modules/shared/pagination/paginationAction';
import selectors from 'modules/roomURL/list/roomURLListSelectors';
import { i18n } from 'i18n';
import exporterFields from 'modules/roomURL/list/roomURLListExporterFields';

const prefix = 'RoomURL_LIST';

export default paginationAction(
  prefix,
  RoomURLService.list,
  selectors,
  i18n('entities.RoomURL.exporterFileName'),
  exporterFields,
);
