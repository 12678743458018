import list from 'modules/gradesManager/list/gradesManagerListReducers';
import form from 'modules/gradesManager/form/gradesManagerFormReducers';
import view from 'modules/gradesManager/view/gradesManagerViewReducers';
import destroy from 'modules/gradesManager/destroy/gradesManagerDestroyReducers';
import importerReducer from 'modules/gradesManager/importer/gradesManagerImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
    list,
    form,
    view,
    destroy,
    importer: importerReducer,
});