import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';

export default class MatterService {
  static async update(id, data) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        update_element_matter(where: {id: {_eq: ${id}}}, _set: {name: "${data.name}", id_matter: ${data.matter}}) {
          affected_rows
        }
      }      
      `,
    });

    return response.data.update_element_matter;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
        mutation MyMutation {
          delete_element_matter(where: {id: {_eq: ${ids}}}) {
            affected_rows
          }
        }
      `,
    });

    return response.data.delete_element_matter;
  }

  static async create(data) {
    // console.log(data);
    
    await data.elements.map( async (item, index) => {
      await graphqlClient.config().mutate({
        mutation: gql`
        mutation MyMutation {
          insert_element_matter(objects: {name: "${item.toLowerCase()}", id_matter: ${
          data.matter
        }}) {
            affected_rows
          }
        }
        `,
      });
    })
    
    return null;
  }

  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          element_matter(where: { id: { _eq: ${id} } }) {
            id
            name
            matter {
              id
              name
            }
            created_at
            updated_at
          }
        }
      `,
    });

    return response.data.element_matter[0];
  }

  static async list(filter, orderBy, limit, offset) {
    if (
      filter &&
      filter.createdAt &&
      filter.createdAtRange
    ) {
      delete filter.createdAt;
    }
    if (filter == undefined) filter = {};

    // console.log(filter)

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {

          element_matter_aggregate(where: {id:${
            filter.id ? ` {_eq: ${filter.id}}` : '{}'
          },
            name:
            ${
              filter.name
                ? `{_like:"%${filter.name.label}%"}`
                : '{}'
            },
            id_matter:
            ${
              filter.matter ? `{_eq: ${filter.matter.id}}` : '{}'
            }
          ${
            filter.createdAt
              ? `,created_at:{_eq:"${filter.createdAt.format()}"}`
              : ''
          },
        
          updated_at:
        ${
          filter.updatedAt
            ? `{_eq:"${filter.updatedAt.format()}"}`
            : '{}'
        }
        
        ${
          filter.createdAtRange
            ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
            : ''
        }  
      }) {
            aggregate {
              count
            }
          }
          element_matter( ${limit != 0 ? `limit:${limit},` : ''} ${
            offset != 0 ? `offset:${offset} ,` : ''
          } order_by:{${
            orderBy ? orderBy : ''
          }}, where: {id:${
            filter.id ? ` {_eq: ${filter.id}}` : '{}'
          },
            name:
            ${
              filter.name
                ? `{_like:"%${filter.name.label}%"}`
                : '{}'
            },
            id_matter:
            ${
              filter.matter ? `{_eq: ${filter.matter.id}}` : '{}'
            }
          ${
            filter.createdAt
              ? `,created_at:{_eq:"${filter.createdAt.format()}"}`
              : ''
          },
        
          updated_at:
        ${
          filter.updatedAt
            ? `{_eq:"${filter.updatedAt.format()}"}`
            : '{}'
        }
        
        ${
          filter.createdAtRange
            ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
            : ''
        }
  
      }) {
            id
            name
            created_at
            updated_at
            matter {
              id
              name
            }
          }
        }
        `,
      });

    var data = response.data.element_matter;

    var new_Data = data;

    new_Data.count = response.data.element_matter_aggregate.aggregate.count;

    return new_Data;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          element_matter(where: {name: {_like: "%${query}%"}}) {
            id
            name
            matter {
              id
              name
            }
          }
        }
      `,
    });

    return response.data.element_matter;
  }

  static async listSelect(matterId, classroomId) {
    
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        element_matter(where: {id_matter: {_eq:  ${
          matterId ? matterId : null
        }}
        ${
          classroomId
            ? `, level_sector_elements: {
                  level_sector: {
                    classrooms: {
                      id: {_eq: ${classroomId}}
                    }
                  }
                }`
            : ''
        }
          
        }) {
          name
          id
        }
      }
      `,
    });
    return response.data.element_matter;
  }
}
