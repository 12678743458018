import destroyActions from 'modules/shared/destroy/destroyActions';
import listActions from 'modules/neighborhood/list/neighborhoodListActions';
import NeighborhoodService from 'modules/neighborhood/neighborhoodService';

const prefix = 'Neighborhood_DESTROY';

export default destroyActions({
  prefix,
  destroyAllFn: NeighborhoodService.destroyAll,
  destroySuccessMessageI18nKey:
    'entities.Neighborhood.destroy.success',
  destroyAllSuccessMessageI18nKey:
    'entities.Neighborhood.destroyAll.success',
  redirectTo: '/neighborhood',
  listActions,
});
