import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';
import { getLanguageCode } from 'i18n';

export default class ChatService {
  static async list_role() {
    const response = await graphqlClient.config().query({
      query: gql`
        query {
          roles(where: { role: { _neq: "user" } }) {
            role
          }
        }
      `,
    });

    return response.data.roles;
  }

  static async findChat(user_id) {
    const response = await graphqlClient.config().query({
      query: gql`
        query findChat($user_id: uuid) {
          chat(
            where: {
              _or: [{ id_user_one: { _eq: $user_id } }]
            }
          ) {
            id
          }
        }
      `,
      variables: { user_id },
    });

    return response.data.chat;
  }

  static async CreateChate(user_id) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        insert_chat(objects: {id_user_two: "${user_id}"}) {
          affected_rows
          returning {
            id
          }
        }
      }
      
      `,
    });

    return response.data.insert_chat.returning[0].id;
  }

  static async listeMessages(id) {
    const response = await graphqlClient.config().query({
      query: gql`
      query {
        message(where:{chat_id:{_eq:${id}}}){
          content
          sender_id
          created_at
          
        }
      }
      `,
    });

    return response.data.message;
  }

  static async InsertMessage(chat_id, text) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        insert_message(objects: {chat_id: ${chat_id}, content:"${text}"}) {
          affected_rows
        }
      }
      
      `,
    });

    return response.data.insert_message;
  }
}
