import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';
import { getLanguageCode } from 'i18n';

export default class MatterService {
  static async update(id, data) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        update_matter(where: {id: {_eq: ${id}}}, _set: {name: "${data.name}"}) {
          affected_rows
        }
      }
      
      `,
    });

    return response.data.update_matter;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        delete_matter(where: {id: {_eq: ${ids}}}) {
          affected_rows
        }
      }
      
      `,
    });

    return response.data.delete_matter;
  }

  static async create(data) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyMutation {
        insert_matter(objects: {name: "${data.name.toLowerCase()}"}) {
          affected_rows
        }
     }    
      `,
    });

    return response.data.insert_matter;
  }

  static async import(file) {}

  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        matter(where: {id: {_eq: ${id}}}) {
          id
          name
          createdAt
          updatedAt
        }
      }
      
      `,
    });

    return response.data.matter[0];
  }

  static async list(filter = {}, orderBy, limit, offset) {
    if (
      filter &&
      filter.createdAt &&
      filter.createdAtRange
    ) {
      delete filter.createdAt;
    }
    if (filter == undefined) filter = {};

    console.log(`
    query MyQuery {

      matter_aggregate(where: {id: {_eq: ${
        filter.id || null
      }},
        name:
        ${
          filter.name ? `{_like: "%${filter.name}%"}` : '{}'
        }
        
        ${
          filter.createdAt
            ? `,createdAt:{_eq:"${filter.createdAt.format()}"}`
            : ''
        },
      
        updatedAt:
      ${
        filter.updatedAt
          ? `{_eq:"${filter.updatedAt.format()}"}`
          : '{}'
      }
      
      ${
        filter.createdAtRange
          ? `,createdAt:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
          : ''
      }
        }) {
        aggregate {
          count
        }
      }
      
      matter( ${limit != 0 ? `limit:${limit},` : ''} ${
      offset != 0 ? `offset:${offset} ,` : ''
    } order_by:{${
      orderBy ? orderBy : ''
    }}, where: {id: {_eq: ${filter.id || null}},
    name:
    ${filter.name ? `{_like: "%${filter.name}%"}` : '{}'}
    
    ${
      filter.createdAt
        ? `,createdAt:{_eq:"${filter.createdAt.format()}"}`
        : ''
    },
  
    updatedAt:
  ${
    filter.updatedAt
      ? `{_eq:"${filter.updatedAt.format()}"}`
      : '{}'
  }
  
  ${
    filter.createdAtRange
      ? `,createdAt:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
      : ''
  }
    }) {
        id
        name
        createdAt
        updatedAt
      }
    }
    `);

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
        query MyQuery {

          matter_aggregate(where: {id: {_eq: ${filter.id || null}},
            name:
            ${
              filter.name ? `{_like: "%${filter.name}%"}` : '{}'
            }
            
            ${
              filter.createdAt
                ? `,createdAt:{_eq:"${filter.createdAt.format()}"}`
                : ''
            },
          
            updatedAt:
          ${
            filter.updatedAt
              ? `{_eq:"${filter.updatedAt.format()}"}`
              : '{}'
          }
          
          ${
            filter.createdAtRange
              ? `,createdAt:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
              : ''
          }
            }) {
            aggregate {
              count
            }
          }
          
          matter( ${limit != 0 ? `limit:${limit},` : ''} ${
          offset != 0 ? `offset:${offset} ,` : ''
        } order_by:{${
          orderBy ? orderBy : ''
        }}, where: {id: {_eq: ${filter.id || null}},
        name:
        ${
          filter.name ? `{_like: "%${filter.name}%"}` : '{}'
        }
        
        ${
          filter.createdAt
            ? `,createdAt:{_eq:"${filter.createdAt.format()}"}`
            : ''
        },
      
        updatedAt:
      ${
        filter.updatedAt
          ? `{_eq:"${filter.updatedAt.format()}"}`
          : '{}'
      }
      
      ${
        filter.createdAtRange
          ? `,createdAt:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
          : ''
      }
        }) {
            id
            name
            createdAt
            updatedAt
          }
        }
        `,
      });

    console.log(response.data);

    var data = response.data.matter;

    var new_Data = data.map((e) => ({
      ...e,
    }));
    new_Data.count =
      response.data.matter_aggregate.aggregate.count;
    return new_Data;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        matter(where: {name: {_like: "%${query}%"}}) {
          id
          name
        }
      }
      `,
    });

    return response.data.matter;
  }

  static async listSelect(classroomId) {
    const response = await graphqlClient.config().query({
      query: gql`
        query MyQuery {
          matter(where: {${classroomId ? `classroom_teacher_matters: {id_classroom: {_eq: ${classroomId ? classroomId : null}}}` : ''}}) {
            id
            name
          }
        }
      `,
    });
    return response.data.matter;
  }
}
