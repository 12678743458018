import CircuitService from 'modules/circuit/circuitService';
import paginationAction from 'modules/shared/pagination/paginationAction';
import selectors from 'modules/circuit/list/circuitListSelectors';
import { i18n } from 'i18n';
import exporterFields from 'modules/circuit/list/circuitListExporterFields';

const prefix = 'Circuit_LIST';

export default paginationAction(
  prefix,
  CircuitService.list,
  selectors,
  i18n('entities.Circuit.exporterFileName'),
  exporterFields,
);
