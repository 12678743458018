import gql from 'graphql-tag';
import graphqlClient from 'modules/shared/graphql/graphqlClient';
import graphqlClientConfig from 'modules/shared/graphql/graphqlClient';
import role from 'security/roles';
import authService from 'modules/auth/authService';

export default class ParentService {
  static async update(id, data) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
      mutation MyQuery {
        update_parent(where: {id: {_eq: ${id}}}, _set: {first_name: "${data.first_name}",last_name: "${data.last_name}"}) {
          affected_rows
          returning {
            id_user
          }
        }
      }
      `,
    });
    return response.data.update_parent;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.config().mutate({
      mutation: gql`
        mutation MyMutation {
          delete_parent(where: {id: {_eq: ${ids}}}) {
            affected_rows
            returning {
              id_user
            }
          }
        }
        `,
    });
    let id_user =
      response.data.delete_parent.returning[0].id_user;

    const response1 = await graphqlClient.config().mutate({
      mutation: gql`
        mutation MyMutation {
          delete_users(where: {id: {_eq: "${id_user}"}}) {
            affected_rows
          }
        }
        `,
    });

    return response1.data.delete_users;
  }

  static async create(data) {
    let userId = await authService.createUser({
      email: data.email,
      phone: data.phone,
      role: role.values.parent,
    });

    const response = await graphqlClient.config().mutate({
      mutation: gql`
        mutation MyMutation(
          $data: [parent_insert_input!]!
        ) {
          insert_parent(objects: $data) {
            affected_rows
            returning {
              id
            }
          }
        }
      `,
      variables: {
        data: {
          id_user: userId,
          first_name: data.first_name,
          last_name: data.last_name,
        },
      },
    });

    return response.data.insert_parent;
  }

  static async find(id) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        parent_view(
          where: {id: {_eq: ${id}}}
        ) {
          id
          updated_at
          created_at
          first_name
          last_name
          email
          user_id
          phone
        }
      }
      `,
    });
    var data = response.data.parent_view;

    var new_Data = data.map((e) => ({
      ...e,
      user: {
        email: e.email,
        phone: e.phone,
        id: e.user_id,
      }
    }));

    return new_Data;
  }

  static async list(filter, orderBy, limit, offset) {
    if (
      filter &&
      filter.createdAt &&
      filter.createdAtRange
    ) {
      delete filter.createdAt;
    }
    if (filter == undefined) filter = {};

    const response = await graphqlClientConfig
      .config()
      .query({
        query: gql`
      
        query MyQuery {

          parent_view_aggregate(where: {id: ${
            filter.id ? ` {_eq: ${filter.id}}` : '{}'
          },
          first_name:
          ${
            filter.first_name
              ? `{_like: "%${filter.first_name}%"}`
              : '{}'
          },
          last_name:
          ${
            filter.last_name
              ? `{_like: "%${filter.last_name}%"}`
              : '{}'
          },
            phone:
            ${
              filter.phone
                ? `{_like: "%${filter.phone}%"}`
                : '{}'
            },
            email:
            ${
              filter.email
                ? `{_like: "%${filter.email}%"}`
                : '{}'
            }
          ${
            filter.created_at
              ? `,created_at:{_eq:"${filter.created_at.format()}"}`
              : ''
          },
          
              updated_at:
          ${
            filter.updated_at
              ? `{_eq:"${filter.updated_at.format()}"}`
              : '{}'
          }
          
          ${
            filter.createdAtRange
              ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
              : ''
          }
            }) {
            aggregate {
              count
            }
          }

          parent_view( ${limit != 0 ? `limit:${limit},` : ''} ${
            offset != 0 ? `offset:${offset} ,` : ''
          } order_by:{${
            orderBy ? orderBy : ''
          }}, where: {id: ${
            filter.id ? ` {_eq: ${filter.id}}` : '{}'
          },
          first_name:
          ${
            filter.first_name
              ? `{_like: "%${filter.first_name}%"}`
              : '{}'
          },
          last_name:
          ${
            filter.last_name
              ? `{_like: "%${filter.last_name}%"}`
              : '{}'
          },
            phone:
            ${
              filter.phone
                ? `{_like: "%${filter.phone}%"}`
                : '{}'
            },
            email:
            ${
              filter.email
                ? `{_like: "%${filter.email}%"}`
                : '{}'
            }
          ${
            filter.created_at
              ? `,created_at:{_eq:"${filter.created_at.format()}"}`
              : ''
          },
          
              updated_at:
          ${
            filter.updated_at
              ? `{_eq:"${filter.updated_at.format()}"}`
              : '{}'
          }
          
          ${
            filter.createdAtRange
              ? `,created_at:{_gte: "${filter.createdAtRange[0].format()}", _lte: "${filter.createdAtRange[1].format()}"}`
              : ''
          }
            }) {
            cin
            address
            type_parent
            parent_code
            status
            id
            updated_at
            created_at
            first_name
            last_name
            email
            user_id
            phone
          }
        }
        `,
      });
    var data = response.data.parent_view;

    var new_Data = data.map((e) => ({
      ...e,
      user: {
        email: e.email,
        phone: e.phone,
        id: e.user_id,
      }
    }));

    new_Data.count =
      response.data.parent_view_aggregate.aggregate.count;
    return new_Data;
  }

  static async listAutocomplete(query, limit) {
    var Myarray = query.split(' ');
    if (Myarray.length == 1) {
      Myarray[1] = '';
    }

    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        parent(where: {
          _or: [
            {last_name: {_like: "%${Myarray[0]}%"}, first_name: {_like: "%${Myarray[1]}%"}},
            {first_name: {_like: "%${Myarray[0]}%"}, last_name: {_like: "%${Myarray[1]}%"}}
          ]
        }) {
          id
          first_name
          last_name
        }
      }
      `,
    });

    var newMap = response.data.parent.map((item) => {
      return {
        id: item.id,
        label: item.first_name + ' ' + item.last_name,
      };
    });

    return newMap;
  }

  static async listSelect(limit) {
    const response = await graphqlClient.config().query({
      query: gql`
      query MyQuery {
        parent {
          id
          updated_at
          created_at
          first_name
          last_name
        }
      }
      `,
    });
    return response.data.parent;
  }
}
